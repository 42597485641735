const listeners = {
  message: []
};

export const subscribe = function (key, callback) {
  listeners[key] = [...new Set([...listeners[key], callback])];
}

export const publish = function (key, data) {
  listeners[key].forEach(callback => {
    callback(data);
  });
}