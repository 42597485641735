import apis from "@/api/api";
import { TreeSelect } from "antd";
import { TreeSelectProps } from "antd/lib/tree-select";
import React from "react";

export default React.memo((props: TreeSelectProps<any>) => {
  const [repositories, setRepositories] = React.useState([]);

  React.useEffect(() => {
    apis["repository.findMany"].post()
    .then(data => {
      setRepositories(data as any);
    });
  }, []);
  return <TreeSelect
    bordered={false}
    treeDefaultExpandAll
    className="search-tree-select"
    defaultValue=""
    dropdownMatchSelectWidth={200}
    {...props}
    treeData={[{
      title: '全部仓库',
      value: '',
      children: repositories.map((i: any) => {
        return {
          title: i.repositoryName,
          value: i.repositoryId,
        };
      })
    }]}
  />
})