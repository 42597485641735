import React from 'react';
import { Form, Button, Input } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { getLoginName, getPassword } from '@/storage/storage';
import globalUserStore from '@/global_store/globalUserStore';
const styles = require('./login.less');

export default class UserLogin extends React.PureComponent<RouterPropsType> {
  private loginName: string | null = getLoginName();
  private password: string | null = getPassword();

  state: {
    loading: boolean
  }

  constructor(props) {
    super(props);
    this.password = this.password ? this.password.substring(0, 6) : '';

    this.state = {
      loading: false,
    };
  }
  onFinish = values => {
    this.setState({
      loading: true,
    });

    globalUserStore.userLogin({
      username: values.username,
      password: values.password,
    })
    .then(() => {
      this.props.history.replace('/');
    })
    .catch(e => {
      this.setState({
        loading: false,
      });
    });
  };

  onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  render() {
    const { loading } = this.state;
    // const { get} = this.props.form;
    return <div className={styles.wrapper}>
      <div className={styles.form}>
        <div className={styles.displayName}>
          {/* {config.displayName} */}
          <img src={require('../../static/logo.png').default}/>
        </div>
        <Form
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
          initialValues={{
            username: this.loginName || undefined,
            password: this.password
          }}
        >
          <Form.Item name="username"
            rules={[
              {
                required: true,
                message: '手机号/用户名',
              },
            ]}>
            <Input size="large" placeholder="请填写手机号/用户名" prefix={<UserOutlined />}/>
          </Form.Item>
          <Form.Item name="password"
            rules={[
              {
                required: true,
                message: '密码',
              },
            ]}>
            <Input type="password" size="large" placeholder="请填写密码" prefix={<LockOutlined />}/>
          </Form.Item>
          <Form.Item>
            <Button block size="large" type="primary" htmlType="submit" loading={loading} disabled={loading}>
              登录
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  }
}
