import React from 'react';
import { Result, Button } from 'antd';

export default props => {
  return <Result
    status="500"
    title="500"
    subTitle="抱歉，服务器发生错误了"
    extra={<Button type="primary" onClick={() => {
      props.history.replace('/');
    }}>返回首页</Button>}
  />
}