import { Form, Input, Select } from 'antd';
import { FormItemProps } from 'antd/lib/form';
import { InputProps, SearchProps } from 'antd/lib/input';
import { SelectProps } from 'antd/lib/select';
import React from 'react';
const styles = require('./search-form.less');

const SearchForm = (props) => {
  return <div style={{marginBottom: 0}}>
    {props.children}
  </div>
}

SearchForm.Row = (props: {
  style?: React.CSSProperties
  children: React.ReactNode
}) => {
  return <div style={{display: 'flex', ...(props.style || {})}} className="com-search-form">
    {props.children}
  </div>
}

SearchForm.Item = (props: FormItemProps) => {
  const {style, children, ...reset} = props;
  return <Form.Item  style={{marginRight: 50, ...(style || {})}} {...reset} className="com-search-form-item">
    {children}
  </Form.Item>
}

SearchForm.Select = (props: SelectProps<any>) => {
  return <Select dropdownMatchSelectWidth={100} bordered={false} {...props}>
    {props.children}
  </Select>
}

SearchForm.Search = (props: SearchProps) => {
  return <Input.Search enterButton={<i className="iconfont icon-chazhao"/>} allowClear style={{width: 300}} {...props}/>
}

interface InputType extends InputProps {border?: 'underline' | 'border'}
SearchForm.Input = (props: InputType) => {
  return <Input allowClear style={{width: 300}} {...props} className={props.border || ''}/>
}

SearchForm.ItemProductName = (props: SearchProps) => {
  return <SearchForm.Item label="商品名称">
    <SearchForm.Search {...props}/>
  </SearchForm.Item>
}

export default SearchForm;