import React from 'react';
import{ConfigProvider} from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import { Router, Route, Switch, Redirect } from 'dva/router';
import { showError, showSuccess, showFail } from '@/components/message/message';
import App from './App';
import Login from './page/login/Login';

window['showSuccess'] = showSuccess;
window['showFail'] = showFail;
window['showError'] = showError;

export default (props): JSX.Element => {
  const { history, app } = props;
  return (<ConfigProvider locale={zhCN}>
    <Router history={history}>
      <Switch>
        <Route path="/login" component={Login}/>
        <Route path="/" component={App}></Route>
        <Redirect to="/"></Redirect>
      </Switch>
    </Router>
    </ConfigProvider>
  );
}