import React from 'react';
import {Input, message} from 'antd';
import { InputProps } from 'antd/lib/input';
import Bignumber from 'bignumber.js';

interface IPrice extends Omit<InputProps, 'onChange'> {
  onChange(value: string): void;
  selected?: boolean | undefined;
}

const Price = React.memo((props: IPrice) => {
  const {disabled = false, defaultValue, onChange, value, style = {}, selected } = props;
  const [selfValue, setValue] = React.useState(value || defaultValue);
  return <Input
    style={{width: '100%', textAlign: 'right', ...style}}
    disabled={disabled}
    value={value !== undefined ? value : selfValue}
    defaultValue={defaultValue}
    placeholder="填写价格"
    suffix={props.suffix}
    onChange={(e) => {
      const value = e.target.value;
      if (isNaN(Number(value))) return;
      setValue(value);
      onChange && onChange(value as any);
    }}
    onFocus={(e) => {
      setValue(Number(value));
      onChange && onChange(Number(value) as any);
      if (selected) {
        e.target.select();
      }
    }}
    onBlur={(e) => {
      const value = new Bignumber(e.target.value || 0).toFixed(2);
      setValue(value);
      onChange && onChange(value as any);
    }}
  />
})

interface ICount extends Omit<InputProps, 'onChange'> {
  min?: number
  max?: number
  maxTip?: string
  minTip?: string
  selected?: boolean | undefined
  onChange(value: string): void
}

const Count = React.memo((props: ICount) => {
  const {min = 0, max = 10000000000000, maxTip, minTip, disabled = false, defaultValue, onChange, value, style = {}, selected } = props;
  const [selfValue, setValue] = React.useState(defaultValue);
  return <Input
    style={{width: '100%', textAlign: 'right', ...style}}
    disabled={disabled}
    value={value !== undefined ? value : selfValue}
    defaultValue={defaultValue}
    onChange={(e) => {
      const value = e.target.value;
      if (isNaN(Number(value))) return;
      if (value.indexOf('.') > -1) return;
      if (Number(value) > max && !maxTip) return;

      const result = value !== '' ? parseInt(value) : value;
      setValue(result);
      onChange && onChange(result as any);
    }}
    onFocus={(e) => {
      if (selected) {
        e.target.select();
      }
    }}
    onBlur={(e) => {
      const value = parseInt(e.target.value || '0');
      if (Number(value) > max) {
        maxTip && message.error(maxTip);
      }
      if (Number(value) < min) {
        minTip && message.error(minTip);
      }

      if (Number(value) >= max) {
        setValue(Math.min(value, max));
        onChange && onChange(Math.min(value, max) as any);
      }
      if (Number(value) <= min) {
        setValue(Math.max(Number(value), min));
        onChange && onChange(Math.max(Number(value), min) as any);
      }
    }}
  />
})

export default {
  Price,
  Count
}