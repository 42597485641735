import React from "react";
import { Tag } from 'antd';

type OrderStatusType = 'finished' | 'unfinished'

const OrderStatus = (props: {
  status: OrderStatusType
  children: React.ReactNode
}) => {
  if (!props.children) {
    return null;
  }
  const color = {
    'unfinished': '#f50',
    'finished': '#87d068',
  }[props.status] || '#aaa'
  return <Tag color={color}>{props.children}</Tag>
}

OrderStatus.Sale = (props: {
  status: SaleStatusType
  children: React.ReactNode
}) => {
  const status = {
    '11': 'unfinished',
    '12': 'unfinished',
    '13': 'unfinished',
    '14': 'finished',
  }[props.status];
  return <OrderStatus status={status as OrderStatusType}>{props.children}</OrderStatus>
}

OrderStatus.SaleReturn = (props: {
  status: SaleReturnStatusType
  children: React.ReactNode
}) => {
  const status = {
    '11': 'unfinished',
    '12': 'unfinished',
    '13': 'finished',
    '14': 'finished',
    '99': 'finished',
  }[props.status];
  return <OrderStatus status={status as OrderStatusType}>{props.children}</OrderStatus>
}

OrderStatus.Purchase = (props: {
  status: PurchaseStatusType
  children: React.ReactNode
}) => {
  const status = {
    '11': 'unfinished',
    '12': 'unfinished',
    '13': 'unfinished',
    '14': 'finished',
    '99': 'finished',
  }[props.status];
  return <OrderStatus status={status as OrderStatusType}>{props.children}</OrderStatus>
}

OrderStatus.PurchaseReturn = (props: {
  status: PurchaseReturnStatusType
  children: React.ReactNode
}) => {
  const status = {
    '11': 'unfinished',
    '12': 'unfinished',
    '13': 'finished',
    '14': 'finished',
    '99': 'finished',
  }[props.status];
  return <OrderStatus status={status as OrderStatusType}>{props.children}</OrderStatus>
}

OrderStatus.Pay = (props: {
  status: PayStatusType
  children: React.ReactNode
}) => {
  const status = {
    '11': 'unfinished',
    '12': 'finished',
  }[props.status];
  return <OrderStatus status={status as OrderStatusType}>{props.children}</OrderStatus>
}

OrderStatus.Receive = (props: {
  status: PayStatusType
  children: React.ReactNode
}) => {
  const status = {
    '11': 'unfinished',
    '12': 'finished',
  }[props.status];
  return <OrderStatus status={status as OrderStatusType}>{props.children}</OrderStatus>
}

export default OrderStatus;