import { action, computed, makeAutoObservable, observable, runInAction } from 'mobx';
import md5 from '@/utils/md5';
import { getLoginName, getPassword, removeToken, setLoginName, setPassword, setToken } from '@/storage/storage';
import WebAuth from '@/web/WebAuth';
import WebApi from '@/web/api';

class GlobalUserStore {
  isLogin: boolean = false;
  userId: string = '';
  from: 'AGENT' | 'YCH' = 'AGENT';
  mobile: string = '';
  isAdministrator: boolean = false;
  fullName: string = '';
  departmentName: string = '';
  agentId: string = '';
  agentName: string = '';

  @observable roleList: string[] = [];
  @observable functionList: string[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  @action
  public userLogin(payload: any): Promise<any> {
    payload.password = md5.hex_md5(payload.password);
    return this.login(payload);
  }

  @action
  public autoLogin(): Promise<any> {
    const username = getLoginName();
    const password = getPassword();
    return this.login({
      username,
      password,
    }).catch(e => {
      showFail('登录已过期，请重新登录');
      window.location.replace('/login');
    });
  }

  @action
  public uerLogout() {
    removeToken();
    window.location.replace('/login');
  }

  @action
  private login(payload): Promise<any> {
    return WebApi.user_login(payload).then((res: any) => {
      WebAuth.reset(res.isAdministrator, res.functionList || []);
      setToken(res.token);
      setLoginName(payload.username);
      setPassword(payload.password);
      runInAction(() => {
        this.isLogin = true;
        this.isAdministrator = res.isAdministrator;
        this.agentId = res.agentId;
        this.agentName = res.agentName;
        this.mobile = res.mobile;
        this.fullName = res.fullName;
        this.departmentName = res.departmentName;
        this.roleList = res.roleList;
        this.from = res.from;
        this.userId = res.userId;
        this.functionList = res.functionList;
      });
      return Promise.resolve();
    }).catch(e => {
      // window.location.href = '/login';
      return Promise.reject(e);
    });
  }

  @computed
  public isYch() {
    return this.from === 'YCH';
  }
}

export default new GlobalUserStore();