import request from './request';
import notice from './module/notice';
import activity from './module/activity';
import coupon from './module/coupon';
import sale from './module/sale';

export interface Api {
  url: string
  post(data?: object): Promise<any>
}

const apis = {
  ...notice,
  ...activity,
  ...coupon,
  ...sale,


  // ================== 用    户 ===============
  'user.login': {
    url: '/web/user/login',
    post: function(data) {
      return request(this.url, data);
    }
  },
  // ================== 用    户 ===============
};

export default apis;