import React from "react";

interface IBookData {
  financeBookId?: string;
  targetName?: string;
  actualAmount?: string;
  datetime?: string;
  createdAt?: string;
  remark?: string;
}

export default (props: {
  data: IBookData | IBookData[];
  mode: 'payment' | 'received';
}) => {
  const TAGS = props.mode === 'payment' ? ['收', '付'] : ['付', '收'];
  const data = Array.isArray(props.data) ? (props.data[0] || {}) : props.data || {};
  const {financeBookId, targetName, actualAmount, datetime, createdAt, remark} = data;
  return <div>
    <strong style={{color: '#666'}}>账单编号：</strong>
    <span style={{marginRight: 50}}>{financeBookId || '--'}</span>

    <strong style={{color: '#666'}}>{TAGS[0]}款单位：</strong>
    <span style={{marginRight: 50}}>{targetName || '--'}</span>

    <strong style={{color: '#666'}}>{TAGS[1]}款金额：</strong>
    <span style={{marginRight: 50}}>{actualAmount || '--'}</span>

    <strong style={{color: '#666'}}>{TAGS[1]}款时间：</strong>
    <span style={{marginRight: 50}}>{datetime || '--'}</span>

    <strong style={{color: '#666'}}>备注：</strong>
    <span style={{marginRight: 50}}>{remark || '--'}</span>

    <strong style={{color: '#666'}}>创建时间：</strong>
    <span style={{marginRight: 50}}>{createdAt || '--'}</span>
  </div>
}