import React from 'react';
import ViewTable, {IViewableProps, logoUrlCell, IViewTableColumn} from './ViewTable';
import { TableProps, ColumnType } from 'antd/lib/table';
import Button from '../button/Button';
const styles = require('./styles.less');

interface IEditColumn extends IViewTableColumn<any> {
  disabledEmptyRender?: boolean
}

interface EditProps<T> extends Omit<IViewableProps<T>, 'columns'> {
  columns: IEditColumn[];
  onAdd?(record: T, index: number): void
  onMinus?(record: T, index: number): void
  actionColSpan?(value, row, index): {}
}

const EditTable = (props: EditProps<any>) => {
  const actionColumn: ColumnType<any> = {
    title: '操作',
    align: 'center',
    fixed: 'left',
    width: 50,
    render: (v, r, index) => {
      let children: React.ReactNode = <span className="edit-table-action-col" onClick={() => {
        props.onAdd && props.onAdd(r, index);
      }}>
        <i className="iconfont icon-jia" style={{fontSize: 16}}/>
      </span>;

      if (r.id !== 'default-id') {
        children = <span className="edit-table-action-col" onClick={() => {
          props.onMinus && props.onMinus(r, index);
        }}>
          <i className="iconfont icon-jian" style={{fontSize: 14}}/>
        </span>
      }
      return {
        children,
        props: props.actionColSpan ? props.actionColSpan(v ,r, index) : {}
      }
    }
  };

  const actionRow: any = {
    id: 'default-id'
  };
  props.columns?.forEach((item: IEditColumn) => {
    item.disabledEmptyRender = item.disabledEmptyRender === undefined ? false : item.disabledEmptyRender;
    actionRow[item.dataIndex + ''] = null;
    const render = item.render;

    if (render) {
      item.render = (v, row, index) => {
        if (row.id === 'default-id' && !item.disabledEmptyRender) {
          return null;
        }
        return render ? render(v, row, index) : row[item.dataIndex + ''];
      }
    } else {
      if (item.dataIndex === 'logoImageUrl') {
        item.render = (v, row, index) => {
          if (row.id === 'default-id') {
            return null;
          }
          return logoUrlCell(v);
        };
      }
      if (item.dataIndex === 'imageLogo') {
        item.render = (v, row, index) => {
          if (row.id === 'default-id') {
            return null;
          }
          return logoUrlCell(v?.imageUrl);
        };
      }
    }
  });

  const indexColumnIndex = (props.columns || []).findIndex(item => item.dataIndex === 'indexColumn');
  if (indexColumnIndex > -1 && Array.isArray(props.columns)) {
    props.columns.splice(indexColumnIndex + 1, 0, actionColumn);
  } else {
    props.columns.splice(0, 0, actionColumn);
  }
  return <ViewTable
    emptyCellRender={() => null}
    pagination={false}
    {...props}
    dataSource={[...(props.dataSource || []), actionRow]}
    columns={props.columns}/>
}

export default EditTable;