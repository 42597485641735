export default class LibAuth {
  isAdministrator: boolean = false;

  authCodeList: number[] = [];

  reset(isAdministrator: boolean, authCodeList: number[]) {
    this.isAdministrator = isAdministrator;
    this.authCodeList = authCodeList;
  }

  hasAuth(authCode: string | number) {
    if (this.isAdministrator) return true;
    return this.authCodeList.includes(Number(authCode));
  }
}

export const LibAuthCode = {
  saleOrder: {
    create: 101000,
    delete: 101100,
    update: 101200,
    view: 101300,
    print: 101400,
    export: 101500,
  },
  saleReturnOrder: {
    create: 111000,
    delete: 111100,
    update: 111200,
    view: 111300,
    print: 111400,
    export: 111500,
  },
  purchaseOrder: {
    create: 121000,
    delete: 121100,
    update: 121200,
    view: 121300,
    print: 121400,
    export: 121500,
  },
  purchaseReturnOrder: {
    create: 131000,
    delete: 131100,
    update: 131200,
    view: 131300,
    print: 131400,
    export: 131500,
  },
  product: {
    create: 141000,
    delete: 141100,
    update: 141200,
    view: 141300,
    print: 141400,
    export: 141500,
  },
  category: {
    create: 151000,
    delete: 151100,
    update: 151200,
    view: 15300,
    print: 151400,
    export: 151500,
  },
  activity: {
    create: 151000,
    delete: 151100,
    update: 151200,
    view: 15300,
    print: 151400,
    export: 151500,
  },
  advert: {
    create: 161000,
    delete: 161100,
    update: 161200,
    view: 16300,
    print: 161400,
    export: 161500,
  },
  coupon: {
    create: 171000,
    delete: 171100,
    update: 171200,
    view: 17300,
    print: 171400,
    export: 171500,
  },
  book: {
    create: 181000,
    delete: 181100,
    update: 181200,
    view: 18300,
    print: 181400,
    export: 181500,
  },
  customer: {
    create: 191000,
    delete: 191100,
    update: 191200,
    view: 19300,
    print: 191400,
    export: 191500,
  },
  supplier: {
    create: 201000,
    delete: 201100,
    update: 201200,
    view: 20300,
    print: 201400,
    export: 201500,
  },
  auth: {
    create: 211000,
    delete: 211100,
    update: 211200,
    view: 211300,
    print: 211400,
    export: 211500,
  },
  actionLog: {
    create: 221000,
    delete: 221100,
    update: 221200,
    view: 221300,
    print: 221400,
    export: 221500,
  },
  department: {
    create: 231000,
    delete: 231100,
    update: 231200,
    view: 231300,
    print: 231400,
    export: 231500,
  },
  employee: {
    create: 241000,
    delete: 241100,
    update: 241200,
    view: 241300,
    print: 241400,
    export: 241500,
  },
  repository: {
    create: 251000,
    delete: 251100,
    update: 251200,
    view: 251300,
    print: 251400,
    export: 251500,
  },
  notice: {
    create: 261000,
    delete: 261100,
    update: 261200,
    view: 261300,
    print: 261400,
    export: 261500,
  },
  message: {
    create: 271000,
    delete: 271100,
    update: 271200,
    view: 271300,
    print: 271400,
    export: 271500,
  },
  stock: {
    create: 281000,
    delete: 281100,
    update: 281200,
    view: 281300,
    print: 281400,
    export: 281500,
  },
  report: {
    create: 291000,
    delete: 291100,
    update: 2981200,
    view: 291300,
    print: 291400,
    export: 291500,
  },
  agent: {
    create: 301000,
    delete: 301100,
    update: 3081200,
    view: 301300,
    print: 301400,
    export: 301500,
  }
};