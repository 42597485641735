export default class LibAgentUrl{


  // 用户登录
  static USER_LOGIN_URL = '/agent/user/login';

  // 后台首页接口
  static HOME_DATA_DETAIL_URL = '/agent/home_data';


  // 订货单分页
  static SALE_ORDER_PAGE_URL = '/agent/sale_order/sale_order_paging';
  static SALE_PRODUCT_PAGE_URL = '/agent/sale_order/sale_product_paging';
  static SALE_ORDER_COUNT_URL = '/agent/sale_order/sale_order_count';
  // 订货单商品修改选项列表
  static SALE_ORDER_DETAIL_UPDATE_OPTION_URL = '/agent/sale_order/detail_update_option';
  // 订货单详情
  static SALE_ORDER_DETAIL_URL = '/agent/sale_order/sale_order_detail';
  // 订货单付款状态修改
  static SALE_ORDER_PAYMENT_STATUS_UPDATE_URL = '/agent/sale_order/payment_status_update';
  // 订货单打印状态修改
  static SALE_ORDER_PRINT_STATUS_UPDATE_URL = '/agent/sale_order/print_status_update';
  // 打印信息查询
  static SALE_ORDER_PRINT_DATA_LIST_URL = '/agent/sale_order/print_data_list';
  // 订货单收货地址修改
  static SALE_ORDER_ADDRESS_UPDATE_URL = '/agent/sale_order/address_update';
  // 订货单备注修改
  static SALE_ORDER_REMARK_UPDATE_URL = '/agent/sale_order/remark_update';
  // 订货单商品修改
  static SALE_ORDER_PRODUCT_UPDATE_URL = '/agent/sale_order/sale_order_product_update';
  // 订货单作废
  static SALE_ORDER_INVALID_URL = '/agent/sale_order/sale_order_invalid';
  // 订货通过审核
  static SALE_ORDER_AUDIT_URL = '/agent/sale_order/sale_order_audit';
  // 订货退回审核
  static SALE_ORDER_AUDIT_RETURN_URL = '/agent/sale_order/sale_order_audit_return';
  // 订货单查询出库选项信息
  static SALE_ORDER_OUT_STOCK_OPTION_LIST_URL = '/agent/sale_order/out_stock_option_list';
  // 订货出库
  static SALE_ORDER_OUT_STOCK_URL = '/agent/sale_order/sale_order_out_stock';
  // 订货发货
  static SALE_ORDER_DELIVER_URL = '/agent/sale_order/sale_order_deliver';
  // 修改发货记录
  static SALE_ORDER_DELIVER_RECORD_UPDATE_URL = '/agent/sale_order/update_deliver_record';
  // 订货添加付款记录
  static SALE_ORDER_PAYMENT_URL = '/agent/sale_order/sale_order_payment';
  // 订货单删除
  static SALE_ORDER_DELETE_URL = '/agent/sale_order/sale_order_delete';
  // 代下单
  static SALE_ORDER_CREATE_URL = '/agent/sale_order/sale_order_create';
  // 商品最后订货的价格
  static SALE_PRODUCT_LATEST_PRICE = '/agent/sale_order/sale_product_latest_price';


  // 订货退货单分页查询
  static SALE_RETURN_ORDER_PAGING = '/agent/sale_return_order/sale_return_order_paging';
  static SALE_RETURN_PRODUCT_PAGING = '/agent/sale_return_order/sale_return_product_paging';
  static SALE_RETURN_ORDER_DETAIL = '/agent/sale_return_order/sale_return_order_detail';
  static SALE_RETURN_ORDER_AUDIT = '/agent/sale_return_order/sale_return_order_audit';
  static SALE_RETURN_ORDER_CANCEL = '/agent/sale_return_order/sale_return_order_cancel';
  static SALE_RETURN_ORDER_IN_STOCK = '/agent/sale_return_order/sale_return_order_in_stock';
  static SALE_RETURN_ORDER_DELETE = '/agent/sale_return_order/sale_return_order_delete';
  static SALE_RETURN_ORDER_PAY = '/agent/sale_return_order/sale_return_order_pay';
  static SALE_RETURN_ORDER_CREATE = '/agent/sale_return_order/sale_return_order_create';

  // 进货单分页查询
  static PURCHASE_ORDER_PAGING_URL = '/agent/purchase_order/purchase_order_paging';
  // 进货商品分页查询
  static PURCHASE_PRODUCT_PAGING_URL = '/agent/purchase_order/purchase_product_paging';
  // 进货单详情
  static PURCHASE_ORDER_DETAIL_URL = '/agent/purchase_order/purchase_order_detail';
  // 进货单修改
  static PURCHASE_ORDER_UPDATE_URL = '/agent/purchase_order/purchase_order_update';
  // 创建
  static PURCHASE_ORDER_CREATE_URL = '/agent/purchase_order/purchase_order_create';
  // 审核
  static PURCHASE_ORDER_AUDIT_URL = '/agent/purchase_order/purchase_order_audit';
  // 作废
  static PURCHASE_ORDER_CANCEL_URL = '/agent/purchase_order/purchase_order_cancel';
  // 单删除
  static PURCHASE_ORDER_DELETE_URL = '/agent/purchase_order/purchase_order_delete';
  // 入库
  static PURCHASE_ORDER_IN_STOCK_URL = '/agent/purchase_order/purchase_order_in_stock';
  // 付款记录
  static PURCHASE_ORDER_PAYMENT_RECORD_CREATE_URL = '/agent/purchase_order/purchase_order_payment_record_create';


  // 进货退货
  static PURCHASE_RETURN_ORDER_PAGING_URL = '/agent/purchase_return_order/purchase_return_order_paging';
  static PURCHASE_RETURN_ORDER_DETAILS_URL = '/agent/purchase_return_order/purchase_return_order_details';
  static PURCHASE_RETURN_ORDER_AUDIT_URL = '/agent/purchase_return_order/purchase_return_order_audit';
  static PURCHASE_RETURN_ORDER_CANCEL_URL = '/agent/purchase_return_order/purchase_return_order_cancel';
  static PURCHASE_RETURN_ORDER_DELETE_URL = '/agent/purchase_return_order/purchase_return_order_delete';
  static PURCHASE_RETURN_ORDER_PAYMENT_RECORD_CREATE_URL = '/agent/purchase_return_order/purchase_return_order_payment_record_create';
  static PURCHASE_RETURN_ORDER_CREATE_URL = '/agent/purchase_return_order/purchase_return_order_create';
  static PURCHASE_RETURN_ORDER_OUT_STOCK_URL = '/agent/purchase_return_order/purchase_return_order_out_stock';


  // 商品详情
  static PRODUCT_DETAIL_URL = '/agent/product/product_detail';
  // 新增商品
  static PRODUCT_CREATE_URL = '/agent/product/product_create';
  // 修改商品
  static PRODUCT_UPDATE_URL = '/agent/product/product_update';
  // 删除商品
  static PRODUCT_DELETE = '/agent/product/product_delete';
  // 规格信息
  static PRODUCT_SKU_UPDATE_URL = '/agent/product/product_sku_update';
  static PRODUCT_SKU_CREATE_URL = '/agent/product/product_sku_create';
  static PRODUCT_SKU_DELETE_URL = '/agent/product/product_sku_delete';
  static PRODUCT_SKU_DISABLE_URL = '/agent/product/product_sku_disable';
  // 新增单位
  static PRODUCT_UNIT_CREATE_URL = '/agent/product/product_unit_create';
  // 修改单位信息
  static PRODUCT_UNIT_UPDATE_URL = '/agent/product/product_unit_update';
  static PRODUCT_UNIT_DISABLE_URL = '/agent/product/product_unit_disable';
  // 删除单位
  static PRODUCT_UNIT_DELETE_URL = '/agent/product/product_unit_delete';
  // 商品分类
  static CATEGORY_LIST_URL = '/agent/product/category_list';
  // 商品分类
  static CATEGORY_CREATE_URL = '/agent/product/category_create';
  static CATEGORY_UPDATE_URL = '/agent/product/category_update';
  static CATEGORY_DELETE_URL = '/agent/product/category_delete';
  static CATEGORY_SORT_URL = '/agent/product/category_sort';


  // 商品分页v2
  static PRODUCT_PAGING_URL = '/agent/product/product_paging';
  // 商品分类
  static PRODUCT_CATEGORY_TREE_URL = '/agent/product/category_tree';
  // 批量操作，下/上架
  static PRODUCT_BACH_UPDATE_STATUS_URL = '/agent/product/product_status_batch_update';
  // 批量操作，修改分类
  static PRODUCT_BACH_UPDATE_CATEGORY_URL = '/agent/product/product_category_batch_update';


  // 客户分页
  static CUSTOMER_PAGE_URL = '/agent/customer/customer_page';
  // 客户详情
  static CUSTOMER_DETAIL_URL = '/agent/customer/customer_detail';
  // 客户新增
  static CUSTOMER_CREATE_URL = '/agent/customer/customer_create';
  // 客户信息修改
  static CUSTOMER_UPDATE_URL = '/agent/customer/customer_update';
  // 客户删除
  static CUSTOMER_DELETE_URL = '/agent/customer/customer_delete';
  // 客户收货地址列表
  static CUSTOMER_ADDRESS_LIST_URL = '/agent/customer/address_list';
  // 客户收货地址删除
  static CUSTOMER_ADDRESS_DELETE_URL = '/agent/customer/address_delete';
  // 客户收货地址修改
  static CUSTOMER_ADDRESS_UPDATE_URL = '/agent/customer/address_update';
  // 客户收货地址新增
  static CUSTOMER_ADDRESS_CREATE_URL = '/agent/customer/address_create';


  // 供应商分页
  static SUPPLIER_PAGING_URL = '/agent/supplier/supplier_paging';
  // 供应商列表
  static SUPPLIER_LIST_URL = '/agent/supplier/supplier_list';
  // 供应商修改
  static SUPPLIER_UPDATE_URL = '/agent/supplier/supplier_update';
  // 供应商新增
  static SUPPLIER_CREATE_URL = '/agent/supplier/supplier_create';
  // 供应商删除
  static SUPPLIER_DELETE_URL = '/agent/supplier/supplier_delete';


  // 库存汇总分页
  static STOCK_SUMMARY_PAGING_URL = '/agent/stock/stock_summary_paging';
  // 库存明细分页
  static STOCK_DETAIL_PAGING_URL = '/agent/stock/stock_detail_paging';
  static STOCK_DETAIL_DELETE_URL = '/agent/stock/stock_detail_delete';
  // 出入库记录分页
  static STOCK_RECORD_PAGING_URL = '/agent/stock/stock_record_paging';

  // 财务分页
  static BOOK_PAGING_URL = '/agent/book/book_paging';
  static BOOK_CREATE_URL = '/agent/book/book_create';


  // 系统日志
  static ACTION_LOG_PAGING_URL = '/agent/system/action_log_paging';

  // 员工
  static EMPLOYEE_LIST_URL = '/agent/company/employee_list';
  static EMPLOYEE_CREATE_URL = '/agent/company/employee_create';
  static EMPLOYEE_UPDATE_URL = '/agent/company/employee_update';
  static EMPLOYEE_DELETE_URL = '/agent/company/employee_delete';
  static EMPLOYEE_RESET_PW_URL = '/agent/company/employee_reset_pw';
  // 部门
  static DEPARTMENT_LIST_URL = '/agent/company/department_list';
  static DEPARTMENT_CREATE_URL = '/agent/company/department_create';
  static DEPARTMENT_UPDATE_URL = '/agent/company/department_update';
  static DEPARTMENT_DELETE_URL = '/agent/company/department_delete';

  // 仓库
  static REPOSITORY_LIST_URL = '/agent/repository/repository_list';
  static REPOSITORY_CREATE_URL = '/agent/repository/repository_create';
  static REPOSITORY_UPDATE_URL = '/agent/repository/repository_update';
  static REPOSITORY_DELETE_URL = '/agent/repository/repository_delete';

  // 权限
  static AUTH_ROLE_LIST_URL = '/agent/auth/role_list';
  static AUTH_ROLE_DETAIL_URL = '/agent/auth/role_detail';
  static AUTH_ROLE_CREATE_URL = '/agent/auth/role_create';
  static AUTH_ROLE_UPDATE_URL = '/agent/auth/role_update';
  static AUTH_ROLE_DELETE_URL = '/agent/auth/role_delete';

  // 公告
  static NOTICE_PAGING_URL = '/agent/notice/notice_paging';
  static NOTICE_UPDATE_URL = '/agent/notice/notice_update';
  static NOTICE_CREATE_URL = '/agent/notice/notice_create';
  static NOTICE_DELETE_URL = '/agent/notice/notice_delete';

  // 首页广告
  static ADVERT_DETAIL_URL = '/agent/advert/advert_detail';
  static ADVERT_LIST_URL = '/agent/advert/advert_list';
  static ADVERT_CREATE_URL = '/agent/advert/advert_create';
  static ADVERT_UPDATE_URL = '/agent/advert/advert_update';
  static ADVERT_DELETE_URL = '/agent/advert/advert_delete';

  // 报表
  static REPORT_SALE_ORDER_URL = '/agent/report/sale_order_report';
  static REPORT_PRODUCT_SALE__URL = '/agent/report/product_sale_report';
  static REPORT_CUSTOMER_SALE_URL = '/agent/report/customer_sale_report';
  static REPORT_PURCHASE_ORDER_URL = '/agent/report/purchase_order_report';
  static REPORT_PRODUCT_PURCHASE_URL = '/agent/report/purchase_product_report';
  static REPORT_SUPPLIER_PURCHASE_URL = '/agent/report/supplier_purchase_report';
  static REPORT_MANAGE_SUMMARY_URL = '/agent/report/manage_summary_report';
  static REPORT_MANAGE_FEE_URL = '/agent/report/manage_fee_report';
  static REPORT_EMPLOYEE_SALE_RANKING_URL = '/agent/report/employee_sale_ranking_report';

  // 后台推送消息
  static MESSAGE_UNREAD_COUNT_URL = '/agent/message/message_unread_count';
  static MESSAGE_PAGING_URL = '/agent/message/message_paging';
  static MESSAGE_READ_UPDATE_URL = '/agent/message/message_read_update';
  static MESSAGE_DELETE_URL = '/agent/message/message_delete';

  // 上传图片
  static UPLOAD_IMAGE = '/agent/upload/upload_image';

  // 修改图片参数
  static IMAGE_UPDATE = '/agent/image/image_update';
  // 删除图片
  static IMAGE_DELETE = '/agent/image/image_delete';

  // 查询最近代下单的50个客户
  static SALE_CUSTOMER_URL = '/agent/sale_customer'
}