import config from '../config';
import creatNotification from './notification';
import { publish } from './events';

export default (agentId) => {

  const socket = io(config.apiUrl, {

    // 实际使用中可以在这里传递参数
    query: {
      roomId: agentId,
    },
    transports: ['websocket'],
  });

  socket.on('connect', () => {
    const id = socket.id;
    console.log('#connect,', id);
  });

  // 接收消息
  socket.on('message', msg => {
    creatNotification({
      body: msg
    });
    publish('message');
    // console.log('#message,', msg);
  });

  socket.on('connected', msg => {
    console.log('#connected,', msg);
  })

  // 系统事件
  socket.on('disconnect', msg => {
    console.log('#disconnect', msg);
  });

  socket.on('disconnecting', () => {
    console.log('#disconnecting');
  });

  socket.on('error', () => {
    console.log('#error');
  });
};