import { routerMap } from "@/router/agent_router";
import WebApi from "@/web/api";
import WebAuth from "@/web/WebAuth";
import dayjs from "dayjs";
import { LibAuthCode } from "lib/Auth";
import React from "react";
const styles = require('./home.less');

interface IState {
  saleOrderWaitAuditCount: number;
  saleOrderWaitOutStockCount: number;
  saleOrderWaitDeliverCount: number;
  saleOrderWaitPaymentCount: number;
  saleOrderTodayCount: number;
  saleOrderYesterday: number;
  saleReturnOrderTodayCount: number;
  saleReturnOrderYesterday: number;
  purchaseOrderTodayCount: number;
  purchaseOrderYesterday: number;
  purchaseReturnTodayCount: number;
  purchaseReturnOrderYesterday: number;
  customerTodayCount: number;
  customerYesterday: number;
  saleOrderList: any[];
}

export default class AgentHome extends React.PureComponent<RouterPropsType, IState> {

  private shortcutList = [
    {
      icon: 'icon-daikexiadandaikexiadan',
      name: '代下单',
      router: routerMap.sale_order_create.path,
      authCode: LibAuthCode.saleOrder.create,
    },
    {
      icon: 'icon-shouhuodan',
      name: '进货',
      router: routerMap.purchase_order_create.path,
      authCode: LibAuthCode.purchaseOrder.create,
    },
    // {
    //   icon: 'icon-kehuzhuce',
    //   name: '新增客户',
    //   router: routerMap.customer_list.path,
    //   authCode: LibAuthCode.purchaseOrder.create,
    // }
  ];

  private saleOrderList = [
    {
      icon: 'icon-daishenhe3',
      name: '待审核',
      status: 'audit',
    },
    {
      icon: 'icon-daichuku',
      name: '待出库',
      status: 'out',
    },
    {
      icon: 'icon-daifahuo',
      name: '待发货',
      status: 'deliver',
    },
    {
      icon: 'icon-shoukuan',
      name: '待收款',
      status: 'received',
    }
  ];

  private manageList = [
    {
      icon: 'icon-dinghuodan',
      name: '订货单',
      authCode: LibAuthCode.saleOrder.view,
      router: routerMap.sale_order_list.path,
    },
    {
      icon: 'icon-tuihuo',
      name: '订货退货单',
      authCode: LibAuthCode.saleReturnOrder.view,
      router: routerMap.sale_return_order_list.path,
    },
    {
      icon: 'icon-jinhuo1',
      name: '进货单',
      authCode: LibAuthCode.purchaseOrder.view,
      router: routerMap.purchase_order_list.path,
    },
    {
      icon: 'icon-tuihuo jinhuotuihuo',
      name: '进货退货单',
      authCode: LibAuthCode.purchaseReturnOrder.view,
      router: routerMap.purchase_return_order_list.path,
    },
    {
      icon: 'icon-kehu',
      name: '新客户注册',
      unitName: '家',
      authCode: LibAuthCode.customer.view,
      router: routerMap.customer_list.path,
    }
  ]

  constructor(props) {
    super(props);
    this.state = {
      saleOrderWaitAuditCount: 0,
      saleOrderWaitOutStockCount: 0,
      saleOrderWaitDeliverCount: 0,
      saleOrderWaitPaymentCount: 0,
      saleOrderTodayCount: 0,
      saleOrderYesterday: 0,
      saleReturnOrderTodayCount: 0,
      saleReturnOrderYesterday: 0,
      purchaseOrderTodayCount: 0,
      purchaseOrderYesterday: 0,
      purchaseReturnTodayCount: 0,
      purchaseReturnOrderYesterday: 0,
      customerTodayCount: 0,
      customerYesterday: 0,
      saleOrderList: [],
    };
  }

  private isListening = false;

  componentDidMount() {
    this.fetchSaleOrderCount();
    this.props.history.listen((location) => {
      if (location.pathname === '/') {
        if (this.isListening) {
          this.fetchSaleOrderCount();
        } else {
          this.isListening = true;
        }
      }
    });

    WebApi.sale_order_paging({
      pageSize: 10,
    })
    .then(res => {
      this.setState({
        saleOrderList: res.rows
      });
    });
  }

  fetchSaleOrderCount = () => {
    WebApi.home_data_detail().then(res => {
      this.setState({
        saleOrderWaitAuditCount: res.saleOrder?.waitAuditCount,
        saleOrderWaitOutStockCount: res.saleOrder?.waitOutStockCount,
        saleOrderWaitDeliverCount: res.saleOrder?.waitDeliverCount,
        saleOrderWaitPaymentCount: res.saleOrder?.waitPaymentCount,
      });
    })
  }

  render() {
    const {saleOrderWaitAuditCount, saleOrderWaitOutStockCount, saleOrderWaitDeliverCount, saleOrderWaitPaymentCount} = this.state;
    const shortcutList = this.shortcutList;
    const saleOrderList = this.saleOrderList;
    const manageList = this.manageList;
    return <div className="home-page">
      <section className="home-block home-header">
        <div className="home-block-title">常用功能</div>
        <div className="home-shortcut">
          {
            shortcutList.filter(item => {
              return item.authCode && WebAuth.hasAuth(item.authCode)
            }).map(item => {
              return <div className="home-shortcut-item" key={item.name}>
                <div className="home-shortcut-item-icon" onClick={() => {
                  if (item.router) {
                    this.props.history.push(item.router);
                  }
                }}>
                  <i className={`iconfont ${item.icon}`}/>
                </div>
                <div>{item.name}</div>
              </div>
            })
          }
          {
            new Array(10 - shortcutList.length).fill(1).map((item, index) => {
              return <div className="home-shortcut-item placeholder" key={index}>
                <div className="home-shortcut-item-icon">
                  <i className="iconfont icon-caidan"/>
                </div>
                <div>暂无</div>
              </div>
            })
          }
        </div>
      </section>

      <section className="home-body">
        <section className="home-body-wide">
          <div className="home-block">
            <div className="home-block-title">订货单<span className="home-block-sub-title">(截止到目前)</span></div>
            <div className="home-block-list">
              {
                saleOrderList.map(item => {
                  const count = {
                    '待审核': saleOrderWaitAuditCount,
                    '待出库': saleOrderWaitOutStockCount,
                    '待发货': saleOrderWaitDeliverCount,
                    '待收款': saleOrderWaitPaymentCount,
                  }[item.name] as number;
                  return <div className="home-block-list-item" style={{
                    cursor: WebAuth.hasAuth(LibAuthCode.saleOrder.view) ? 'pointer' : 'auto',
                  }} key={item.name} onClick={() => {
                      if (WebAuth.hasAuth(LibAuthCode.saleOrder.view)) {
                        this.props.history.push(routerMap.sale_order_list_status.getPath('audit'));
                      }
                    }}>
                      <div className="home-block-list-item-label">
                        <div className={`home-block-list-item-icon ${item.icon.replaceAll('icon-', '')}`}>
                          <i className={`iconfont ${item.icon}`}/>
                        </div>
                        <span>{item.name}</span>
                      </div>
                      <div className="home-count">{count} <span>笔</span></div>
                    </div>
                })
              }
            </div>
          </div>

          <div className="home-block">
            <div className="home-block-title">经营情况</div>
            <div className="home-block-list">
              {
                manageList.map(item => {
                  const count: any = {
                    '订货单': {
                      today: this.state.saleOrderTodayCount,
                      yesterday: this.state.saleOrderYesterday,
                    },
                    '订货退货单': {
                      today: this.state.saleReturnOrderTodayCount,
                      yesterday: this.state.saleReturnOrderYesterday,
                    },
                    '进货单': {
                      today: this.state.purchaseOrderTodayCount,
                      yesterday: this.state.purchaseOrderYesterday,
                    },
                    '进货退货单': {
                      today: this.state.purchaseReturnTodayCount,
                      yesterday: this.state.purchaseReturnOrderYesterday,
                    },
                    '新客户注册': {
                      today: this.state.customerTodayCount,
                      yesterday: this.state.customerYesterday,
                    },
                  }[item.name];
                  return <div className="home-block-list-item2" key={item.name}>
                    <div className="home-block-list-item2-label">
                      <div className={`home-block-list-item2-icon ${item.icon.replaceAll('icon-', '')}`}>
                        <i className={`iconfont ${item.icon}`}/>
                      </div>
                      <span>{item.name}</span>
                    </div>
                    <div>
                      <div>今日<span className="home-block-list-item2-value" style={WebAuth.hasAuth(item.authCode) ? {
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      } : {}} onClick={() => {
                        if (WebAuth.hasAuth(item.authCode)) {
                          this.props.history.push(item.router);
                        }
                      }}>{count.today}</span>{item.unitName || '笔'}</div>
                      <div>昨日<span className="home-block-list-item2-value" style={WebAuth.hasAuth(item.authCode) ? {
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      } : {}} onClick={() => {
                        if (WebAuth.hasAuth(item.authCode)) {
                          this.props.history.push(item.router);
                        }
                      }}>{count.yesterday}</span>{item.unitName || '笔'}</div>
                    </div>
                  </div>
                })
              }
            </div>
          </div>
        </section>
        <section className="home-body-narrow">
          <div className="home-block" style={{height: 360, overflow: 'hidden'}}>
            <div className="home-block-title">最近订货下单</div>
            <div className="home-sale-order-list">
              {
                this.state.saleOrderList.map((item, index) => {
                  return <div key={item.id} className="home-sale-order-item">
                    <span><span className="home-sale-order-item-icon" style={{
                      backgroundColor: colorList[index % 6]
                    }}>{item.customerName.substr(0,1)}</span>{item.customerName}</span>
                    <span>{getDateDiff(dayjs(item.createdAt, 'YYYY-MM-DD HH:mm:ss').valueOf())}</span>
                  </div>
                })
              }
            </div>
          </div>
        </section>
      </section>
    </div>
  }
}


function getDateDiff(dateTimeStamp){
	var minute = 1000 * 60;
	var hour = minute * 60;
	var day = hour * 24;
	var halfamonth = day * 15;
	var month = day * 30;
	var now = new Date().getTime();
	var diffValue = now - dateTimeStamp;
	if(diffValue < 0){return;}
	var monthC = diffValue / month;
	var weekC = diffValue / (7 * day);
	var dayC = diffValue / day;
	var hourC = diffValue / hour;
	var minC = diffValue / minute;
  var result = '';
	if(monthC>=1){
		result="" + parseInt(monthC + '') + "月前";
	}
	else if(weekC>=1){
		result="" + parseInt(weekC + '') + "周前";
	}
	else if(dayC>=1){
		result=""+ parseInt(dayC + '') +"天前";
	}
	else if(hourC>=1){
		result=""+ parseInt(hourC + '') +"小时前";
	}
	else if(minC>=1){
		result=""+ parseInt(minC + '') +"分钟前";
	}else
	result="刚刚";
	return result;
}

const colorList = [
  '#007aff',
  '#38D5B0',
  '#FF948F',
  '#F4CE65',
  '#58C8D0',
  '#BBA3F6',
];