import React from "react";
import { Form, Input, Cascader } from 'antd';
import { FormInstance } from "antd/lib/form";
import cities from '@/utils/cities';
import { CascaderOptionType } from "antd/lib/cascader";

export interface IFormValue {
  name: string
  mobile: string
  cityName?: string
  areaName?: string
  provinceCode?: string
  cityCode?: string
  provinceName?: string
  areaCode?: string
  street: string
}

export default class AddressForm extends React.PureComponent<{customerName: string, initValues?: {
  name?: string
  mobile?: string
  area?: Array<string>
  street?: string
}}> {

  private selectOptions: CascaderOptionType[] | undefined

  private form = React.createRef<FormInstance>()

  constructor(props) {
    super(props);
    this.selectOptions = [];
  }

  public validateFields = async (): Promise<IFormValue> => {
    try {
      const values = await this.form.current?.validateFields();

      const data = { ...values };
      data.area = undefined;

      return Promise.resolve({
        name: data.name,
        mobile: data.mobile,
        street: data.street,
        provinceName: this.selectOptions && (this.selectOptions[0]?.label as string),
        cityName: this.selectOptions && (this.selectOptions[1]?.label as string),
        areaName: this.selectOptions && (this.selectOptions[2]?.label as string),
        provinceCode: this.selectOptions && this.selectOptions[0]?.value,
        cityCode: this.selectOptions && this.selectOptions[1]?.value,
        areaCode: this.selectOptions && this.selectOptions[2]?.value,
      });
    } catch (errorInfo) {
      return Promise.reject(errorInfo);
    }
  }

  render() {
    return <div>
      <Form ref={this.form} labelCol={{span: 4}} initialValues={this.props.initValues}>
        <Form.Item label="客户">
          <div>{this.props.customerName}</div>
        </Form.Item>
        <Form.Item label="收货人"
          name="name"
          rules={[{ required: true, message: '请填写收货人' }]}>
          <Input/>
        </Form.Item>
        <Form.Item label="手机"
          name="mobile"
          rules={[{
            required: true,
            message: '请填写手机号码'
          }, {
            pattern: /\d{11}$/,
            message: '请填写正确的手机号'
          }]}>
          <Input maxLength={11}/>
        </Form.Item>
        <Form.Item label="区域"
          name="area"
          rules={[{ required: true, message: '请选择区域' }]}>
          <Cascader
            options={cities}
            onChange={(value, selectOptions) => {
              this.selectOptions = selectOptions;
            }}
            placeholder="选择"
          />
        </Form.Item>
        <Form.Item label="详细地址"
          name="street"
          rules={[{ required: true, message: '请填写详细地址' }]}>
          <Input/>
        </Form.Item>
      </Form>
    </div>
  }
}