import WebApi from "@/web/api";
import React from "react";
import Dialog from "../dialog/Dialog";
import { showSuccess } from "../message/message";
import AddressForm, {IFormValue} from "./AddressForm";

export default class AddressCreateDialog extends React.PureComponent<{onOk(data: IFormValue): void}> {

  private addressForm = React.createRef<AddressForm>()

  state = {
    visible: false,
    customerName: '',
    customerId: '',
  };
  constructor(props) {
    super(props);
  }

  public open = (customerName: string, customerId: string) => {
    this.setState({
      visible: true,
      customerName,
      customerId,
    });
  }

  private onOK = () => {
    this.addressForm.current?.validateFields().then((data: IFormValue) => {
      WebApi.customer_address_create({
        ...data,
        customerId: this.state.customerId
      } as any).then(() => {
        showSuccess.save();
        this.setState({
          visible: false,
        });
        this.props.onOk(data);
      });
    })
  }

  render() {
    return <Dialog visible={this.state.visible} title="新增收货地址" full={false} onCancel={() => this.setState({
      visible: false
    })} onOk={this.onOK}>
      <AddressForm customerName={this.state.customerName} ref={this.addressForm}/>
    </Dialog>
  }
}