import React from "react";
import { Button, Tree } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import Layout from '../layout/Layout';
import { v4 } from 'uuid';

export default (props: {
  title: string
  showIcon?: boolean
  treeData: any[]
  buttons?: React.ReactElement[]
  onSelect(value: any[]): void
}) => {

  const [selectedKeys, setSelectedKeys] = React.useState(['all']);
return <Layout flexColumn height style={{width: 200}}>
  <div style={{
    borderBottom: '1px solid #DDDDDD',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    height: 30
  }}>
    <div>{props.title}</div>
    <div style={{flex: 1, textAlign: 'right'}}>
      {
        props.buttons && React.Children.map(props.buttons, (child: React.ReactElement) => {
          return React.cloneElement(child, {
            key: v4(),
            size: 'small',
            ...child.props
          });
        })
      }
    </div>
  </div>
  <div style={{borderRight: '1px solid #DDDDDD', flex: 1, paddingTop: 10, overflow: 'scroll'}}>
    <Tree
      showLine
      showIcon={props.showIcon}
      selectable
      selectedKeys={selectedKeys}
      // icon={<i className="iconfont icon-bumen"/>}
      switcherIcon={<DownOutlined />}
      defaultSelectedKeys={selectedKeys}
      defaultExpandedKeys={selectedKeys}
      treeData={props.treeData}
      onSelect={keys => {
        setSelectedKeys((keys.length === 0 ? ['all'] : keys) as any);
        props.onSelect(keys);
      }}
    />
  </div>
</Layout>
}