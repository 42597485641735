export default (props) => {
  const {style = {}} = props;
  return <div style={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      ...style}
    }>
    {props.children}
  </div>
};