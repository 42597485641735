import Home from '@/page/home/Home';
import { LibAuthCode } from 'lib/Auth';
import { routerMap } from './agent_router';
import common, { notFountRouter } from './common';
import { replace } from './util';

export const routerYchMap = {
  ...common,
  'home.view': {
    name: '主页',
    path: '/',
    exact: true,
    component: Home,
  },
  // 订货路由
  sale_order_list: {
    code: LibAuthCode.saleOrder.view,
    name: '订货单',
    path: '/ych/sale_order_list',
    asyncComponent: routerMap.sale_order_list.asyncComponent,
  },
  product_list: {
    code: LibAuthCode.saleOrder.view,
    name: '商品',
    path: '/ych/product_list',
    asyncComponent: routerMap.product_list.asyncComponent,
  },
  customer_list: {
    code: LibAuthCode.saleOrder.view,
    name: '客户',
    path: '/ych/customer_list',
    asyncComponent: routerMap.customer_list.asyncComponent,
  },
  'agent.view': {
    code: LibAuthCode.agent.view,
    name: '代理商',
    path: '/ych/agent/list',
    asyncComponent: () => import('../page/agent/YchAgent'),
  },
  'agent.create': {
    code: LibAuthCode.agent.create,
    name: '新增代理商',
    path: '/ych/agent/create',
    asyncComponent: () => import('../page/agent/create/YchAgentCreate'),
  },
  'agent.update': {
    code: LibAuthCode.agent.update,
    name: '修改代理商',
    path: '/ych/agent/update/:id',
    getPath(id) {
      return replace(this.path, {id});
    },
    asyncComponent: () => import('../page/agent/update/YchAgentUpdate'),
  },
  action_log_list: {
    code: LibAuthCode.actionLog.view,
    name: '系统日志',
    path: '/ych/log',
    asyncComponent: routerMap.action_log_list.asyncComponent,
  },
};

const routes = Object.keys(routerYchMap).map(key => routerYchMap[key]).concat([notFountRouter]);

export const setUserYchRoutes = (functionList: string[]) => {
  return routes.filter(item => functionList.includes(item.code))
};

export default routes;