import React from "react";
import { Tabs, Modal, Checkbox } from "antd";
import CustomerTable from "./CustomerTable";
import SupplierTable from "./SupplierTable";
import Dialog from '../dialog/Dialog';

export default class RelatedParty extends React.PureComponent<{
  onOk(data: {
    id: string;
    name: string;
  }): void
}> {
  state = {
    visible: false,
    customer: null,
    supplier: null,
    activeKey: 'customer'
  }
  render() {
    return <Dialog
      title="往来单位"
      visible={this.state.visible}
      onCancel={this.close}
      okButtonProps={{disabled: this.getDisabled()}}
      onOk={() => {
        const target: any = this.state[this.state.activeKey];
        this.props.onOk({
          id: target.id,
          name: target.customerName || target.supplierName
        });
        this.close();
      }}
    >
      <Tabs animated={false} className="app-tabs" activeKey={this.state.activeKey} onChange={key => this.setState({activeKey: key})}>
        <Tabs.TabPane key="customer" tab="客户" style={{padding: 0, paddingTop: 15}}>
          <CustomerTable onChange={(rowIds, rows) => {
            this.setState({
              customer: rows[0],
            });
          }}/>
        </Tabs.TabPane>
        <Tabs.TabPane key="supplier" tab="供应商" style={{padding: 0, paddingTop: 15}}>
          <SupplierTable onChange={(rowIds, rows) => {
            this.setState({
              supplier: rows[0]
            });
          }}/>
        </Tabs.TabPane>
      </Tabs>
    </Dialog>
  }

  public open = () => {
    this.setState({
      visible: true,
    });
  }

  public close = () => {
    this.setState({
      visible: false,
    });
  }

  private getDisabled() {
    if (this.state.activeKey === 'customer') {
      return !this.state.customer;
    }
    return !this.state.supplier;
  }
}