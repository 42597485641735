import React, { PureComponent } from 'react';
import ViewTable from '../table/ViewTable';
import Dialog from '../dialog/Dialog';
import { message } from 'antd';
import Layout from '../layout/Layout';
import WebApi from '@/web/api';
import Filter from '../filter/Filter';

interface Props {
  onOk(id: string): void
}

export default class CustomerSelectModal extends PureComponent<Props> {
  static defaultProps = {
    visible: false,
    onCancel: () => {},
    onOk: () => {},
  };

  private table: ViewTable | null = null

  state: {
    visible: boolean
  } = {
    visible: false,
  }

  selectedRows: any[] = [];

  render() {
    const {onOk} = this.props;
    const {visible} = this.state;
    return <Dialog
      visible={visible}
      title="选择客户"
      onCancel={() => this.close()}
      onOk={() => {
        if (this.selectedRows.length === 0) {
          message.warning('请选择客户');
          return;
        }
        onOk(this.selectedRows[0]);
      }}
    >
      <Layout flexColumn height overHidden>
        <Filter
          fields={[
            {
              type: 'input',
              field: 'keyword',
              name: '搜索',
              placeholder: '客户名称/手机号'
            }
          ]}
          onChange={(values: any) => {
            this.table?.reset({
              keyword: values.keyword
            });
          }}
        />
        <ViewTable
          flex1
          ref={r => this.table = r}
          rowKey={(row: any) => row.customerId}
          fetch={data => {
            return WebApi.customer_paging(data)
          }}
          columns={[
            {
              title: '客户',
              dataIndex: 'customerName',
              width: 200,
            },
            // {
            //   title: '客户编号',
            //   dataIndex: 'id',
            //   width: 200,
            //   align: 'center'
            // },
            {
              title: '手机号',
              dataIndex: 'mobile',
              width: 150,
              align: 'center'
            },
            {
              title: '业务员',
              dataIndex: 'employeeName',
              width: 'auto',
              titleAlign: 'left',
            },
          ]}
          rowSelection={{
            type: 'radio',
            onChange: (selectedRowKeys, selectedRows) => {
              this.selectedRows = selectedRows;
            }
          }}
        />
      </Layout>
    </Dialog>
  }

  public open = () => {
    this.setState({
      visible: true,
    });
  }

  public close = () => {
    this.setState({
      visible: false,
    });
    this.selectedRows = [];
  }
}
