import { request } from "./request";

export const AgentApi = {
  advert: {
    getAdvertList: (data) => request('/agent/advert/getAdvertList', data),
    editAdvert: (data) => request('/agent/advert/editAdvertList', data),
    saveAdvertPreviewList: (data) => request('/agent/advert/saveAdvertPreviewList', data),
  },
  product: {
    getProductList: (data) => request('/agent/product/getProductList', data),
    getProductPage: (data) => request('/agent/product/getProductPage', data),
    getCategoryList: () => request('/agent/product/getCategoryList', {}),
  },
  activity: {
    getActivityList: (data) => request('/agent/activity/getActivityList', data),
    getActivityPage: (data) => request('/agent/activity/getActivityPage', data),
  },
  upload: {
    image: (data) => request('/agent/upload/image', data),
  }
}