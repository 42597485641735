import { action, makeAutoObservable } from "mobx";

const queue: any[] = [];

class GlobalLoadingStore {
  isLoading: boolean = false

  constructor() {
    makeAutoObservable(this);
  }

  @action
  public showLoading(duration?: number) {
    const d = (duration || 3) * 1000;
    if (d > 0) {
      const t = setTimeout(() => {
        this.isLoading = true;
      }, d);
      queue.push(t);
    } else {
      this.isLoading = true;
    }
  }

  @action
  public hideLoading() {
    const t = queue.shift();
    t !== undefined && clearTimeout(t);
    if (queue.length === 0) {
      this.isLoading = false;
    }
  }
}

export default new GlobalLoadingStore();