import React from 'react';
import { Result, Button } from 'antd';

export default props => {
  return <Result
    status="403"
    title="403"
    subTitle="抱歉，您没有权限"
    extra={<Button type="primary" onClick={() => {
      props.history.replace('/');
    }}>返回首页</Button>}
  />
}