import React from "react";

const PageResult = (props: {
  text?: string
  children?: React.ReactNode
}) => {
  return <div style={{
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  }}>
    {props.children}
    <div style={{
      marginTop: 20,
      fontSize: 24,
      textAlign: 'center'
    }}>{props.text}</div>
  </div>
}

export default PageResult;

PageResult.Delete = () => {
  return <PageResult text="订单已删除">
    <img src={require('../../static/empty.png').default} style={{width: 370, height: 199}}/>
  </PageResult>
}

PageResult.Success = (props) => {
  return <PageResult text={props.children || '保存成功'}>
    <img src={require('../../static/success.png').default} style={{width: 240, height: 240}}/>
  </PageResult>
}