import WebApi from "@/web/api";
import { action, makeObservable, observable, runInAction } from "mobx";

class GlobalMessageStore {
  loading: boolean = false

  totalUnreadCount: number = 0;
  saleUnreadCount: number = 0;
  registerUnreadCount: number = 0;

  @observable registerMsgs: any[] = [];
  @observable saleMsgs: any[] = [];

  constructor() {
    makeObservable(this);
  }

  @action
  public unreadFindAndCount() {
    WebApi.message_unread_count().then((data: any) => {
      runInAction(() => {
        this.totalUnreadCount = data.totalUnreadCount;
        this.registerUnreadCount = data.registerUnreadCount;
        this.saleUnreadCount = data.saleUnreadCount;
      })
    })
  }

  @action
  public msgFindAndCount(msgType: IMessageType, pageNum: number, pageSize: number) {
    WebApi.message_paging({
      msgType,
      pageNum,
      pageSize,
    }).then((data: any) => {
      runInAction(() => {
        if (msgType === 'REGISTER') {
          this.registerMsgs = data.rows;
        }
        if (msgType === 'SALE') {
          this.saleMsgs = data.rows;
        }
      })
    })
  }
  
  @action
  public async updateToRead(msgIdList?: string[]) {
    await WebApi.message_read_update({
      msgIdList,
    }).catch(e => {});
  }
}

export default new GlobalMessageStore();