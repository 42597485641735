import { PRODUCT_STATUS } from '@/enum-data/enum';
import { Tag } from 'antd';
import React from 'react';

export default [
  {
    title: '主图',
    dataIndex: 'imageLogo',
    align: 'center',
  },
  {
    title: '商品名称',
    width: 280,
    dataIndex: 'productName',
  },
  {
    title: '分类',
    width: 150,
    dataIndex: 'categoryName',
  },
  {
    title: '状态',
    dataIndex: 'status',
    width: 100,
    align: 'center',
    render: (v, row) => {
      if (v === PRODUCT_STATUS.UP.value) return <Tag color="#87d068">{row.statusDesc}</Tag>
      if (v === PRODUCT_STATUS.DOWN.value) return <Tag color="#f50">{row.statusDesc}</Tag>
      return <Tag color="#f0f0f0">{row.statusDesc}</Tag>
    }
  },
  {
    title: '营销活动',
    width: 220,
    render: (v, row) => {
      if (!row.activity) return '无';
      return `(${row.activity.activityStatusDesc})` + row.activity.activityName
    },
  },
  {
    title: '价格(元)',
    dataIndex: 'price',
    width: 150,
    align: 'right',
  },
  {
    title: '起购数量',
    align: 'right',
    width: 120,
    dataIndex: 'minBuyCount',
  },
  {
    title: '预售量',
    align: 'right',
    dataIndex: 'stockPreCount',
    width: 100,
    render: v => v || 0,
  },
  {
    title: '可用库存量',
    align: 'right',
    dataIndex: 'stockUsableCount',
    width: 100,
  },
  {
    title: '实际库存量',
    align: 'right',
    dataIndex: 'stockActualCount',
    width: 100,
  },
  {
    title: '主计量单位',
    width: 150,
    dataIndex: 'unitName',
    align: 'center',
  },
];