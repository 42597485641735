import React from "react";
const style = require('./statistics_style.less');

export default (props: {
  options: {
    label: React.ReactNode;
    value: React.ReactNode;
  }[]
}) => {
  return <div className="comp-statistics">
    {
      props.options.map((item, index) => {
        return <div className="comp-statistics-item" key={index}>
          <p>{item.label}</p>
          {item.value}
        </div>
      })
    }
  </div>
}