import React from 'react';
import { Button, Radio, message } from 'antd';
import Dialog from '../dialog/Dialog';
import AddressCreateDialog from './AddressCreateDialog';
import WebApi from '@/web/api';

interface Props {
  customerId: string
  customerName: string
  addressId?: string
  onOk(address: IConsigneeAddress): void
}

interface State {
  selectAddressId: string
  visible: boolean
  addressList: IConsigneeAddress[]
}

export default class AddressListModal extends React.PureComponent<Props> {
  static defaultProps = {
    onOk: () => {},
  };

  private addressCreate = React.createRef<AddressCreateDialog>()
  private address: IConsigneeAddress | undefined = undefined

  state: State = {
    selectAddressId: '',
    visible: false,
    addressList: [],
  }

  constructor(props) {
    super(props);
  }

  public open = () => {
    this.setState({
      visible: true,
    });
    this.findAllAddress();
  }

  public close = () => {
    this.setState({
      visible: false,
      selectAddressId: '',
    });
  }

  private onChange = (e) => {
    const target = e.target.value;
    this.setState({
      selectAddressId: target
    });
  }

  render() {
    const { customerName, addressId, onOk } = this.props;

    const { visible, addressList, selectAddressId } = this.state;
    return <div>
      <Dialog
        visible={visible}
        full={false}
        title="选择收货地址"
        onCancel={() => this.setState({visible: false, selectAddressId: ''})}
        footer={[
          <Button type="ghost" key="cancel" onClick={() => this.setState({visible: false, selectAddressId: ''})}>
            取消</Button>,
          <Button type="primary" ghost key="create" onClick={() => {
            this.close();
            this.addressCreate.current?.open(this.props.customerName, this.props.customerId);
          }}>新增</Button>,
          <Button type="primary" key="ok" disabled={!selectAddressId} onClick={this.clickOk}>
            确定</Button>,
        ]}
        onOk={() => {
          this.address && onOk(this.address);
        }}
      >
        <div style={{minWidth: 700}}>
          <Radio.Group
            defaultValue={addressId}
            value={this.state.selectAddressId}
            onChange={this.onChange}
            style={{width: '100%'}}
          >
            {addressList.map((item: IConsigneeAddress) => {
              return <div key={item.addressId} style={{
                padding: '15px 0'
              }}>
                <Radio value={item.addressId}>
                  {item.name} {item.mobile} {item.provinceName}-{item.cityName}-{item.areaName}-{item.street}
                </Radio>
              </div>
            })}
          </Radio.Group>
        </div>
      </Dialog>

      {/* 新增地址 */}
      <AddressCreateDialog
        ref={this.addressCreate}
        onOk={() => {
          this.findAllAddress();
        }}
      />
    </div>
  }

  private clickOk = () => {
    if (!this.state.selectAddressId) {
      message.warn('请选择一个收货地址');
      return;
    }
    const address = this.state.addressList.find((item: IConsigneeAddress) => item.addressId === this.state.selectAddressId);

    if (!address) {
      message.warn('收货地址已发生变化，请刷新一下重试');
      return;
    }
    this.props.onOk(address);
  }

  private findAllAddress = () => {
    WebApi.customer_address_list({
      customerId: this.props.customerId,
    })
    .then(data => {
      this.setState({
        addressList: data
      });
    })
  }
}