import React, { PureComponent } from 'react';
import { Tag, Tree } from 'antd';
import Dialog from '../dialog/Dialog';
import ViewTable from '../table/ViewTable';
import Filter from '../filter/Filter';
import { EnumMeta } from 'src/meta/enum';
import { AgentApi } from 'src/api/AgentApi';
import { AppstoreOutlined } from '@ant-design/icons';
import classNames from 'classnames';
const style = require('./ProductDialog.less');

interface IState {
  selectedRowKeys: string[];
  selectedRows: any[];
  categoryList: any[];
  selectedCategoryId: string;
}

interface IProductDialogProps {
  multiple?: boolean;
  getRowDisabled?: ((row: any) => boolean);
  onOk?(data: {productId: string}[], params?: any): void;
  onClose(): void;
}

export class ProductDialog extends PureComponent<IProductDialogProps, IState> {

  private table = React.createRef<ViewTable>()

  private reqData = {
    keyword: '',
    categoryId: '',
    status: '11',
  };
  
  constructor(props) {
    super(props);

    this.state = {
      selectedRowKeys: [],
      selectedRows: [],
      categoryList: [],
      selectedCategoryId: ''
    };
  }

  componentDidMount(): void {
    AgentApi.product.getCategoryList().then(data => {
      this.setState({
        categoryList: data.map(i => {
          return {
            key: i.categoryId,
            title: i.categoryName,
            children: (i.children || []).map(c => {
              return {
                key: c.categoryId,
                title: c.categoryName,
                children: [],
              };
            })
          };
        }),
      });
    })
  }

  render() {
    const { multiple } = this.props;
    const { categoryList, selectedCategoryId } = this.state;
    return <Dialog
      visible
      okButtonProps={{
        disabled: this.state.selectedRowKeys.length === 0
      }}
      full={false}
      width={'100%'}
      onOk={this.clickOk}
      destroyOnClose
      onCancel={this.close}
      title="选择商品"
      wrapClassName="product-dialog"
    >
      <div className="product-dialog-content">
        <div className="product-dialog-content-left">
          <div className={classNames('product-dialog-all-category', {
            'product-dialog-all-category-selected': selectedCategoryId == ''
          })} onClick={this.onClickAllCategory}>
            <AppstoreOutlined/>
            <div>全部分类</div>
          </div>
          {
            categoryList.length > 0 &&
            <Tree
              className="product-category-tree"
              selectedKeys={[selectedCategoryId]}
              defaultExpandAll
              autoExpandParent
              treeData={categoryList}
              onSelect={this.onCategoryChange}
            ></Tree>
          }
          {
            categoryList.length > 0 &&
            <Tree
              className="product-category-tree"
              selectedKeys={[selectedCategoryId]}
              defaultExpandAll
              autoExpandParent
              treeData={categoryList}
              onSelect={this.onCategoryChange}
            ></Tree>
          }
        </div>
        <div className="product-dialog-content-right">
          <Filter
            fields={filterFields as any}
            onChange={this.onFilterChange}
          />
          <ViewTable
            flex1
            ref={this.table}
            fetch={this.fetch}
            rowSelection={{
              type: multiple ? 'checkbox' : 'radio',
              columnWidth: 60,
              selectedRowKeys: this.state.selectedRowKeys,
              onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                  selectedRowKeys: selectedRowKeys as string[],
                  selectedRows,
                })
              },
              getCheckboxProps: (record: any) => {
                const disabled = this.props.getRowDisabled ? this.props.getRowDisabled(record) : false;
                return {
                  disabled,
                };
              },
            }}
            columns={columns as any[]}
          />
        </div>
      </div>
    </Dialog>
  }

  private onFilterChange = values => {
    this.reqData = values as any;
    if (this.reqData.status) {
      this.reqData.status = EnumMeta.productStatus.online.value;
    } else {
      this.reqData.status = '';
    }
    this.table.current?.reset();
  }

  private onClickAllCategory = () => {
    this.setState({
      selectedCategoryId: ''
    });
    this.reqData.categoryId = '';
    this.table.current?.reset();
  }

  private onCategoryChange = (selectedKeys, e) => {
    const categoryId = e.node.key as string;
    if (e.selected) {
      this.setState({
        selectedCategoryId: categoryId
      });
    } else {
      this.setState({
        selectedCategoryId: ''
      });
    }
    this.reqData.categoryId = e.selected ? categoryId : '';
    this.table.current?.reset();
  }

  private fetch = (data) => {
    return AgentApi.product.getProductPage({...data, ...this.reqData});
  }

  private clickOk = () => {
    this.setState({
      selectedRowKeys: [],
      selectedRows: [],
    });
    this.props.onOk && this.props.onOk(this.state.selectedRows);
  }

  private close = () => {
    this.props.onClose();
  }
}

const filterFields = [
  {
    type: 'input',
    name: '搜索',
    field: 'keyword',
    placeholder: '商品名称/编号/关键词',
  },
  {
    type: 'checkbox',
    name: '只显示上架',
    field: 'status',
    defaultValue: true,
  }
]

const columns = [
  {
    title: '主图',
    dataIndex: 'imgUrl',
    align: 'center',
  },
  {
    title: '商品名称',
    width: 280,
    dataIndex: 'productName',
  },
  {
    title: '分类',
    width: 150,
    dataIndex: 'categoryName',
  },
  {
    title: '状态',
    dataIndex: 'status',
    width: 100,
    align: 'center',
    render: (v, row) => {
      if (v === EnumMeta.productStatus.online.value) return <Tag color="#87d068">{row.statusDesc}</Tag>
      if (v === EnumMeta.productStatus.offline.value) return <Tag color="#f50">{row.statusDesc}</Tag>
      return <Tag color="#f0f0f0">{row.statusDesc}</Tag>
    }
  },
  {
    title: '营销活动',
    width: 220,
    render: (v, row) => {
      if (!row.activity) return '无';
      return `(${row.activity.activityStatusDesc})` + row.activity.activityName
    },
  },
  {
    title: '单价(元)',
    dataIndex: 'price',
    width: 150,
    align: 'right',
  },
  {
    title: '起购量',
    align: 'right',
    width: 120,
    dataIndex: 'minBuyCount',
  },
  {
    title: '预售量',
    align: 'right',
    dataIndex: 'presaleCount',
    width: 100,
    render: v => v || 0,
  },
  {
    title: '可用库存量',
    align: 'right',
    dataIndex: 'forsaleCount',
    width: 100,
  },
  {
    title: '实际库存量',
    align: 'right',
    dataIndex: 'stockCount',
    width: 100,
  },
  {
    title: '主计量单位',
    width: 100,
    dataIndex: 'unitName',
    align: 'center',
  },
];