
export default class LibApi<IConfig> {

  protected async request(url: string, data?: any, config?: IConfig): Promise<any> {
    return;
  }

  constructor(request?: (url: string, data?: any, config?: IConfig) => Promise<any>) {
    if (typeof request === 'function') {
      this.request = request;
    }
  }
}