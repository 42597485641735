import React from 'react';
import setUserMenu from './menu';

import {routerYchMap} from '@/router/ych_router';

import {Sub_Menu, Menu_Item} from './type';
import { routerMap } from '@/router/agent_router';

export * from './type';

const filterComponent = (routerItem) => {
  const item: any = {};
  item.path = routerItem.path;
  item.code = routerItem.code;
  return item;
};

const style = {
  fontSize: 14,
  verticalAlign: 'middle'
};

const menus = [
  {
    name: '主页',
    icon: <i className="iconfont icon-shouye" style={style}/>,
    type: Menu_Item,
    ...filterComponent(routerYchMap['home.view']),
  },
  {
    name: '订单',
    icon: <i className="iconfont icon-dingdan" style={style}/>,
    type: Menu_Item,
    ...filterComponent(routerYchMap.sale_order_list),
  },
  {
    name: '商品',
    icon: <i className="iconfont icon-shangpin" style={style}/>,
    type: Menu_Item,
    ...filterComponent(routerYchMap.product_list),
  },
  {
    name: '代理商',
    ...filterComponent(routerYchMap['agent.view']),
    type: Menu_Item,
    icon: <i className="iconfont icon-gongsi" style={style}/>,
  },
  {
    name: '客户',
    ...filterComponent(routerYchMap.customer_list),
    type: Menu_Item,
    icon: <i className="iconfont icon-kehu" style={style}/>,
  },
  {
    name: '系统日志',
    ...filterComponent(routerYchMap.action_log_list),
    type: Menu_Item,
    icon: <i className="iconfont icon-rizhi" style={style}/>,
  },
];


export const setUserYchMenu = (functionList: any[]) => {
  return setUserMenu(functionList, menus);
}

export default menus;