import React, { PureComponent } from 'react';
import { Drawer, Menu, Tabs, List, Button, Badge, message } from 'antd';
import { subscribe } from '@/modules/events';
import globalMessageStore from '@/global_store/globalMessageStore';
import { routerMap } from '@/router/agent_router';
import { observer } from 'mobx-react';
import { withRouter } from 'dva/router';
import WebApi from '@/web/api';

const { TabPane } = Tabs;

const TabPaneTitle = props => {
  return <div style={{display: 'flex', alignItems: 'center'}}>
    <span style={{marginRight: 5}}>{props.children}</span>
    <Badge overflowCount={99} count={props.count || 0}/>
  </div>
}

const Title = (props) => {
  return <>
    {!props.isRead && <Badge status="error" />}
    {props.content}
  </>
};

const LoadMore = () => {
  return <div style={{
    padding: 15,
    textAlign: 'center',
    color: '#666'
  }}>
    只显示最近50条消息
  </div>
}

const TabPaneList = (props: {dataSource: any[], clickItem(data?: any): void}) => {
  return <List
    loading={globalMessageStore.loading}
    itemLayout="horizontal"
    dataSource={props.dataSource}
    renderItem={item => (
      <List.Item key={item.id} onClick={() => {
        props.clickItem(item);
      }}>
        <List.Item.Meta
          title={<Title content={item.content} isRead={item.isRead}/>}
          description={item.createTime}
        />
      </List.Item>
    )}
    loadMore={<LoadMore/>}
  />
}

@observer
class AppMessage extends PureComponent<RouterPropsType> {
  state = {
    visible: false,
  };

  componentDidMount() {
    globalMessageStore.unreadFindAndCount();
    // globalMessageStore.findAllUnread();
    subscribe('message', () => {
      globalMessageStore.unreadFindAndCount();
    });

    // globalMessageStore.findAll({
    //   msgType: 'reg',
    //   pageNum: 1,
    //   pageSize: 50,
    // });
    globalMessageStore.msgFindAndCount('REGISTER', 1, 50);
  }

  render() {
    const { visible } = this.state;

    return (
      <div style={{
        height: '30px',
        display: 'flex',
        alignItems: 'center',
        padding: '0 15px'
      }} >
        <div onClick={() => this.setState({visible: true})}>
          <Badge count={globalMessageStore.totalUnreadCount} overflowCount={99}>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer'
            }}>
              <i className="iconfont icon-xiaoxi" style={{fontSize: 12, marginRight: 3}}></i>
              <span>消息</span>
            </div>
          </Badge>
        </div>

        <Drawer visible={visible} title={false}
          closable={false}
          width={420}
          destroyOnClose
          onClose={() => this.setState({visible: false})}
          footer={
            <div style={{textAlign: 'left'}}>
              <Button disabled={globalMessageStore.totalUnreadCount === 0} onClick={this.clickMarkReadAll} style={{ marginRight: 20 }}>
                全部标记为已读
              </Button>
              <Button type="primary" onClick={this.clickOpenMessage}>
                显示全部
              </Button>
            </div>
          }
          >
          <div style={{height: '100%'}}>
            <Tabs animated={false} defaultActiveKey="1" tabBarGutter={20} onChange={this.onChangeTabKey}>
              {/* 注册消息 */}
              <TabPane tab={<TabPaneTitle count={globalMessageStore.registerUnreadCount}>注册消息</TabPaneTitle>} key="REGISTER">
                <TabPaneList dataSource={globalMessageStore.registerMsgs} clickItem={this.clickItem}/>
              </TabPane>

              {/* 订单消息 */}
              <TabPane tab={<TabPaneTitle count={globalMessageStore.saleUnreadCount}>订货消息</TabPaneTitle>} key="SALE">
                <TabPaneList dataSource={globalMessageStore.saleMsgs} clickItem={this.clickItem}/>
              </TabPane>

              {/* 系统通知 */}
              {/* <TabPane tab={<TabPaneTitle count={globalMessageStore.sysUnreadCount}>系统通知</TabPaneTitle>} key="3">
                <TabPaneList dataSource={globalMessageStore.saleByAgentMsgs} clickItem={this.clickItem}/>
              </TabPane> */}
            </Tabs>
          </div>
        </Drawer>
      
      </div>
    )
  }

  onChangeTabKey = key => {
    globalMessageStore.msgFindAndCount(key, 1, 50);
  }

  clickItem = (item) => {
    this.setState({visible: false});

    if (item.isRead == 0) {
      // 标记已读
      globalMessageStore.updateToRead([item.id]);
    }
  }

  clickOpenMessage = () => {
    this.setState({visible: false});
    this.props.history.push(routerMap['message.view'].getPath('reg'));
  }

  clickMarkReadAll = () => {
    this.setState({
      allReadBtn: false,
    })

    WebApi.message_read_update()
    .then(() => {
      this.setState({visible: false, allReadBtn: true});
    });
  }
}


// @ts-ignore
export default withRouter(AppMessage);
