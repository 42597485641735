import React from 'react';
import { Form, Input, DatePicker } from 'antd';
import { FormInstance } from 'antd/lib/form';
import Dialog from '../dialog/Dialog';
import dayjs from 'dayjs';

interface Props {
  actualAmount: any
  shouldAmount: any
  onOk(data: {
    actualAmount: string;
    datetime: string;
    remark: string;
  }): void
}

export default class BookCreateDialog extends React.PureComponent<Props> {
  private form: FormInstance | null = null;
  state = {
    visible: false,
    loading: false,
  }
  render() {
    const { actualAmount, shouldAmount} = this.props;
    return <Dialog title="添加付款记录"
      visible={this.state.visible}
      full={false}
      onCancel={() => this.setState({visible: false})}
      onOk={this.clickOnOk}
      okButtonProps={{
        loading: this.state.loading,
        disabled: this.state.loading,
      }}
    >
      <div>
        <Form ref={r => this.form = r}
          labelCol={{span: 5}}
          initialValues={{
            actualAmount,
          }}
        >
          <Form.Item label="应付金额">
            <div style={{ fontSize: 20, color: '#f96868' }}>{shouldAmount}</div>
          </Form.Item>
          <Form.Item label="实付金额" name="actualAmount"
            normalize={(value, prevValue) => {
              if (isNaN(value) && prevValue !== '') {
                return prevValue;
              }
              return value;
            }}
            rules={[{
              transform: value => Number(value),
              validator: (rule, value) => {
                if (isNaN(value)) {
                  return Promise.reject('请填写金额');
                }
                return Promise.resolve();
              },
              required: true,
              type: 'number',
              message: '请填写正确的金额',
              }]}>
            <Input placeholder="填写"/>
          </Form.Item>
          <Form.Item label="付款日期" name="receivedTime"
            rules={[{
              required: true,
              message: `请选择付款日期`,
            }]}>
            <DatePicker showTime placeholder="选择" style={{width: '100%'}}/>
          </Form.Item>
        <Form.Item label={`付款备注`} name="remark">
          <Input placeholder="填写"/>
        </Form.Item>
      </Form>
      </div>
    </Dialog>
  }

  public open = () => {
    this.setState({
      visible: true,
    });
  }

  public close = () => {
    this.setState({
      visible: false,
    });
  }

  private clickOnOk = () => {
    this.form?.validateFields().then((data) => {
      this.props.onOk && this.props.onOk({
        actualAmount: data.actualAmount,
        datetime: dayjs(data.datetime).format('YYYY-MM-DD HH:mm:ss'),
        remark: data.remark,
      });
    });
  }
}