import React from "react";
import Dialog from "../dialog/Dialog";
import { message, Tree } from 'antd';
import apis from "@/api/api";
import WebApi from "@/web/api";

interface ISelected {
  categoryName: string;
  categoryId: string;
}

export default class CategorySelectDialog extends React.PureComponent<{
  levels: Array<'level1' | 'level2'>;
  onOk(data: ISelected): void;
}> {

  state = {
    visible: false,
    categories: [],
    categoryName: '',
    categoryId: '',
  }

  componentDidMount() {
    this.findCategories();
  }

  public open = () => {
    this.setState({
      visible: true
    });
  }

  public close = () => {
    this.setState({
      visible: false
    });
  }

  render() {
    return <Dialog title="选择商品分类" block okButtonProps={{disabled: !this.state.categoryId}} visible={this.state.visible}
      onCancel={() => this.close()}
      onOk={this.clickOk}>
      <div>
        <Tree
          style={{ width: '300px' }}
          defaultExpandAll
          onSelect={(selectedKeys, e) => {
            if (e.selected) {
              this.setState({
                categoryName: e.node.title as string,
                categoryId: e.node.key as string
              });
            } else {
              this.setState({
                categoryName: '',
                categoryId: ''
              });
            }
          }}
          treeData={this.getCategoryTreeData(this.state.categories)}
        ></Tree>
      </div>
    </Dialog>
  }

  private findCategories = () => {
    WebApi.category_list({
      withImage: false,
    })
    .then(data => {
      this.setState({
        categories: data
      });
    });
  }

  private getCategoryTreeData = array =>
    array.map(item => {
      return {
        title: item.categoryName,
        key: item.categoryId,
        disabled: !this.props.levels.includes('level1'),
        children: Array.isArray(item.children) ? item.children.map(c => {
          return {
            title: c.categoryName,
            key: c.categoryId,
            disabled: !this.props.levels.includes('level2'),
            children: [],
          };
        }) : [],
      };
    });

  private clickOk = () => {
    this.props.onOk({
      categoryName: this.state.categoryName,
      categoryId: this.state.categoryId,
    });
    this.close();
  }
}