import { Modal } from 'antd';
const AppInfoParser = require('app-info-parser/src/apk');
const JSZip = require("jszip");
export default (e, callback1, callback2) => {
  const files = e.target.files;
  if (!files || !files[0]) {
    return;
  }

  const file = files[0];

  if (file.type === 'application/vnd.android.package-archive') {
    const parser = new AppInfoParser(file)
    parser.parse().then(result => {
      callback1(result, file);
    }).catch(err => {
      console.log('err ----> ', err);
      Modal.error({
        content: '文件解析失败[' + e.message + ']',
      });
    })
  }
  if (file.type === 'application/zip') {
    let zip = zip = new JSZip();
    zip.loadAsync(file).then(data => {
      const filenames = Object.keys(data.files);
      const versionFileName = filenames.find(f => f.endsWith('version.json'));
      if (!versionFileName) {
        throw new Error('版本信息文件不存在');
      }

      const versionFile = zip.file(versionFileName);
      if (versionFile) {
        versionFile.async('string').then(versionContent => {
          const versionData = JSON.parse(versionContent);
          console.log(versionData);
          if (!versionData.platform) {
            throw new Error('版本信息文件错误，缺少平台信息');
          }
          callback2(versionData, file, filenames);
        }).catch(e => {
          Modal.error({
            content: '解析版本信息文件失败[' + e.message + ']',
          });
        });
      } else {
        Modal.error({
          content: '版本信息文件不存在'
        });
      }
    });
  }
};