module.exports = {
  'menu-color': '#F3C246',
  'primary-color': '#1890ff',
  // 'primary-color': '#5871ef',
  'secondary-color': '#faf4e8',
  'table-color': '#323232',
  // 'table-color': '#3ec8dd',
  'theme-color': '#323232',
  'hover-color': '#e6f7ff',
  'selected-color': '#e6f7ff',
  'default-button-color': '#1890ff',
  // 'default-button-color': '#3FC8DD',
  'link-color': '#997c4b',
  // 'bar-color': '#f3f5f9',
  'bar-color': 'rgb(240, 240, 240)',
  'border-radius-base': '2px',
  'success-color': '#52c41a', // 成功色
  'warning-color': '#faad14', // 警告色
  'error-color': '#f5222d', // 错误色
  'font-size-base': '12px', // 主字号
  'heading-color': 'rgba(0, 0, 0, 0.85)', // 标题色
  'text-color': 'rgba(0, 0, 0, 0.65)', // 主文本色
  'text-color-secondary': 'rgba(0, 0, 0, 0.45)', // 次文本色
  'disabled-color': 'rgba(0, 0, 0, 0.25)', // 失效色
  'border-radius-base': '4px', // 组件/浮层圆角
  'border-color-base': '#d9d9d9', // 边框色
  'box-shadow-base': '0 2px 8px rgba(0, 0, 0, 0.15)', // 浮层阴影
  // or
  // 'hack': `true; @import "your-less-file-path.less";`, // Override with less file
};