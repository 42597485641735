import { createCatch, findCatch, updateCatch } from "../catching";
import request from "../request";

export default {
  activity_fac: {
    url: '/agent/activity/fac',
    post: function(data: {
      activityName?: string;
      activityType?: IActivityType;
      activityStatus?: 'WAIT' | 'ONGOING' | 'DONE'
    }) {
      return request(this.url, data).catch(e => findCatch('活动', e));
    }
  },
  activity_rule_fac: {
    url: '/agent/activity/rule/fac',
    post: function(data: {
      activityName?: string
      productName?: string
    }) {
      return request(this.url, data).catch(e => findCatch('活动规则', e));
    }
  },
  'activity_rule_dtl_fac': {
    url: '/agent/activity/rule_dtl/fac',
    post: function(data: {
      activityId: string;
      pageNum?: number;
      pageSize?: number;
    }) {
      return request(this.url, data).catch(e => findCatch('活动规则', e));
    }
  },
  'activity_special_dtl_fac': {
    url: '/agent/activity/special_dtl/fac',
    post: function(data: {
      activityName?: string
      productName?: string
    }) {
      return request(this.url, data).catch(e => findCatch('特价明细', e));
    }
  },
  'activity.gift.findAndCount': {
    url: '/agent/activity/gift/fac',
    post: function(data: {
      activityName?: string
      productName?: string
    }) {
      return request(this.url, data).catch(e => findCatch('赠品明细', e));
    }
  },
  activity_fd: {
    url: '/agent/activity/fd',
    post: function(data: {
      activityId: string
    }) {
      return request(this.url, data).catch(e => findCatch('活动详情', e));
    }
  },
  'activity.update': {
    url: '/agent/activity/update',
    post: function(data: {
      activityId: string
      endDatetime?: string
      rules?: (IActivityDiscountRule)[]
    }) {
      return request(this.url, data).catch(e => updateCatch('修改活动', e));
    }
  },
  'activity.discount.create': {
    url: '/agent/activity/discount/create',
    post: function(data: {
      activityName: string
      activityType: IActivityType
      rules: IActivityRule[]
      startDatetime: string
      endDatetime: string
      withCoupon: 0 | 1
    }) {
      return request(this.url, data).catch(e => createCatch('新增活动', e));
    }
  },
  'activity.special.create': {
    url: '/agent/activity/special/create',
    post: function(data: {
      activityName: string
      activityType: IActivityType
      rules: IActivityRule[]
      startDatetime: string
      endDatetime: string
      withCoupon: 0 | 1
    }) {
      return request(this.url, data).catch(e => createCatch('新增活动', e));
    }
  },
  'activity.gift.create': {
    url: '/agent/activity/gift/create',
    post: function(data: {
      activityName: string
      activityType: IActivityType
      rules: IActivityRule[]
      startDatetime: string
      endDatetime: string
      withCoupon: 0 | 1
    }) {
      return request(this.url, data).catch(e => createCatch('新增活动', e));
    }
  },
}