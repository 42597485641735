export const EnumMeta = {
  saleStatus: {
    waitAudit: {
      name: '待审核',
      value: '11',
    },
    waitOutstock: {
      name: '待出库',
      value: '12',
    },
    waitDelivery: {
      name: '待发货',
      value: '13',
    },
    done: {
      name: '已完成',
      value: '14',
    },
    cancel: {
      name: '已取消',
      value: '99',
    },
  },
   // 付款状态
   receiveStatus: {
    waitPay: {
      name: '待付款',
      value: '11',
    },
    done: {
      name: '已付款',
      value: '13',
    },
  },
  // 打印状态
  printStatus: {
    waitPrint: {
      name: '未打印',
      value: '11',
    },
    done: {
      name: '已打印',
      value: '12',
    },
  },
  // 下单方式
  orderWay: {
    agent: {
      name: '代下单',
      value: '11',
    },
    customer: {
      name: '自主下单',
      value: '12'
    },
  },
  advert: {
    swiper: {
      value: 'CAROUSEL',
      name: '海报轮播'
    },
    brand: {
      value: 'BRAND',
      name: '品牌导航'
    }
  },
  productStatus: {
    online: {
      value: '11',
      name: '上架'
    },
    offline: {
      value: '12',
      name: '下架'
    }
  },
  // 图片类型
  imageType: {
    category: {
      value: '400',
      name: '分类图片'
    },
    productMain: {
      name: '商品主图',
      value: '401',
    },
    productCarousel: {
      name: '商品轮播图',
      value: '402',
    },
    productDetail: {
      name: '商品详情图',
      value: '403',
    },
  },
  // 优惠券发放状态
  coupon_give_status: {
    wait_give: {
      name: '待发放',
      value: 'WAIT',
    },
    giving: {
      name: '发放中',
      value: 'GOING'
    },
    done: {
      name: '已结束',
      value: 'DONE'
    },
  },
}

export const getEnumMap = (keyName: keyof typeof EnumMeta) => {
  return EnumMeta[keyName];
}

export const getEnumOptions = (keyName: keyof typeof EnumMeta) => {
  const map = EnumMeta[keyName];
  const options: {name: string; value: string}[] = [];
  for (let item in map) {
    options.push(map[item]);
  }
  return options;
}

export const getEnumName = (keyName: keyof typeof EnumMeta, value: string) => {
  if (!EnumMeta[keyName]) return '';
  for (let key in EnumMeta[keyName]) {
    if (EnumMeta[keyName][key].value == value) {
      return EnumMeta[keyName][key].name;
    }
  }
}

export const appendEnumName = (data: any[], fieldName: string, keyName: keyof typeof EnumMeta) => {
  const options = getEnumOptions(keyName);
  for (let item of data) {
    const option = options.find(i => i.value == item[fieldName]);
    if (option) {
      item[fieldName + 'Desc'] = option.name;
    }
  }
}