import dev from  './config.dev';
import prod from './config.prod';
import test from './config.test';

const config = {
  development: dev,
  test: test,
  production: prod,
}[__ENV__];

export default {
  displayName: '易采惠',
  apiUrl: 'http://apps.yicaihui.net',
  customerName: '客户',
  batchAddProductUrl: 'http://www.yicaihui.net/ych_web/download/template/?type=product',
  ...config
}