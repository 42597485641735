import WebApi, { YchApi } from "@/web/api";
import { Checkbox, DatePicker, Input, Select, TreeSelect } from "antd";
import React from "react";
import dayjs from 'dayjs';
import './filter.less';
import { observer } from "mobx-react";
import { LibIAgentInfo } from "lib/interface/AgentInterface";
import CustomerSelectDialog from "../customer-select-modal/CustomerSelectModal";
import globalUserStore from '@/global_store/globalUserStore';

interface IFilterFieldOption {
  name: string;
  value: string;
}

interface IFilterFieldBase {
  field: string;
  name: string;
  width?: number;
  defaultValue?: any;
  placeholder?: string;
}

interface IFilterFieldItem extends IFilterFieldBase{
  type: 'select' | 'input' | 'checkbox' | 'dateRange' | 'supplier';
  options?: IFilterFieldOption[] | (() => Promise<IFilterFieldOption[]>);
}

interface IFilterFieldCategory extends Partial<IFilterFieldBase>{
  type: 'category';
}

interface IFilterFieldCustomer extends Partial<IFilterFieldBase>{
  type: 'customer';
}

interface IFilterFieldSupplier extends Partial<IFilterFieldBase>{
  type: 'supplier';
}

interface IFilterFieldRenderValue extends IFilterFieldBase{
  type: 'renderValue';
  renderValue?(): React.ReactNode;
}

export type IFilterField = IFilterFieldItem | IFilterFieldCategory | IFilterFieldCustomer | IFilterFieldSupplier | IFilterFieldRenderValue;

export interface IFilterProps {
  fields: IFilterField[];
  onChange?(values: {}): void;
  children?: React.ReactNode;
  ych?: boolean;
}

interface IState {
  option: {
    [key: string]: IFilterFieldOption[]
  };
  values: {
    [key: string]: string | number | boolean | string[] | dayjs.Dayjs[]
  };
  categories: any;
  agentList: LibIAgentInfo[];
  customerId: string;
  customerName: string;
  supplierOptions: IFilterFieldOption[];
}

@observer
export default class Filter extends React.PureComponent<IFilterProps, IState> {
  private values = {};

  private customerSelectDialog = React.createRef<CustomerSelectDialog>();

  constructor(props) {
    super(props);

    this.state = {
      values: {},
      option: {},
      categories: [],
      agentList: [],
      customerId: '',
      customerName: '',
      supplierOptions: [],
    };
  }

  componentDidMount() {
    if (this.props.ych && globalUserStore.isYch()) {
      YchApi.agent_list().then((data: any) => {
        this.setState({
          agentList: data
        });
      });
    }

    if (this.props.fields.some(item => item.type === 'supplier')) {
      WebApi.supplier_list().then((data: any) => {
        this.setState({
          supplierOptions: data.map(item => {
            return {
              value: item.supplierId,
              name: item.supplierName,
            };
          }),
        });
      });
    }
    
    if (this.props.fields.some(item => item.type === 'category')) {
      WebApi.category_list()
      .then(data => {
        this.setState({
          categories: data
        });
      });
    }

    for (let item of this.props.fields) {
      if (item.type === 'select' && typeof item.options === 'function') {
        item.options().then((data) => {
          const option = this.state.option || {};
          if (item.field) {
            option[item.field] = data;
          }
          this.setState({
            option: {...option},
          });
        });
      }
    }
  }

  render() {
    const fields = [...this.props.fields];
    if (this.props.ych && globalUserStore.isYch()) {
      fields.unshift({
        field: 'agentId',
        name: '代理商',
        options: this.state.agentList.map(item => {
          return {
            name: item.agentName,
            value: item.agentId,
          };
        }),
        width: 200,
        type: 'select',
      });
    }
    return <div className="component-filter">
      <div className="component-filter-left">
        {
          fields.map(item => {
            if (item.type === 'renderValue') {
              return <FilterItem name={item.name} key={item.field}>{item.renderValue?.()}</FilterItem>
            }
            if (item.type === 'category') {
              return <FilterItem name={item.name || '商品分类'} key={item.field || 'categoryId'}>
                <TreeSelect
                  style={{width: 120}}
                  treeDefaultExpandAll
                  defaultValue=""
                  className="search-tree-select"
                  dropdownMatchSelectWidth={200}
                  dropdownStyle={{minHeight: 200, maxHeight: 400}}
                  onSelect={(categoryId) => {
                    this.onChange(item.field || 'categoryId', categoryId);
                  }}
                  treeData={[{
                    title: '全部分类',
                    value: '',
                    children: this.state.categories.map((i: CategoryType) => {
                      return {
                        title: i.categoryName,
                        value: i.categoryId,
                        children: i.children.map(c => {
                          return {
                            title: c.categoryName,
                            value: c.categoryId,
                          };
                        })
                      };
                    })
                  }]}
                />
              </FilterItem>
            }
            if (item.type === 'select' || item.type === 'supplier') {
              let options = item.type === 'select' ? item.options : this.state.supplierOptions;
              if (item.type === 'select' && typeof item.options === 'function') {
                options = this.state.option[item.field] || [];
              }
              const width = item.type === 'supplier' ? 150 : 100;
              return <FilterItem name={item.name || '供应商'} key={item.field}>
                <Select style={{width: item.width || width}}
                  placeholder={item.placeholder}
                  value={this.state.values[item.field || 'supplierId'] as string}
                  defaultValue={item.defaultValue || ''}
                  suffixIcon={<i className="iconfont icon-arrow-down"/>}
                  onChange={value => {
                    this.onChange(item.field || 'supplierId', value);
                  }}
                >
                  {
                    (!Array.isArray(options) || !options.some(item => item.name === '全部')) && <Select.Option value="" key="all">全部</Select.Option>
                  }
                  {
                    Array.isArray(options) && options.map(opt => {
                      return <Select.Option value={opt.value} key={opt.value || 'all'}>{opt.name}</Select.Option>
                    })
                  }
                </Select>
              </FilterItem>
            }
            if (item.type === 'input') {
                return <FilterItem name={item.name} key={item.field}>
                  <Input.Search style={{width: item.width || 300}}
                    value={this.state.values[item.field] as string}
                    placeholder={item.placeholder}
                    enterButton={<i className="iconfont icon-chazhao"/>}
                    allowClear
                    onChange={e => {
                      this.state.values[item.field] = e.target.value;
                      this.setState({
                        values: {...this.state.values}
                      });
                    }}
                    onSearch={value => {
                      this.onChange(item.field, value);
                    }}
                  />
                </FilterItem>
            }
            if (item.type === 'checkbox') {
              return <Checkbox key={item.field} defaultChecked={item.defaultValue} onChange={e => {
                this.onChange(item.field, e.target.checked);
              }}>{item.name}</Checkbox>
            }

            if (item.type === 'dateRange') {
              return <FilterItem name={item.name} key={item.field}>
                <DatePicker.RangePicker style={{width: 300}} value={this.state.values[item.field] || [] as any} allowClear disabledDate={current => {
                  return current && current > dayjs().endOf('day');
                }} onChange={(d, dateString) => {
                  this.onChange(item.field, d || undefined);
                }}/>
              </FilterItem>
            }

            if (item.type === 'customer') {
              return <FilterItem name={item.name || '客户'} key={item.field || 'customerId'}>
                <Select
                  style={{width: 180}}
                  open={false}
                  value={this.state.customerName || undefined}
                  placeholder="选择"
                  onClick={this.clickOpenCustomerDialog}
                />
              </FilterItem>
            }
            return null;
          })
        }

        {
          this.props.fields.some(item => item.type === 'customer') && <CustomerSelectDialog
            ref={this.customerSelectDialog}
            onOk={this.clickOkCustomerDialog}
          />
        }
      </div>
      <div className="component-filter-right">{this.props.children}</div>
    </div>
  }

  reset = () => {
    this.setState({
      values: {}
    });
  }

  private clickOpenCustomerDialog = () => {
    this.customerSelectDialog.current?.open();
  }

  private clickOkCustomerDialog = (data) => {
    this.setState({
      customerId: data.customerId,
      customerName: data.customerName,
    });
    const field = this.props.fields.find(item => item.type === 'customer');
    this.customerSelectDialog.current?.close();
    this.onChange(field?.field || 'customerId', data.customerId);
  }

  private onChange = (field, value) => {
    this.state.values[field] = value;
    this.setState({
      values: {...this.state.values}
    });
    const values = {...this.state.values};
    for (let item of this.props.fields) {
      if (item.type === 'dateRange') {
        const value = values[item.field];
        if (value && Array.isArray(value) && value.length > 0) {
          values[item.field] = value.map(d => dayjs(d).format('YYYY-MM-DD'));
        }
      }
    }
    this.props.onChange?.(values);
  }
}

const FilterItem = (props: {name: string; children: React.ReactNode}) => {
  return <div className="component-filter-item">
    <span className="component-filter-item-name">{props.name}</span>
    {props.children}
  </div>
}