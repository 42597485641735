import { Spin } from "antd";
import classNames from "classnames";
import React from "react";
const style = require('./layout.less');

export interface ILayoutProps {
  className?: string;
  children?: React.ReactNode;
  page?: boolean;
  flexColumn?: boolean;
  flex1?: boolean;
  padding?: boolean;
  overHidden?: boolean;
  height?: boolean;
  white?: boolean;
  scrollY?: boolean;
  style?: React.CSSProperties;
}

const appendClass = (prefix = '', props: ILayoutProps) => {
  return classNames(prefix, {
    [props.className || '']: !!props.className,
    'layout-page': props.page,
    'layout-flex-column': props.flexColumn,
    'layout-flex1': props.flex1,
    'layout-over-hidden': props.overHidden,
    'layout-padding': props.padding,
    'layout-height': props.height,
    'layout-white': props.white,
    'layout-scroll-y': props.scrollY,
  })
}

const Layout = (props: ILayoutProps) => {
  return <div className={appendClass('layout', props)} style={props.style}>{props.children}</div>
}

interface ILayoutPageProps extends ILayoutProps{
  loading?: boolean;
  children?: React.ReactNode;
}

const Page = (props: ILayoutPageProps) => {
  if (props.loading) {
    return <div style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#fff'}}>
      <Spin spinning tip="正在加载..."/>
    </div>
  }
  return <div className={appendClass('layout-page', props)} style={props.style}>{props.children}</div>
}

const TabPage = (props: ILayoutPageProps) => {
  return <div className={appendClass('layout-tab-pane', props)}>{props.children}</div>
}

interface ILayoutToolbarProps {
  title?: React.ReactNode;
  children?: React.ReactNode;
  transparent?: boolean;
}

const Toolbar = (props: ILayoutToolbarProps) => {
  return <div className={classNames('layout-toolbar', {
    'layout-toolbar-transparent': props.transparent
  })}>
    <div className="layout-toolbar-left">{props.title}</div>
    <div className="layout-toolbar-right">{props.children}</div>
  </div>
}

const FootBar = (props) => {
  return  <div className="layout-foot-bar">
    {props.children}
  </div>
}

const TabBarExtra = (props) => {
  return  <div className="layout-tab-bar-extra">
    {props.children}
  </div>
}

const Title = (props: {
  icon?: string;
  background?: boolean;
  children: React.ReactNode
  style?: React.CSSProperties
}) => {
  const icon = props.icon || 'icon-info';
  const style = props.style || {};
  const {background = true} = props;
  return <div className="flex-row align-center" style={{marginBottom: 15, ...style}}>
    {
      icon &&
      <div className="flex-row align-center just-center" style={{
        width: 20,
        height: 20,
        background: background ? '#68A6F6' : 'none',
        color: background ? '#fff': '#68A6F6',
        borderRadius: 25,
        overflow: 'hidden',
      }}>
        <i className={"iconfont " + icon} style={{fontSize:14}}/>
      </div>
    }
    <div style={{marginLeft: 5, fontSize: 16, lineHeight: 1, display: 'flex', alignItems: 'center'}}>{props.children}</div>
  </div>
}

Layout.Page = Page;
Layout.TabPage = TabPage;
Layout.FootBar = FootBar;
Layout.Toolbar = Toolbar;
Layout.TabBarExtra = TabBarExtra;
Layout.Title = Title;
Layout.FootBar = FootBar;

export default Layout;