import globalLoadingStore from "@/global_store/globalLoadingStore";
import React from "react";
const style = require('./message.less');

let timer: any = null;

const create = (content: string, preCls: string, className: string, duration?: number) => {
  const messageDiv = document.getElementById('global-message-content');
  if (messageDiv) {
    clearTimeout(timer);
    messageDiv.innerText = content;
    const div = document.getElementById('global-message');
    div && div.setAttribute('class', 'app-fixed-message-box' + ' ' + preCls);
    const icon = document.getElementById('message-icon');
    icon && icon.setAttribute('class', className);
    timer = setTimeout(() => {
      div && document.body.removeChild(div);
    }, (duration || 4) * 1000);
    return;
  }
  const div = document.createElement('div');
  div.setAttribute('id', 'global-message');
  div.setAttribute('class', 'app-fixed-message-box' + ' ' + preCls);
  const i = document.createElement('i');
  i.setAttribute('id', 'message-icon');
  i.setAttribute('class', className);
  const text = document.createElement('span');
  text.setAttribute('id', 'global-message-content');
  text.innerText = content;
  div.appendChild(i);
  div.appendChild(text);
  document.body.appendChild(div);
  timer = setTimeout(() => {
    div && document.body.removeChild(div);
  }, (duration || 4) * 1000);
}

export const showSuccess = (content: string, duration?: number) => {
  create(content, 'success', 'iconfont icon-success', duration);
}

showSuccess.save = () => {
  showSuccess('保存成功');
}

showSuccess.delete = () => {
  showSuccess('删除成功');
}

export const showFail = (content: string, duration?: number) => {
  create(content, 'warn', 'iconfont icon-warn', duration);
}

export const showError = (content: string, duration?: number) => {
  create(content, 'error', 'iconfont icon-error', duration);
}

showError.save = () => {
  showSuccess('保存出错');
}

showError.delete = () => {
  showSuccess('删除出错');
}

export const showLoading = (duration?: number) => {
  globalLoadingStore.showLoading(duration);
}

export const hideLoading = (duration?: number) => {
  globalLoadingStore.hideLoading();
}