import React from 'react';
import { Layout, Spin, Dropdown, Menu} from 'antd';
import socket from '@/modules/socket2';

import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  LoginOutlined,
} from '@ant-design/icons';
const styles = require('@/styles/app.less');
import AppSideBar from '@/components/app-sider/AppSider';
import AppRoute from '@/components/app-route/AppRoute';
import AppMessage from '@/components/app-message/AppMessage';

import agentRoutes, { setUserAgentRoutes } from './router/agent_router';
import ychRoutes from './router/ych_router';
import agentMenu, {setUserAgentMenu} from './menu/menu_agent';
import ychMenu, {setUserYchMenu} from './menu/menu_ych';
import { observer } from 'mobx-react';
import globalLoadingStore from './global_store/globalLoadingStore';
import globalUserStore from './global_store/globalUserStore';
import { getToken, removeToken } from './storage/storage';

const { Header, Content } = Layout;

interface AppProps extends PagePropsType {
  user: any
}

@observer
export default class App extends React.PureComponent<AppProps> {
  private userRoutes: any[] = [];
  private userMenu: any[] = [];

  constructor(props) {
    super(props);
  }

  state = {
    collapsed: false,
  };

  componentDidMount() {
    const {isLogin} = globalUserStore;
    if (!isLogin) {
      const token = getToken();
      if (token) {
        // 自动登录
        globalUserStore.autoLogin();
        return;
      }
      this.props.history.replace('/login');
    }
  }

  componentDidCatch(error) {
    if (!__DEV__) {
      this.props.history.replace('/500');
    }
    console.error(error);
  }


  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  render() {
    const { history } = this.props;
    const { isAdministrator, functionList, from, agentId, agentName, fullName } = globalUserStore;

    if (!globalUserStore.isLogin) {
      return <div style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <Spin size="large" tip="正在加载..."/>
      </div>
    }

    if (from === 'YCH') {
      this.userRoutes = ychRoutes;
      this.userMenu = ychMenu;
    } else {
      // socket(agentId);
      // 如果不是管理员，则过滤权限
      if (!isAdministrator) {
        this.userRoutes = setUserAgentRoutes(functionList);
        this.userMenu = setUserAgentMenu(functionList);
      } else {
        this.userRoutes = agentRoutes;
        this.userMenu = agentMenu;
      }
    }
    
    return <Spin size="large"delay={0} spinning={globalLoadingStore.isLoading} tip="网络有点慢，请稍后" wrapperClassName="root-spain">
      <Layout style={{
        height: '100%'
      }}>
        <AppSideBar menu={this.userMenu} history={history} collapsed={this.state.collapsed}/>
        <Layout style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}>
          <Header style={{
            padding: 0,
            height: 50,
            marginLeft: 10,
            alignItems: 'center',
            backgroundColor: 'initial',
            boxShadow: 'none'
          }}>
            <div style={{flex: 1, display: 'flex', alignItems: 'center'}}>
              <div onClick={this.toggle} style={{
                fontSize: 20,
                marginLeft: 15,
                cursor: 'pointer',
                marginRight: 20
              }}>
                {
                  this.state.collapsed ? <MenuUnfoldOutlined/> : <MenuFoldOutlined/>
                }
              </div>
              <div style={{fontSize: 18}}>{agentName}</div>
            </div>
            <div style={{
              height: '100%',
              flex: 1,
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              paddingRight: 20,
              lineHeight: 'initial'
            }}>
              {
                from === 'AGENT' &&
                <AppMessage/>
                
              }
              <div style={{padding: 5, marginRight: 20, paddingRight: 20, borderRight: '1px solid #bbb'}}>
                最近更新：{__BUILD_TIME__}
              </div>
              <div style={{paddingLeft: 15, height: '100%', lineHeight: '50px'}}>
                <Dropdown overlay={
                  <Menu onClick={() => {}}>
                    <Menu.Item key="1" onClick={() => this.props.history.push('/userInfo')}>
                      <UserOutlined />
                      个人中心
                      </Menu.Item>
                    <Menu.Item key="2" onClick={() => {
                      removeToken();
                      sessionStorage.removeItem('$$_router_list');
                      sessionStorage.removeItem('$$_router_active');
                      window.location.replace('/login');
                    }}>
                      <LoginOutlined />
                      退出登录
                    </Menu.Item>
                  </Menu>
                }>
                  <a>
                    <div style={{height: 50, display: 'flex', alignItems: 'center'}} className="hover-menu">
                      <i className="iconfont icon-jiqiren2" style={{fontSize: 22, marginRight: 5}}></i>
                      {fullName}
                      <i className="iconfont icon-arrow-down"/>
                    </div>
                  </a>
                </Dropdown>
              </div>
            </div>
          </Header>
          <Content style={{
            flex: 1,
            padding: 0,
            margin: 0,
            position: 'relative',
          }}>
            <AppRoute routes={this.userRoutes} history={history}/>
          </Content>
          {/* <div style={{textAlign: 'center', paddingBottom: 10}}>Copyright ©2019 成都易采惠科技有限公司&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;最近更新：{__BUILD_TIME__}</div> */}
        </Layout>
      </Layout>
    </Spin>
  }
}