import React from "react";
import { Button, Divider } from 'antd';

export default (props: {
  type?: 'create' | 'find';
  onClick?(): void;
  children?: React.ReactNode;
}) => {
  let icon: any = null;
  let text: any = '';
  if (props.type === 'create') {
    icon = <i className="iconfont icon-xinzeng"/>;
    text = props.children || '新增';
  }
  if (props.type === 'find') {
    icon = <i className="iconfont icon-chazhao1"/>;
    text = props.children || '查找更多';
  }
  return <div style={{textAlign: 'center'}}>
    <Divider style={{marginBottom: 3, marginTop: 0}}/>
    <Button className="select-button" type="default" icon={icon} onClick={props.onClick}>{text}</Button>
  </div>
}