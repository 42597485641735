import React from "react";
import { Button as AntdButton } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import WebAuth from "@/web/WebAuth";

interface IButtonProps extends ButtonProps {
  authCode?: number;
}

const Button = (props: IButtonProps) => {
  if (props.authCode && !WebAuth.hasAuth(props.authCode)) return null;
  const {authCode, ...resetProps} = props;
  return <AntdButton {...resetProps}/>
}

export default Button;

const Edit = (props: IButtonProps) => {
  const {children, ...resetProps} = props;
  return <Button type={props.type || 'default'} icon={
    props.type === 'link' ? <i className="iconfont icon-bianji"/> : <i className="iconfont icon-bianji1"/>
  } {...resetProps}>{children || '修改'}</Button>
}

const Create = (props: IButtonProps) => {
  const {children, ...resetProps} = props;
  return <Button type="default" icon={<i className="iconfont icon-xinzeng"/>} {...resetProps}>{children || '新增'}</Button>
}

const Delete = (props: IButtonProps) => {
  const {children, ...resetProps} = props;
  return <Button type={props.type || 'default'} icon={
    props.type === 'link' ? <i className="iconfont icon-delete"></i> : <i className="iconfont icon-zu"></i>
  } {...resetProps}>{children || '删除'}</Button>
}

const Setting = (props: IButtonProps) => {
  return <Button type="default" icon={<i className="iconfont icon-shezhi1"></i>} {...props}>设置</Button>
}

const Refresh = ({onClick = () => {}}) => {
  return <Button type="default" onClick={onClick} icon={<i className="iconfont icon-shuaxin"/>}>刷新</Button>
};

const Print = ({onClick = () => {}}) => {
  return <Button type="default" onClick={onClick} icon={<i className="iconfont icon-icon-printing"/>}>打印</Button>
}

Button.Edit = Edit;
Button.Create = Create;
Button.Delete = Delete;
Button.Setting = Setting;
Button.Refresh = Refresh;
Button.Print = Print;