import { Modal } from "antd";

export const findCatch = (moduleName: string | Error, e?: Error) => {
  if (typeof moduleName === 'string') {
    showError(`查询${moduleName}出错: ${e ? e.message : ''}`);
  } else {
    showError(`查询出错: ${moduleName.message}`);
  }
  return Promise.reject(e);
}

export const createCatch = (moduleName = '', e: any) => {
  if (e.code === '1000') {
    showError(`新增${moduleName}出错: ${e.message}`);
  } else {
    Modal.error({
      title: '出错了',
      content: e.msg || e.message
    });
  }
  return Promise.reject(e);
}

export const updateCatch = (moduleName = '', e: Error) => {
  showError(`保存${moduleName}出错: ${e.message}`);
  return Promise.reject(e);
}

export const deleteCatch = (moduleName: string | Error, e?: Error) => {
  if (typeof moduleName === 'string') {
    showError(`删除${moduleName}出错: ${e ? e.message : ''}`);
  } else {
    showError(`删除出错: ${moduleName.message}`);
  }
  
  return Promise.reject(e);
}

export default (actionName = '', e: Error) => {
  showError(`${actionName}出错: ${e.message}`);
  return Promise.reject(e);
}