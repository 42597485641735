import React, { PureComponent } from 'react';
import config from '@/config';

export default class Home extends PureComponent<RouterPropsType> {
  constructor(props) {
    super(props);
  }
  clickItem = () => {
    this.props.history.push('/app');
  };

  clickAdd = () => {
    this.props.history.push('/add');
  };

  render() {
    return (
      <div style={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 30,
        background: '#fff'
      }}>
        欢迎登录{config.displayName}
      </div>
    )
  }
}
