import { LibIAgentInfo } from "../interface/AgentInterface";
import LibApi from "./Api";
import LibYchUrl from "./YchUrl";

interface LibIPagingParam {
  pageNum?: number;
  pageSize?: number;
  keyword?: string;
}

export default class LibYchApi<IConfig> extends LibApi<IConfig> {
  // 订货单分页
  async sale_order_paging(
    data: {
      keyword?: string;
      orderStatus?: string;
      withDetail?: boolean;
      paymentStatus?: string;
      agentId?: string;
    } & LibIPagingParam,
    config?: IConfig
  ) {
    return this.request(LibYchUrl.SALE_ORDER_PAGING_URL, {
      ...(data || {}),
    },
    config);
  }

  // 商品
  async product_paging(data) {
    return this.request(LibYchUrl.PRODUCT_PAGING_URL, data);
  }

  // 客户
  async customer_paging(data: {
    agentId?: string;
  } & LibIPagingParam) {
    return this.request(LibYchUrl.CUSTOMER_PAGING_URL, data);
  }

  // 所有代理商
  async agent_list() {
    return this.request(LibYchUrl.AGENT_LIST_URL, {});
  }

  // 所有公司
  async company_list() {
    return this.request(LibYchUrl.COMPANY_LIST_URL, {});
  }

  // 新增代理商
  async agent_create(data: Omit<LibIAgentInfo, 'agentId'>) {
    return this.request(LibYchUrl.AGENT_CREATE_URL, data);
  }
  // 修改代理商
  async agent_update(data: Partial<LibIAgentInfo>) {
    return this.request(LibYchUrl.AGENT_UPDATE_URL, data);
  }
  // 删除代理商
  async agent_delete(data: {agentId: string}) {
    return this.request(LibYchUrl.AGENT_DELETE_URL, data);
  }

  // 部门
  async department_list() {
    return this.request(LibYchUrl.DEPARTMENT_LIST_URL, {});
  }

  // 员工
  async employee_list() {
    return this.request(LibYchUrl.EMPLOYEE_LIST_URL, {});
  }
  
  // 系统日志查询
  async action_log_paging(data: {
    targetName?: string;
    eventType?: string;
    agentId?: string;
  } & LibIPagingParam, config?: IConfig) {
    return this.request(LibYchUrl.ACTION_LOG_PAGING_URL, data, config);
  }
}