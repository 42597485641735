import { replace } from './util';
import common, { notFountRouter } from './common';
import { LibAuthCode } from 'lib/Auth';
import AgentHome from '@/page/home/AgentHome';

// 订单
// sale     销售
// purchase 采购

export const routerMap =  {
  ...common,
  home: {
    name: '主页',
    path: '/',
    exact: true,
    component: AgentHome,
  },
  // 消息
  'message.view': {
    name: '我的消息',
    path: '/message/:id',
    code: LibAuthCode.message.view,
    getPath(id) {
      return replace(this.path, {
        'id': id
      });
    },
    asyncComponent: () => import('../page/message/Message'),
  },
  // 订货路由
  sale_order_list: {
    code: LibAuthCode.saleOrder.view,
    name: '订货单',
    path: '/sale_order_list',
    getPath(date) {
      return replace(this.path, { date });
    },
    asyncComponent: () => import('../page/sale_order/sale_order_list/SaleOrderPage'),
  },
  sale_order_list_status: {
    code: LibAuthCode.saleOrder.view,
    name: '订货单',
    path: '/sale_order_list/:status',
    getPath(status) {
      return replace(this.path, { status });
    },
    asyncComponent: () => import('../page/sale_order/sale_order_list/SaleOrderPage'),
  },
  sale_order_detail: {
    code: LibAuthCode.saleOrder.view,
    name: '订货单详情',
    path: '/sale_order_detail/:id/:key',
    getPath(id, key) {
      return replace(this.path, { id, key });
    },
    asyncComponent: () => import('../page/sale_order/sale_order_detail/SaleOrderDetailPage')
  },
  sale_order_create: {
    code: LibAuthCode.saleOrder.create,
    name: '新增订货单',
    path: '/sale_order_create',
    asyncComponent: () => import('../page/sale_order/sale_order_create/SaleOrderCreatePage')
  },
  'sale.update': {
    code: LibAuthCode.saleOrder.update,
    name: '修改订货单',
    path: '/sale_order_update/:id',
    getPath(id) {
      return replace(this.path, {'id': id});
    },
    asyncComponent: () => import('../page/sale_order/sale_product_update/SaleProductUpdatePage')
  },
  sale_return_order_list: {
    code: LibAuthCode.saleReturnOrder.view,
    name: '订货退货单',
    path: '/sale_return_order_list',
    asyncComponent: () => import('../page/sale_return_order/list/SaleReturnPage'),
  },
  saleReturnOrderDetail: {
    code: LibAuthCode.saleReturnOrder.view,
    name: '订货退货单详情',
    path: '/sale_return_detail/:id',
    getPath(id) {
      return replace( this.path, { id });
    },
    asyncComponent: () => import('../page/sale_return_order/detail/SaleReturnDetail'),
  },
  'saleReturn.create': {
    code: LibAuthCode.saleReturnOrder.create,
    name: '新增订货退货单',
    path: '/sale_return_create',
    asyncComponent: () => import('../page/sale_return_order/create/SaleReturnCreate'),
  },
  // 采购路由
  purchase_order_list: {
    code: LibAuthCode.purchaseOrder.view,
    name: '进货单',
    path: '/purchase_order_list',
    asyncComponent: () => import('../page/purchase_order/list/PurchaseOrderPage'),
  },
  purchaseOrderDetail: {
    code: LibAuthCode.purchaseOrder.view,
    name: '进货单详情',
    path: '/purchase_order_detail/:id',
    getPath(id) {
      return replace( this.path, {
        'id': id
      });
    },
    asyncComponent: () => import('../page/purchase_order/detail/PurchaseDetailPage'),
  },
  purchase_order_create: {
    code: LibAuthCode.purchaseOrder.create,
    name: '新增进货单',
    path: '/purchase_order_create',
    asyncComponent: () => import('../page/purchase_order/create/PurchaseCreatePage'),
  },
  'purchaseReturn.create': {
    code: LibAuthCode.purchaseReturnOrder.create,
    name: '新增进货退货单',
    path: '/purchase_return_order_create',
    asyncComponent: () => import('../page/purchase_return_order/create/PurchaseReturnCreatePage'),
  },
  purchase_return_order_list: {
    code:LibAuthCode.purchaseReturnOrder.view,
    name: '进货退货单',
    path: '/purchase_return_order',
    asyncComponent: () => import('../page/purchase_return_order/list/PurchaseReturnOrderPage'),
  },
  'purchaseReturn.view.detail': {
    code: LibAuthCode.purchaseReturnOrder.view,
    name: '进货退货单详情',
    path: '/purchase_return_order_detail/:id',
    getPath(id) {
      return replace( this.path, {id});
    },
    asyncComponent: () => import('../page/purchase_return_order/detail/PurchaseReturnDetailPage'),
  },
  // 供应商
  'supplier.view': {
    code: LibAuthCode.supplier.view,
    name: '供应商',
    path: '/supplier_list',
    asyncComponent: () => import('../page/supplier/SupplierPage'),
  },
  product_category: {
    code: LibAuthCode.category.view,
    name:  '商品分类',
    path: '/product_category',
    asyncComponent: () => import('../page/product_category/ProductCategoryPage'),
  },
  product_list: {
    code: LibAuthCode.product.view,
    name:  '商品列表',
    path: '/product_list',
    asyncComponent: () => import('../page/product/product_list/ProductListPage'),
  },
  product_detail: {
    code: LibAuthCode.product.view,
    name:  '商品详情',
    path: '/product_detail/:id',
    getPath(id) {
      return replace( this.path, {id})
    },
    asyncComponent: () => import('../page/product/product_detail/ProductDetailPage'),
  },
  productCreate: {
    code: LibAuthCode.product.create,
    name:  '新增商品',
    path: '/product_create',
    asyncComponent: () => import('../page/product/product_create/ProductCreatePage'),
  },
  productUpdate: {
    code: LibAuthCode.product.update,
    name:  '修改商品',
    path: '/product_update/:id',
    getPath(id) {
      return replace( this.path, {id})
    },
    asyncComponent: () => import('../page/product/product_update/ProductUpdatePage'),
  },
  // 'product.create.batch':{
  //   code: authCode.product.data.create,
  //   name:  '批量导入',
  //   path: '/product/list/batch',
  //   asyncComponent: () => import('../page/product/batchAdd'),
  // },
  // 营销
  'advert.view': {
    code: LibAuthCode.advert.view,
    name: '首页广告',
    path: '/advert_home',
    asyncComponent: () => import('../page/advert/AdvertPage'),
  },
  'coupon.full-minus.view': {
    code: LibAuthCode.coupon.view,
    name: '满减券',
    path: '/coupon_minus_full',
    asyncComponent: () => import('../page/coupon/full-minus/CouponFullMinusPage'),
  },
  'coupon.detail': {
    code: LibAuthCode.coupon.view,
    name: '优惠券详情',
    path: '/coupon_detail/:id',
    getPath(id) {
      return replace( this.path, {
        'id': id
      });
    },
    asyncComponent: () => import('../page/coupon/CouponDetail'),
  },
  'coupon.full-minus.create': {
    code: LibAuthCode.coupon.view,
    name: '新增满减券',
    path: '/coupon_full_minus_create',
    asyncComponent: () => import('../page/coupon/full-minus/CouponFullMinusCreatePage'),
  },
  'activity.discount.view': {
    code: LibAuthCode.activity.view,
    name: '单品打折',
    path: '/activity_discount',
    asyncComponent: () => import('../page/activity/discount/ActivityDiscount'),
  },
  'activity.discount.detail': {
    code: LibAuthCode.activity.view,
    name: '查看单品打折',
    path: '/activity_discount_detail/:id',
    getPath(id) {
      return replace( this.path, {
        'id': id
      });
    },
    asyncComponent: () => import('../page/activity/discount/ActivityDiscountDetail'),
  },
  'activity.discount.create': {
    code: LibAuthCode.activity.create,
    name: '新增单品打折',
    path: '/activity_discount_create',
    asyncComponent: () => import('../page/activity/discount/ActivityDiscountCreate'),
  },
  'activity.special.view': {
    code: LibAuthCode.activity.view,
    name: '单品特价',
    path: '/activity_special',
    asyncComponent: () => import('../page/activity/special/ActivitySpecial'),
  },
  'activity.special.detail': {
    code: LibAuthCode.activity.view,
    name: '查看单品特价',
    path: '/activity_special_detail/:id',
    getPath(id) {
      return replace( this.path, {
        'id': id
      });
    },
    asyncComponent: () => import('../page/activity/special/ActivitySpecialDetail'),
  },
  'activity.special.create': {
    code: LibAuthCode.activity.create,
    name: '新增单品特价',
    path: '/activity_special_create',
    asyncComponent: () => import('../page/activity/special/ActivitySpecialCreate'),
  },
  'activity.gift.view': {
    code: LibAuthCode.activity.view,
    name: '单品满赠',
    path: '/activity_gift',
    asyncComponent: () => import('../page/activity/gift/ActivityGift'),
  },
  'activity.gift.detail': {
    code: LibAuthCode.activity.view,
    name: '查看单品满赠',
    path: '/activity_gift_detail/:id',
    getPath(id) {
      return replace( this.path, {
        'id': id
      });
    },
    asyncComponent: () => import('../page/activity/gift/ActivityGIftDetail'),
  },
  'activity.gift.create': {
    code: LibAuthCode.activity.create,
    name: '新增单品满赠',
    path: '/activity_gift_create',
    asyncComponent: () => import('../page/activity/gift/ActivityGiftCreate'),
  },
  // 库存  
  'repository.view': {
    code: LibAuthCode.repository.view,
    name: '仓库信息',
    path: '/repository',
    asyncComponent: () => import('../page/system/repository/RepositoryPage'),
  },
  stockSummary: {
    code: LibAuthCode.stock.view,
    name: '库存汇总',
    path: '/stock_summary',
    asyncComponent: () => import('../page/stock/StockSummaryPage'),
  },
  stockDetail: {
    code: LibAuthCode.stock.view,
    name: '库存分布',
    path: '/stock_detail',
    asyncComponent: () => import('../page/stock/StockDetailPage'),
  },
  stockRecord: {
    code: LibAuthCode.stock.view,
    name: '出入库流水',
    path: '/stock_record',
    asyncComponent: () => import('../page/stock/StockRecordPage'),
  },

  // 'stock.create': {
  //   code:  authCode.stock.data.create,
  //   name: '商品入库',
  //   path: '/stock/batchAdd',
  //   asyncComponent: () => import('../views/stock/batchAdd'),
  // },
  // 财务
  bookSale: {
    code:  LibAuthCode.book.view,
    name: '订货收款',
    path: '/book_sale',
    type: 'navigator',
    asyncComponent: () => import('../page/finance/sale_book/SalePage'),
  },
  bookSaleReturn: {
    code:  LibAuthCode.book.view,
    name: '订货退货付款',
    path: '/book_sale_return',
    type: 'navigator',
    asyncComponent: () => import('../page/finance/sale_return_book/SaleReturnBookPage'),
  },
  bookPurchase: {
    code:  LibAuthCode.book.view,
    name: '进货付款',
    path: '/book_purchase',
    type: 'navigator',
    asyncComponent: () => import('../page/finance/purchase_book/PurchaseBookPage'),
  },
  bookPurchaseReturn: {
    code:  LibAuthCode.book.view,
    name: '进货退货收款',
    path: '/book_purchase_return',
    type: 'navigator',
    asyncComponent: () => import('../page/finance/purchase_return_book/PurchaseReturnBookPage'),
  },
  'fee.view': {
    code: LibAuthCode.book.view,
    name: '费用支出单',
    path: '/book_fee',
    asyncComponent: () => import('../page/finance/fee/FeePage'),
  },
  'book.view': {
    code: LibAuthCode.book,
    name: '总账',
    path: '/book_total',
    asyncComponent: () => import('../page/finance/book/BookPage'),
  },
  reportSaleOrder: {
    code: LibAuthCode.report.view,
    name: '订货单(利润)统计表',
    path: '/report_sale_report',
    asyncComponent: () => import('../page/report/sale_report/SaleOrderReportPage'),
  },
  reportSaleOrderDetail: {
    code: LibAuthCode.report.view,
    name: '订货单明细表',
    path: '/report_sale_detail_report/:date',
    getPath(date) {
      return replace( this.path, {
        date,
      });
    },
    asyncComponent: () => import('../page/report/sale_report/SaleOrderDetailReportPage'),
  },
  reportCustomerSale: {
    code: LibAuthCode.report.view,
    name: '客户订货(利润)统计表',
    path: '/report_customer_sale_report',
    asyncComponent: () => import('../page/report/sale_report/CustomerSaleReportPage'),
  },
  reportSaleOrderCustomerDetail: {
    code: LibAuthCode.report.view,
    name: '客户订货明细表',
    path: '/report_sale_detail_report/:customerId/:customerName/:date',
    getPath(customerName, customerId, date) {
      return replace( this.path, {
        customerName,
        customerId,
        date,
      });
    },
    asyncComponent: () => import('../page/report/sale_report/SaleOrderDetailReportPage'),
  },
  reportProductSaleProfit: {
    code: LibAuthCode.report.view,
    name: '商品订货(利润)统计表',
    path: '/report_product_purchase_sale_report',
    asyncComponent: () => import('../page/report/sale_report/ProductSaleReportPage'),
  },
  reportPurchaseOrder: {
    code: LibAuthCode.report.view,
    name: '进货单统计表',
    path: '/report_purchase_report',
    asyncComponent: () => import('../page/report/purchase_report/PurchaseOrderReportPage'),
  },
  reportPurchaseOrderDetail: {
    code: LibAuthCode.report.view,
    name: '进货单明细表',
    path: '/report_purchase_detail_report/:date',
    getPath(date) {
      return replace( this.path, {
        date
      });
    },
    asyncComponent: () => import('../page/report/purchase_report/PurchaseOrderDetailReportPage'),
  },
  reportPurchaseOrderSupplierDetail: {
    code: LibAuthCode.report.view,
    name: '进货单明细表',
    path: '/report_purchase_detail_report/:supplierName/:supplierId/:date',
    getPath(supplierName, supplierId, date) {
      return replace( this.path, {
        supplierName,
        supplierId,
        date
      });
    },
    asyncComponent: () => import('../page/report/purchase_report/PurchaseOrderDetailReportPage'),
  },
  reportSupplierPurchase: {
    code: LibAuthCode.report.view,
    name: '供应商进货统计表',
    path: '/report_supplier_purchase_report',
    asyncComponent: () => import('../page/report/purchase_report/SupplierPurchaseReportPage'),
  },
  reportProductPurchase: {
    code: LibAuthCode.report.view,
    name: '商品进货统计表',
    path: '/report_product_purchase_report',
    asyncComponent: () => import('../page/report/purchase_report/ProductPurchaseReportPage'),
  },
  // 经营报表
  reportManage: {
    code: LibAuthCode.report.view,
    name: '经营统计表',
    path: '/report_manage_report',
    asyncComponent: () => import('../page/report/manage_report/ManageReportPage'),
  },
  reportManageFee: {
    code: LibAuthCode.report.view,
    name: '费用支出统计表',
    path: '/report_manage_fee_report',
    asyncComponent: () => import('../page/report/manage_report/ManageFeeReportPage'),
  },
  reportEmployeeSaleRanking: {
    code: LibAuthCode.report.view,
    name: '员工销售排行',
    path: '/report_manage_employee_sale_ranking_report',
    asyncComponent: () => import('../page/report/manage_report/EmployeeSaleRankingPage'),
  },
  reportManageDetail: {
    code: LibAuthCode.report.view,
    name: '经营明细表',
    getPath(type, date) {
      return replace( this.path, {
        type,
        date
      });
    },
    path: '/report_manage_detail_report/:type/:date',
    asyncComponent: () => import('../page/report/manage_report/ManageDetailReportPage'),
  },
  // 报表
  // 客户
  customer_list: {
    code: LibAuthCode.customer.view,
    name: '客户',
    path: '/customer_list',
    asyncComponent: () => import('../page/customer/CustomerPage'),
  },
  'customer.update': {
    code: LibAuthCode.customer.update,
    name: '修改客户信息',
    path: '/customer_update/:id',
    getPath(id) {
      return replace(this.path, {id});
    },
    asyncComponent: () => import('../page/customer/CustomerUpdatePage'),
  },
  // 员工与部门
  'company.view': {
    code: [LibAuthCode.department.view, LibAuthCode.employee.view],
    name:  '部门与员工',
    path: '/employee',
    asyncComponent: () => import('../page/system/company/Company'),
  },
  'notice.view': {
    name: '通知公告',
    code: LibAuthCode.notice.view,
    path: '/notice',
    asyncComponent: () => import('../page/notice/NoticePage'),
  },
  action_log_list: {
    name: '系统日志',
    code: LibAuthCode.actionLog.view,
    path: '/system_log',
    asyncComponent: () => import('../page/system/log/ActionLogPage'),
  },
};

const routes = Object.keys(routerMap).map(key => routerMap[key]).concat([notFountRouter]);

export const setUserAgentRoutes = (functionList: string[]) => {
  return routes.filter(item => {
    if (Array.isArray(item.code)) {
      return item.code.some(i => functionList.includes(i));
    }
    return (item.code && functionList.includes(item.code)) || !item.code
  })
};

export default routes;