export default class LibYchUrl {

  static SALE_ORDER_PAGING_URL = '/ych/sale_order/sale_order_paging';

  static COMPANY_LIST_URL = '/ych/company_list';

  static AGENT_LIST_URL = '/ych/agent_list';

  static AGENT_DELETE_URL = '/ych/agent_delete';

  static AGENT_CREATE_URL = '/ych/agent_create';

  static AGENT_UPDATE_URL = '/ych/agent_update';

  static PRODUCT_PAGING_URL = '/ych/product_paging';

  static CUSTOMER_PAGING_URL = '/ych/customer_paging';

  static DEPARTMENT_LIST_URL = '/ych/department_list';

  static EMPLOYEE_LIST_URL = '/ych/employee_list';

  static ACTION_LOG_PAGING_URL = '/ych/action_log_paging';
}