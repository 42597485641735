import React from "react";
import {Spin} from 'antd';
import {CaretLeftOutlined, CaretRightOutlined, DeleteOutlined, EyeOutlined, LoadingOutlined, PlusOutlined} from '@ant-design/icons';
import { Dialog } from "@/components";
import WebApi from "@/web/api";
import classNames from "classnames";
const style = require('./upload_image.less');

interface IFile {
  imageId: string;
  imageUrl: string;
  loading?: boolean;
  editing?: boolean;
}

interface IState {
  loading: boolean;
  fileList: IFile[];
  previewVisible: boolean;
  previewImage: string;
  previewTitle: string;
}

interface IUploadImageProps {
  extendData: {
    type: 'logo' | 'slider' | 'detail' | 'category' | 'carousel' | 'icon_menu' | 'card';
    objectId?: string;
  };
  validateStatus?: 'error' | 'validating';
  fileList?: IFile | IFile[] | undefined;
  maxLength?: number;
  sortable?: boolean;
  previewAble?: boolean;
  deleteAble?: boolean;
  onChange?(fileList: {imageId: string}[]): void;
  onSortChange?(fileList: {imageId: string}[]): void;
}

export default class UploadImage extends React.PureComponent<IUploadImageProps, IState> {

  private input = React.createRef<HTMLInputElement>();

  private editingImageId?: string = undefined;

  constructor(props: IUploadImageProps) {
    super(props);

    const fileList = props.fileList ? (Array.isArray(props.fileList) ? props.fileList : [props.fileList]) : [];
    this.state = {
      loading: false,
      fileList,
      previewVisible: false,
      previewImage: '',
      previewTitle: ''
    };
  }

  public getImageIdList = (): string[] => {
    return this.state.fileList.map(i => i.imageId);
  }

  render() {
    const {previewAble = true, deleteAble = true, sortable} = this.props;
    const { previewVisible, previewImage, fileList, previewTitle } = this.state;

    return <div className="component-upload-image">
      {
        this.state.fileList.map((item, index) => {
          return <div className="component-upload-image-item" key={item.imageId}>
            <div className="component-upload-image-box">
              <img src={item.imageUrl} alt="加载失败"/>

              {
                item.loading && <div className="component-upload-image-loading">
                  <Spin indicator={<LoadingOutlined/>} tip="正在上传"/>
                </div>
              }

              {
                !item.loading &&
                <div className="component-upload-image-board">
                {
                    sortable && index > 0 &&
                    <CaretLeftOutlined className="component-upload-image-board-icon" onClick={() => {
                      this.clickLeft(index);
                    }}/>
                  }
                  {
                    sortable && index < this.state.fileList.length - 1 &&
                    <CaretRightOutlined className="component-upload-image-board-icon" onClick={() => {
                      this.clickRight(index);
                    }}/>
                  }
                  {
                    previewAble &&
                    <EyeOutlined className="component-upload-image-board-icon" onClick={() => {
                      this.onPreview(item);
                    }}/>
                  }
                  <i className="component-upload-image-board-icon iconfont icon-bianji" onClick={() => {
                    this.editingImageId = item.imageId;
                    item.editing = true;
                    this.input.current?.click();
                  }}/>
                  {
                    deleteAble &&
                    <DeleteOutlined className="component-upload-image-board-icon" onClick={() => {
                      this.onRemove(item);
                    }}/>
                  }
                </div>
              }
            </div>
          </div>
        })
      }
      {
        fileList.length < (this.props.maxLength || 10) &&
        <div className={classNames('component-upload-image-item upload-button', {
          'validate-error': this.props.validateStatus === 'error',
        })} onClick={this.clickCreateInput}>
          {this.renderUploadButton()}
        </div>
      }

      <input ref={this.input} hidden type="file" accept=".jpg,.jpeg,.png" onChange={this.onInputChange}/>

      <Dialog
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        full={false}
        width={455}
        onCancel={this.onCancelPreview}
      >
        <div style={{maxWidth: 375, height: 375}}>
          <img alt="图片加载失败" style={{ width: '100%' }} src={previewImage} />
        </div>
      </Dialog>
    </div>
  }

  private clickLeft = (index: number) => {
    const fileList = [...this.state.fileList];
    const current = fileList[index];
    const pre = fileList[index - 1];
    fileList[index - 1] = current;
    fileList[index] = pre;
    this.setState({
      fileList,
    }, () => {
      this.props.onSortChange && this.props.onSortChange(this.state.fileList);
    });
  }

  private clickRight = (index: number) => {
    const fileList = [...this.state.fileList];
    const current = fileList[index];
    const next = fileList[index + 1];
    fileList[index + 1] = current;
    fileList[index] = next;
    this.setState({
      fileList,
    }, () => {
      this.props.onSortChange && this.props.onSortChange(this.state.fileList);
    });
  }

  private renderUploadButton = () => {
    if (!this.state.loading) {
      return <div>
        <PlusOutlined style={{fontSize: 20, color: '#666'}}/>
        <div>上传</div>
      </div>
    }
    return <div>
      <Spin indicator={<LoadingOutlined/>}/>
      <div>正在上传</div>
    </div>
  }

  private onCancelPreview = () => this.setState({ previewVisible: false });

  private onPreview = async (file: IFile) => {
    this.setState({
      previewImage: file.imageUrl,
      previewVisible: true,
      previewTitle: file.imageUrl.substring(file.imageUrl.lastIndexOf('/') + 1),
    });
  };

  private clickCreateInput = () => {
    if (this.state.loading) return;
    this.editingImageId = undefined;
    this.input.current?.click();
  }

  private onInputChange = (e) => {
    const file = e.target.files?.[0];
    if (!file) return;
    const imageId = this.editingImageId;
    if (imageId) {
      const editingImage = this.state.fileList.find(i => i.imageId === imageId);
      if (editingImage) {
        editingImage.loading = true;
        this.setState({
          fileList: [...this.state.fileList],
        });
      } else {
        this.setState({
          loading: true,
        });
      }
    } else {
      this.setState({
        loading: true,
      });
    }
    // 开始上传
    const data = new FormData();
    data.append('type', this.props.extendData?.type);
    if (this.props.extendData?.objectId) {
      data.append('objectId', this.props.extendData?.objectId);
    }
    if (this.editingImageId) {
      data.append('imageId', this.editingImageId);
    }
    data.append('image', file);

    WebApi.upload_image(data)
    .then((data) => {
      showSuccess('图片上传成功');
      this.setState(state => {
        let fileList = [...state.fileList];
        if (imageId) {
          for (let item of fileList) {
            if (item.imageId === imageId) {
              item.imageUrl = data.imageUrl;
              item.loading = false;
              item.editing = false;
            }
          }
        } else {
          fileList.push({
            imageId: data.imageId,
            imageUrl: data.imageUrl,
            editing: false,
            loading: false,
          });
        }
        return {
          fileList,
          loading: false,
        };
      }, () => {
        this.props.onChange && this.props.onChange(this.state.fileList);
      });
    })
    .catch(() => {
      this.setState({
        loading: false,
      });
    });
  };

  private onRemove = (file: IFile) => {
    // 调用删除接口
    Dialog.confirm({
      content: '确认删除吗，删除后不可恢复',
      onOk: async () => {
        try {
          if (file['imageId']) {
            await WebApi.image_delete({
              imageId: file['imageId'],
            })
          }
          this.setState(state => {
            return {
              fileList: state.fileList.filter(i => i.imageId !== file['imageId']),
            };
          });
          return true;
        } catch (e) {
          console.error(e);
          return false;
        }
      }
    });
  }
}