import WebAuth from "@/web/WebAuth";
import React from "react"
import { Link } from "react-router-dom";
const style = require('./link.less');

interface ILinkProps {
  to: string;
  children?: React.ReactNode;
  authCode?: number | string;
}

export default (props: ILinkProps) => {
  if (props.authCode && !WebAuth.hasAuth(props.authCode)) {
    return <span>{props.children}</span>;
  }
  return <Link to={props.to} className="component-link">{props.children}</Link>
}