import React from 'react';
import { message, Select } from 'antd';
import { Dialog, ViewTable } from '@/components';
import WebApi from '@/web/api';

interface IInStockDataSource {
  id: string;
  productId: string;
  productName: string;
  skuId: string;
  skuName: string;
  supplierId: string;
  supplierName: string;
  purchasePrice: string;
  count: number;
  repositoryId?: string;
  repositoryName?: string;
}

interface IInStockDialogProps {
  visible: boolean;
  dataSource: IInStockDataSource[];
  onCancel(): void;
  onOk(data: IInStockDataSource[]): Promise<any>;
}

interface IState {
  loading: boolean;
  repositoryList: {repositoryName: string; repositoryId: string; isDefault: string}[];
  dataSource: IInStockDataSource[];
}
export default class InStockDialog extends React.PureComponent<IInStockDialogProps, IState> {

  constructor(props: IInStockDialogProps) {
    super(props);

    this.state = {
      repositoryList: [],
      loading: true,
      dataSource: props.dataSource,
    }
  }

  componentDidMount() {
    this.queryRepoList();
  }

  queryRepoList = async () => {
    WebApi.repository_list()
    .then((repositoryList: any) => {
      const dataSource = this.props.dataSource.map(item => {
        if (item.repositoryId && item.repositoryName) {
          return item;
        }
        return {
          ...item,
          repositoryId: repositoryList[0]?.repositoryId,
          repositoryName: repositoryList[0]?.repositoryName,
        };
      });
      this.setState({
        repositoryList,
        loading: false,
        dataSource,
      });
    });
  }

  render() {

    return <Dialog title="填写入库信息"
      visible={this.props.visible}
      block
      onCancel={this.props.onCancel}
      onOk={this.clickOk}
      okButtonProps={{
        disabled: this.state.loading,
        loading: this.state.loading,
      }}
    >
      <ViewTable
        pagination={false}
        dataSource={this.state.dataSource}
        columns={[
          {
            title: '商品',
            dataIndex: 'productName',
            width: 200,
          },
          {
            title: '规格',
            dataIndex: 'skuName',
            width: 300,
          },
          {
            title: '进价',
            dataIndex: 'purchasePrice',
            width: 150,
            align: 'center',
          },
          {
            title: '入库量',
            dataIndex: 'count',
            width: 140,
            align: 'center',
          },
          {
            title: '选择仓库',
            titleAlign: 'center',
            width: 200,
            render: (v, row) => {
              return <Select style={{width: '100%'}}
                value={row.repositoryId}
                loading={this.state.loading}
                onChange={(v, option: any) => {
                row.repositoryId = v;
                row.repositoryName = option.children;
                this.setState({
                  dataSource: [...this.state.dataSource],
                });
              }}>
                {
                  this.state.repositoryList.map((item: any, rIndex, repository) => {
                    return <Select.Option value={item.repositoryId} key={item.repositoryId}>{item.repositoryName}</Select.Option>
                  })
                }
              </Select>
            }
          },
        ]}
        rowKey={row => row.id}
      />
    </Dialog>
  }

  private clickOk = () => {
    for (const item of this.state.dataSource) {
      // 计算count
      if (!item.repositoryId) {
        message.error(`“${item.productName} ${item.skuName}” 仓库不能为空！`);
        return;
      }
    }
    this.setState({
      loading: true
    });
    this.props.onOk(this.state.dataSource).then(() => {
      showSuccess.save('入库成功');
      this.props.onCancel();
    }).catch(e => {
      this.setState({
        loading: false,
      });
    });
  }
}
