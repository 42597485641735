import { createCatch, deleteCatch, findCatch, updateCatch } from "../catching";
import request from "../request";

const TAG = '优惠券';

export default {
  'coupon.findAndCount': {
    url: '/agent/coupon/findAndCount',
    post: function(data) {
      return request(this.url, data).catch(e => findCatch(TAG, e));
    }
  },
  'coupon.findDetail': {
    url: '/agent/coupon/findDetail',
    post: function(data: {
      couponId: string
    }) {
      return request(this.url, data).catch(e => findCatch(TAG, e));
    }
  },
  'coupon.full.create': {
    url: '/agent/coupon/full/create',
    post: function(data) {
      return request(this.url, data).catch(e => createCatch(TAG, e));
    }
  },
  'coupon.update': {
    url: '/agent/coupon/update',
    post: function(data) {
      return request(this.url, data).catch(e => updateCatch(TAG, e));
    }
  },
  'coupon.delete': {
    url: '/agent/coupon/delete',
    post: function(data: {
      couponId: string
    }) {
      return request(this.url, data).catch(e => deleteCatch(TAG, e));
    }
  },
  'coupon.done': {
    url: '/agent/coupon/done',
    post: function(data: {
      couponId: string
    }) {
      return request(this.url, data).catch(e => deleteCatch(TAG, e));
    }
  },
  'coupon.get.findAndCount': {
    url: '/agent/coupon/get/findAndCount',
    post: function(data: {couponId: string}) {
      return request(this.url, data).catch(e => findCatch(TAG, e));
    }
  },
}