import React from 'react';
import { Tabs } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import dynamic from 'dva/dynamic';
const { pathToRegexp, match } = require('path-to-regexp');
const styles = require('./app-route.less');

const Page = React.memo((props: {
  routes: any[],
  history: any,
  path: string
}) => {
  const {path, routes, history} = props;
  const item: any = routes.find((r: any) => testPathname(r.path, path));
  const matched = match(item.path, {decode: decodeURIComponent});
  const result = matched(path);
  const RenderComponent = typeof item.asyncComponent === 'function' ? dynamic({
    // @ts-ignore
    app: window['app'],
    component: item.asyncComponent,
    models: {}
  }) : item.component;
  return <RenderComponent history={history} match={{
    params: result.params,
  }}/>
});

export default class AppRoute extends React.PureComponent<{
  routes: any[],
  history: any
}> {

  private history: any

  state: {
    tabs: string[]
    activeKey: string
  };
  constructor(props) {
    super(props);
    let cacheTabs = [];
    try {
      cacheTabs = JSON.parse(sessionStorage.getItem('$$_router_list') || '[]');
      // 不存在的路由，删掉
      cacheTabs = cacheTabs.filter((path: string) => {
        return props.routes.some(item => item.path.split('/')[0] === path.split('/')[0]);
      });
      sessionStorage.setItem('$$_router_list', JSON.stringify(cacheTabs));
    } catch(e) {}

    this.state = {
      tabs: Array.from(new Set(['/', ...cacheTabs])),
      activeKey: window.location.pathname || sessionStorage.getItem('$$_router_active') ||  '/',
    };
    this.history = props.history;
    this.history.close = () => {
      this.closeTabPane(this.state.activeKey)
    };
  }
  componentDidMount() {
    this.props.history.listen((location, action) => {
      // console.group('路由发生变化');
      // console.log(location, action);
      // console.groupEnd();
      let {pathname} = location;
      if (action === 'REPLACE') {
        // 干掉当前的路由
        const next = [...this.state.tabs];
        const activeKey = this.state.activeKey;
        const activeIndex = next.indexOf(activeKey);
        if (activeIndex > -1) {
          next.splice(activeIndex, 1);
          this.setState({
            tabs: Array.from(new Set([...next, pathname])),
            activeKey: pathname,
          });
        }
        return;
      }
      const existFlag = this.state.tabs.includes(pathname);
      if (!existFlag) {
        // 添加tab，并显示tab组件内容
        const index = this.props.routes.findIndex((item: any) => testPathname(item.path, pathname));
        if (index < 0) {
          this.props.history.replace('/404');
          return;
        }
        this.setState((state: any) => ({
          tabs: state.tabs.concat(pathname),
          activeKey: pathname,
        }));
      } else {
        // 显示已存在的tab组件内容
        this.setState(state => ({
          activeKey: pathname,
        }));
      }
    });
  }

  componentDidUpdate() {
    sessionStorage.setItem('$$_router_list', JSON.stringify(Array.from(new Set(this.state.tabs))));
    sessionStorage.setItem('$$_router_active', this.state.activeKey);
  }
  
  render() {
    const {routes, history} = this.props;
    const {tabs, activeKey} = this.state;
    return <Tabs
      className="page-tabs"
      activeKey={activeKey}
      type="editable-card"
      animated={false}
      onChange={key => {
        console.group('激活标签');
        console.log(key);
        console.groupEnd();
        history.push(key);
      }}
      onEdit={(targetKey: any, action: 'add' | 'remove') => {
        console.group('关闭标签');
        console.log(this.state.tabs);
        console.log(targetKey);
        console.groupEnd();
        if (action === 'remove') {
          this.closeTabPane(targetKey);
        }
      }}
      tabBarExtraContent={<div className="close-icon" onClick={this.closeAll}>
        <CloseOutlined/>
      </div>}
      hideAdd
    >
      {
        tabs.map(path => {
          const item: any = routes.find((r: any) => testPathname(r.path, path));
          if (!item) return null;
          return <Tabs.TabPane
            className="page-tab-pane"
            forceRender={true}
            tab={item.name}
            key={path}
            closable={path !== '/'}
            style={{
              // height: '100%',
              overflow: 'scroll'
            }}
          >
            <Page path={path} routes={routes} history={this.history}/>
          </Tabs.TabPane>
        })
      }
    </Tabs>
  }

  private closeTabPane = (targetKey: string) => {
    const next = [...this.state.tabs];
    const activeKey = this.state.activeKey;
    // 如果关闭的是当前激活的页面，直接replace
    if (targetKey === activeKey) {
      let endPath = next[next.length - 1];
      if (endPath === targetKey) {
        endPath = next[next.length - 2];
      }
      this.props.history.replace(endPath);
      return;
    }

    // 如果是非激活的页面直接干掉
    const index = next.indexOf(targetKey);
    if (index > -1) {
      next.splice(index, 1);
      this.setState({
        tabs: next,
      });
    }
  }

  private closeAll = () => {
    this.setState({
      tabs: ['/'],
      activeKey: '/'
    });
    this.props.history.push('/');
  }
}

const testPathname = (routePath, pathname) => {
  let keys = [];
  const reg = pathToRegexp(routePath, keys, {end: true});
  return reg.test(pathname);
}