import React from 'react';
import ViewTable from '../table/ViewTable';

interface Props {
  dataSource: any[];
  mode: 'payment' | 'received'
}

export default (props: Props) => {
  const TAG = props.mode === 'payment' ? '收' : '付';
  return <ViewTable
    flex1
    pagination={false}
    rowKey={row => row.id}
    bordered
    dataSource={props.dataSource}
    scroll={{y: '100%'}}
    // locale={{
    //   emptyText: '暂无记录'
    // }}
    columns={[
      {
        title: '交易编号',
        dataIndex: 'bookId',
        width: 200,
        align: 'center',
      },
      {
        title: TAG + '款单位',
        dataIndex: 'targetName',
        width: 150,
      },
      {
        title: TAG + '款金额',
        dataIndex: 'actualAmount',
        width: 150,
        align: 'right',
      },
      {
        title: TAG + '款时间',
        dataIndex: 'datetime',
        width: 200,
        align: 'center',
      },
      {
        title: '创建时间',
        dataIndex: 'createdAt',
        width: 200,
        align: 'center',
      },
      {
        title: '备注',
        dataIndex: 'remark',
        width: 'auto',
        render: value => value || '无',
      },
    ]}
  />
}