import React from "react";
import ViewTable from "../table/ViewTable";
import Layout from "../layout/Layout";
import Filter from "../filter/Filter";
import WebApi from "@/web/api";

export default class SupplierTable extends React.PureComponent<{
  onChange(selectedRowKeys: React.Key[], selectedRows: any[]): void
}> {
  private table = React.createRef<ViewTable>()
  private keyword = '';
  state = {
    visible: false
  }
  render() {
    return <Layout.TabPage>

      <Filter
        fields={[
          {
            type: 'input',
            field: 'keyword',
            name: '搜索',
            placeholder: '名称/手机号'
          }
        ]}
        onChange={(values: any) => {
          this.keyword = values.keyword;
          this.table.current?.reset({keyword: this.keyword});
        }}
      />

      <ViewTable
        flex1
        ref={this.table}
        rowSelection={{
          type: 'radio',
          onChange: this.props.onChange
        }}
        columns={[
          {
            title: '手机号',
            width: 160,
            align: 'center',
            dataIndex: 'supplierId',
          },
          {
            title: '供应商',
            titleAlign: 'left',
            width: 'auto',
            dataIndex: 'supplierName',
          },
        ]}
        fetch={data => {
          return WebApi.supplier_paging(data);
        }}
      />
    </Layout.TabPage>
  }
}