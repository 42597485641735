import React from 'react';
import Layout, {ILayoutProps} from '../layout/Layout';
import { Table, message, Tooltip } from 'antd';
import { TableProps, ColumnType, ColumnsType, ColumnGroupType } from 'antd/lib/table';
import { Api } from '@/api/api';
import { v4 } from 'uuid';
import classNames from 'classnames';
import WebAuth from '@/web/WebAuth';
import globalUserStore from '@/global_store/globalUserStore';
import { observer } from 'mobx-react';
const styles = require('./styles.less');

interface FetchOption {
  auto?: boolean
  api: Api
  data?: object
  pageSize?: number
}

type IActionButtonType = 'update' | 'delete' | 'detail' | 'invalid' | 'setting' | 'link' | 'done' | 'enable' | 'disable';

interface IActionButton {
  type: IActionButtonType | ((row: any) => IActionButtonType);
  tip?: string;
  iconClassName?: string;
  disabled?(row: any, index: number, dataSource: any[]): boolean;
  onClick(row: any, index: number): void;
  hidden?: boolean;
  authCode?: number;
}

export interface IViewTableColumn<T = {}> extends ColumnType<T> {
  titleAlign?: 'left' | 'center' | 'right'
}

export type IViewTableActionButton<T> = (IActionButton | ((row: T) => IActionButton))

export interface IViewableProps<T> extends Omit<TableProps<T>, 'columns'>, ILayoutProps{
  columns: IViewTableColumn<T>[];
  pageSizeOptions?: string[];
  fetchOption?: FetchOption;
  beforeRender?(dataSource: any[]): any[];
  actionButtons?: IViewTableActionButton<T>[];
  emptyCellRender?(): React.ReactNode;
  fetch?(data: {pageNum?: number; pageSize?: number}): Promise<any>;
  ych?: boolean;
  defaultFilter?(array: any[]): any[]
}

interface IState {
  loading: boolean;
  pageNum: number;
  pageSize: number;
  totalCount: number;
  dataSource: any[];
  filterSource: any[];
  sorterSource: any[];
  empty: boolean;
  status: 'normal' | 'filter' | 'sorter';
}

export const logoUrlCell = function(text): React.ReactNode {
  return <div style={{ width: 54, height: 54, margin: '-8px -4px', background: '#eee', overflow: 'hidden' }}>
    <img src={text} alt="" style={{ width: '100%' }} />
  </div>
}

const setActionButtonClassName = (name: string, disabled: boolean | Function) => {
  return classNames(name, {
    disabled: typeof disabled === 'function' ? disabled() : disabled
  });
}


@observer
export default class ViewTable<Data extends {} = any> extends React.PureComponent<IViewableProps<Data>, IState> {

  // static defaultProps = {
  //   pageSizeOptions: [ '30', '50', '70', '100'],
  //   fetchOption: {}
  // }

  private filterDataSource = this.props.defaultFilter || ((data) => data);

  state: IState = {
    loading: !!this.props.fetchOption?.api,
    pageNum: 1,
    pageSize: this.props.fetchOption?.pageSize || 50,
    totalCount: 0,
    dataSource: [],
    filterSource: [],
    sorterSource: [],
    empty: false,
    status: this.props.defaultFilter ? 'normal' : 'filter',
  }

  private reqData: object = {}

  private indexColumn: ColumnType<object> = {
    title: '序号',
    width: 65,
    align: 'center',
    fixed: 'left',
    render: (v, r, index) => index + 1 + (this.state.pageNum - 1) * this.state.pageSize,
  }

  constructor(props) {
    super(props);
    this.reqData = props.fetchOption?.data || {};
  }

  componentDidMount() {
    this.props.fetchOption?.auto !== false && this.fetch(1, this.state.pageSize, this.props.fetchOption?.data);
  }

  render() {
    const { columns = [], pagination, pageSizeOptions = [ '30', '50', '70', '100'], actionButtons, emptyCellRender } = this.props;
    const { loading, pageNum, pageSize, totalCount, dataSource, empty} =  this.state;

    const x = columns.reduce((c, n) => {
      return c + (n.width as number || 200);
    }, 0);

    columns.forEach((item : any) => {
      if (item.dataIndex === 'logoUrl' || item.dataIndex === 'logoImageUrl' || item.dataIndex == 'imgUrl') {
        item.width = 62;
        item.align = 'center',
        item.render = item.render || logoUrlCell
      }
      if (item.dataIndex === 'imageLogo') {
        item.width = 63;
        item.align = 'center',
        item.render = item.render || ((value) => {
          return logoUrlCell(value?.imageUrl);
        })
      }
      if (item.dataIndex === 'imageLogoUrl') {
        item.width = 63;
        item.align = 'center',
        item.render = item.render || ((value) => {
          return logoUrlCell(value);
        })
      }
      // if (item.render === undefined) {
      //   item.render = (v) => {
      //     return (v === 0 || v) ? v : (emptyCellRender ? emptyCellRender() : '--');
      //   }
      // }

      if (item.titleAlign) {
        item.title = <div style={{textAlign: item.titleAlign}}>{item.title}</div>
      }
    });

    const actionCols: any[] = [];
    const showActionButtons = actionButtons ? actionButtons.filter(item => {
      if (typeof item !== 'function') {
        if (!item.authCode) {
          return !item.hidden;
        } else {
          return !item.hidden && WebAuth.hasAuth(item.authCode);
        }
      }
      return true;
    }) : [];
    if (showActionButtons.length > 0) {

      const actionCol = {
        title: '操作',
        width: showActionButtons.length * 25 + 26,
        fixed: 'right',
        align: 'center',
        render: (value, row, index) => {
          
          return showActionButtons.map((item, index) => {
            if (!item) return item;
            if (typeof item === 'function') {
              item = item(row);
            }
            const disabled: boolean = item.disabled ? item.disabled(row, index, this.state.dataSource) : false;
            const onClick = disabled ? () => {} : item.onClick;

            const type = typeof item.type === 'function' ? item.type(row) : item.type;
            const data = {
              detail: {
                iconClassName: 'icon-chakan',
                tip: '查看详情',
              },
              update: {
                iconClassName: 'icon-bianji',
                tip: '修改',
              },
              delete: {
                iconClassName: 'icon-delete',
                tip: '删除',
              },
              setting: {
                iconClassName: 'icon-shezhi1',
                tip: '设置',
              },
              invalid: {
                iconClassName: 'icon-feiqi',
                tip: '作废',
              },
              done: {
                iconClassName: 'icon-success',
                tip: '完成',
              },
              enable: {
                iconClassName: 'icon-qiyong1',
                tip: '启用',
              },
              disable: {
                iconClassName: 'icon-jinyong1',
                tip: '禁用',
              },
            }[type]
            return this.renderActionButtonItem(() => onClick(row, index), disabled, item.iconClassName || data.iconClassName, item.tip || data.tip);
          });
        }
      }
      actionCols.push(actionCol);
    }

    let finalColumns: any[] = [];
    if (columns[0] && columns[0].dataIndex === 'indexColumn') {
      finalColumns = [...columns, ...actionCols];
    } else {
      finalColumns = [this.indexColumn, ...columns, ...actionCols];
    }

    // 兼容ych增加代理商
    if (this.props.ych && globalUserStore.isYch()) {
      finalColumns.splice(1, 0, {
        title: '代理商',
        dataIndex: 'agentName',
        width: 200,
      });
    }
  
    const {flex1, overHidden} = this.props;
    return <Layout className="component-view-table" flex1={flex1} overHidden={overHidden} style={{height: flex1 ? 'auto' : '100%'}}>
     <Table
        loading={loading}
        bordered
        scroll={{ x, y: '100%' }}
        size="middle"
        className={empty ? 'empty-table' : ''}
        locale={{
          emptyText: <div className="empty">
            <img src={require('../../static/empty.png').default}/>
            <p>暂无数据</p>
          </div>
        }}
        onChange={this.onChange}
        dataSource={{
          normal: this.state.dataSource,
          filter: this.state.filterSource,
          sorter: this.state.sorterSource,
        }[this.state.status]}
        rowKey={r => r['uid'] || r['id']}
        {...this.props}
        columns={finalColumns}
        pagination={pagination !== false ? {
          defaultCurrent: pageNum,
          current: pageNum,
          total: totalCount,
          pageSize,
          pageSizeOptions,
          showQuickJumper: false,
          showSizeChanger: true,
          // onChange: this.onPageNumChange,
          // onShowSizeChange: this.onShowSizeChange,
          showTotal: total => `共 ${total} 条`,
        } : pagination}
        />
    </Layout>
  }

  private renderActionButtonItem = (onClick, disabled: boolean, iconClassName: string, tip: string) => {
    return <Tooltip title={<div style={{width: tip.length * 12.5}}>{tip}</div>} placement="top" key={iconClassName}>
      <span className={setActionButtonClassName(`iconfont ${iconClassName} app-table-action-button`, disabled)} key={iconClassName} onClick={onClick}></span>
    </Tooltip>
  }

  private fetch = async (pageNum: number, pageSize: number, data: object = {}, isRefresh = false, isTip = true): Promise<any> => {
    const fetch = this.props.fetch || this.props.fetchOption?.api.post.bind(this.props.fetchOption?.api);
    if (!fetch) return;
    this.setState({
      loading: true,
    });
    return fetch({
      pageNum: this.props.pagination === false ? undefined : pageNum,
      pageSize: this.props.pagination === false ? undefined : pageSize,
      ...data,
    }).then(res => {
      let list: any[] = [], totalCount = 0;
      if (this.props.pagination !== false) {
        Object.keys(res).forEach(key => {
          if (Array.isArray(res[key])) {
            list = res[key]
          }
          if (res.count !== undefined) {
            totalCount = res.count;
          } else if (typeof res[key] === 'number') {
            totalCount = res[key]
          }
        })
        if (this.props.beforeRender) {
          list = this.props.beforeRender(list);
        }
      } else {
        list = res;
        totalCount = list.length;
      }

      const dataSource = list.map(i => ({...i, uuid: v4()}));
      this.setState({
        loading: false,
        pageSize,
        pageNum,
        dataSource,
        filterSource: this.filterDataSource ? this.filterDataSource(list) : dataSource,
        empty: list.length === 0,
        totalCount: totalCount
      });
      if (isRefresh && isTip) {
        message.success('刷新成功');
      }
    })
    .catch(e => {
      console.error(e);
      message.error('请求出错了:' + e.message);
    });
  }

  private onChange = (pagination, filters, sorter) => {
    console.group('--------- ViewTable ----------');
    console.log('pagination', pagination);
    console.log('sorter', sorter);
    console.groupEnd();
    if (this.props.pagination === false) {
      return;
    }
    const {field} = sorter;
    // if (this.props.pagination === false) {
    //   this.setState({
    //     loading: true,
    //   });
    //   let dataSource = [...this.state.dataSource]
    //   if (sorter.order === 'ascend') {
    //     // console.time('插入排序');
    //     // for (var i = 1; i < dataSource.length; i++) {  //外循环：主要来控制排序的趟数

    //     //   for (var j = i; j > 0; i--) {     //内循环：用来完成每趟的排序

    //     //       if (dataSource[j - 1][field] > dataSource[j][field]) {
    //     //           var temp = dataSource[j - 1]
    //     //           dataSource[j - 1] = dataSource[j]
    //     //           dataSource[j] = temp
    //     //       }
    //     //   }
    //     // }
    //     // console.timeEnd('插入排序');
    //     dataSource = ascSort(dataSource, field);
    //     // dataSource.sort((pre, cur) => {
    //     //   return cur[sorter.field] - pre[sorter.field]
    //     // });
    //   }
    //   if (sorter.order === 'descend') {
    //     // console.time('插入排序');
    //     // for (var i = 1; i < dataSource.length; i++) {  //外循环：主要来控制排序的趟数

    //     //   for (var j = i; j > 0; i--) {     //内循环：用来完成每趟的排序

    //     //       if (dataSource[j - 1][field] < dataSource[j][field]) {
    //     //           var temp = dataSource[j - 1]
    //     //           dataSource[j - 1] = dataSource[j]
    //     //           dataSource[j] = temp
    //     //       }
    //     //   }
    //     // }
    //     // console.timeEnd('插入排序');
    //     dataSource = descSort(dataSource, field);
    //   }
    //   this.setState({
    //     status: 'sorter',
    //     sorterSource: dataSource,
    //     loading: false,
    //   });
    //   return;
    // }
    let order;

    // 服务端排序
    if (sorter.column && sorter.column.sorter === true) {
      order = {
        [sorter.field]: {
          'descend': 'DESC',
          'ascend': 'ASC',
        }[sorter.order]
      };
    }
    // 接口排序
    this.fetch(pagination.current, pagination.pageSize, {
      ...this.reqData,
      order,
    });
  }

  private onPageNumChange = (page, pageSize) => {
    this.fetch(page, pageSize, this.reqData);
  }

  private onShowSizeChange = (current, size) => {
    this.fetch(1, size,  this.reqData);
  };

  public reset = async (data?: object) => {
    this.reqData = data || {};
    return this.fetch(1, this.state.pageSize, data);
  }
  public refresh = async (data: object = {}, isTip?: boolean) => {
    return this.fetch(this.state.pageNum, this.state.pageSize, Object.assign(data, this.reqData), true, isTip);
  }

  public setFilter = (predicate: (array: any[]) => any[]) => {
    this.filterDataSource = predicate;
  }

  public filter = () => {
    const filterSource = this.filterDataSource([...this.state.dataSource]);
    this.setState({
      status: 'filter',
      filterSource,
    });
  }

  public clearFilter = () => {
    this.filterDataSource = data => data;
    this.setState({
      status: 'normal',
      filterSource: [],
    });
  }
}


function sort3(arr, field) {
  var low = 0;
  var high= arr.length - 1; //设置变量的初始值
  var tmp,j;
  console.time('2.改进后冒泡排序耗时');
  while (low < high) {
    for (j = low; j < high; ++j) //正向冒泡,找到最大者
      if (arr[j][field] > arr[j+1][field]) {
        tmp = arr[j];
        arr[j] = arr[j+1];
        arr[j+1] = tmp;
      }
      --high;                 //修改high值, 前移一位
      for (j = high; j > low; --j) //反向冒泡,找到最小者
        if (arr[j][field] < arr[j-1][field]) {
          tmp = arr[j];
          arr[j] = arr[j-1];
          arr[j-1] = tmp;
        }
        ++low;                  //修改low值,后移一位 5.23681640625 ms
  }
  console.timeEnd('2.改进后冒泡排序耗时');
  return arr;
}

function descSort(arr, field) {
  var len = arr.length;
  console.time('降序冒泡排序耗时');
  for (var i = 0; i < len; i++) {
    for (var j = 0; j < len - 1 - i; j++) {
      if (arr[j][field] < arr[j+1][field]) {        //相邻元素两两对比
        var temp = arr[j+1];        //元素交换
        arr[j+1] = arr[j];
        arr[j] = temp;
      }
    }
  }
  console.timeEnd('降序冒泡排序耗时');
  return arr;
}

function ascSort(arr, field) {
  var len = arr.length;
  console.time('升序冒泡排序耗时');
  for (var i = 0; i < len; i++) {
    for (var j = 0; j < len - 1 - i; j++) {
      if (arr[j][field] > arr[j+1][field]) {        //相邻元素两两对比
        var temp = arr[j+1];        //元素交换
        arr[j+1] = arr[j];
        arr[j] = temp;
      }
    }
  }
  console.timeEnd('升序冒泡排序耗时');
  return arr;
}