import React, { PureComponent } from 'react';
import ViewTable from '../table/ViewTable';
import Status from '../status/Status';
import Dialog from '../dialog/Dialog'
import WebApi from '@/web/api';

const columns = [
  {
    title: '姓名',
    dataIndex: 'fullName',
    width:  200,
  },
  {
    title: '手机号',
    dataIndex: 'mobile',
    width: 200,
  },
  {
    title: '状态',
    dataIndex: 'status',
    width: 150,
    align: 'center',
    render: v => {
      if (v === '1') {
        return <Status.Using/>
      }
      return <Status.Stop/>
    }
  },
  {
    title: '部门',
    dataIndex: 'departmentName',
    width: 'auto',
    render: value => value || '--'
  },
];

export default class EmployeeSelectDialog extends PureComponent<{
  onOk?(keys: string[], rows: any[]): void;
  selectedRowKeys?: string[];
  rowSelectionType?: 'radio' | 'checkbox';
}> {

  constructor(props) {
    super(props);
  }

  state = {
    selectedRowKeys: this.props.selectedRowKeys || [],
    visible: false,
  };

  queryParams = {
    fullName: '',
  }

  private selectedRows: any[] = []

  render() {
    return <Dialog block title="选择员工" visible={this.state.visible} onCancel={this.close} okButtonProps={{
      disabled: this.state.selectedRowKeys.length === 0
    }} onOk={() => {
      this.props.onOk && this.props.onOk(this.state.selectedRowKeys, this.selectedRows);
    }}>
      <div style={{height: '100%'}}>
        <ViewTable
          rowKey={row => row['id']}
          fetch={() => {
            return WebApi.employee_list({})
          }}
          pagination={false}
          scroll={{x: true}}
          rowSelection={{
            type: this.props.rowSelectionType || 'radio',
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: (selectedKeys, selectedRows) => {
              this.setState({
                selectedRowKeys: selectedKeys,
              });
              this.selectedRows = selectedRows;
            },
          }}
          columns={columns as any}
        />
      </div>
    </Dialog>
  }

  public open = () => {
    this.setState({
      visible: true,
      selectedRowKeys: [],
    });
    this.selectedRows = [];
  }

  public close = () => {
    this.setState({
      visible: false
    });
  }
}
