import React from 'react';
import { message } from 'antd';
import dva from 'dva';
import createLoading from 'dva-loading';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import Root from './Root';
import { createBrowserHistory } from 'history';
import "core-js"

console.warn = () => {};
dayjs.locale('zh-cn');

const app = dva({
  history: createBrowserHistory(),
  onError(e) {
    message.error(e.message, /* duration */3);
  },
  onStateChange() {
    // 数据持久化
  }
});

window['app'] = app;

// 加载所有model
const context = require['context']('./', true, /\.js$/);
const files = context.keys().filter(item => {
  return item.includes('model')
});
const models = files.map(context);
models.forEach(model => {
  app.model(model.default);
});

// 插件
app.use(createLoading());

// 路由
app.router(Root);

app.start('#root');