import React from "react";
import { Tag } from 'antd'; 
const styles = require('./column.less');

const Using = (props) => {
  return <span className={styles.using} style={props.onClick ? {cursor: 'pointer'} : {}} onClick={props.onClick}>
    <i className="iconfont icon-qiyong"/>
    {props.children || '正常'}
  </span>
}

const Stop = (props) => {
  return <span className={styles.stop} style={props.onClick ? {cursor: 'pointer'} : {}} onClick={props.onClick}>
    <i className="iconfont icon-jinyong1"/>
    {props.children || '禁用'}
  </span>
}

const Result = (props: {
  status: 'FINISHED' | 'UNFINISHED'
  children: React.ReactNode
}) => {
  if (!props.children) {
    return null;
  }
  const color = {
    'UNFINISHED': '#f50',
    'FINISHED': '#87d068',
  }[props.status] || '#aaa'
  return <Tag color={color}>{props.children}</Tag>
}

const OrderStatus = (props: {
  status: 'WAIT_AUDIT' | 'WAIT_OUT_STOCK' | 'WAIT_IN_STOCK' | 'WAIT_DELIVERY' | 'INVALID' | 'DONE',
  children: React.ReactNode
}) => {
  const {status, children} = props;
  if (status === 'DONE') {
    return <Tag color="#87d068" style={{width: 82}}>{children}</Tag>;
  }
  if (status === 'INVALID') {
    return <Tag color="gray" style={{width: 82}}>{children}</Tag>;
  }

  if (status === 'WAIT_DELIVERY') {
    return <Tag color="orange" style={{width: 82}}>{children}</Tag>;
  }

  if (status === 'WAIT_AUDIT') {
    return <Tag color="#f50" style={{width: 82}}>{children}</Tag>;
  }

  return <Tag color="red" style={{width: 82}}>{children}</Tag>;
}

const PaymentStatus = (props: {
  completed: boolean;
  children: React.ReactNode
}) => {
  if (!props.completed) {
    return <span className={styles.stop}>
      <i className="iconfont icon-weifukuan" style={{marginRight: 5}}/>
      {props.children}
    </span>
  }
  return <span className={styles.using}>
    <i className="iconfont icon-shoukuan1" style={{marginRight: 5}}/>
    {props.children}
  </span>
}

const AmountDirection = (props: {
  income?: boolean;
  children: React.ReactNode;
}) => {
  if (props.income) {
    return <span className="component-received-cell">{props.children}</span>
  }
  return <span className="component-payment-cell">-{props.children}</span>
}

const Column = (props: {
  status: '0' | '1',
  children?: React.ReactNode
}) => {
  if (props.status === '1') {
    return <Using>{props.children}</Using>
  }
  return <Stop>{props.children}</Stop>
}

Column.Using = Using;
Column.Stop = Stop;
Column.PaymentStatus = PaymentStatus;
Column.OrderStatus = OrderStatus;
Column.Result = Result;
Column.AmountDirection = AmountDirection;

export default Column;