export default (functionList: any[], menus: any[]) => {
  return menus.map((m, mIndex) => {
    if (m.code && !functionList.includes(m.code)) {
      return null;
    }

    if (Array.isArray(m.children)) {
      m.children.forEach((g, index) => {
        g.children = g.children.filter(c => {
          if (Array.isArray(c.code)) {
            return c.code.some(i => functionList.includes(i));
          }
          return c.code && functionList.includes(c.code)
        });
        if (g.children.length === 0) {
          m.children[index] = null;
        }
      });
      m.children = m.children.filter(g => !!g);
      if (m.children.length === 0) {
        m = null;
      }
    }
    return m;
  }).filter(m => !!m);
};