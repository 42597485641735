import catching, { createCatch, findCatch, updateCatch, deleteCatch } from "../catching";
import request from "../request";

export default {
  'sale.product.findAndCount': {
    url: '/agent/sale/product/findAndCount',
    post: function(data: {
      customerId: string;
      pageNum: number;
      pageSize: number;
    }) {
      return request(this.url, data).catch(e => findCatch('客户订货历史', e));
    }
  },
}