const cache = {};

export default key => Wrapped => {
  return class Component extends React.PureComponent {
    cacheKey = key ? key : Wrapped.cacheKey;
    componentDidMount() {
      // 清除缓存
      this.cacheKey && delete cache[this.cacheKey];
    }

    setCacheData = data => {
      cache[this.cacheKey] = data;
    }

    render() {
      return <Wrapped {...this.props} cacheQuery={
        {
          cacheData: cache[this.cacheKey] || {},
          setCacheData: this.setCacheData
        }
      }/>
    }
  }
}