import request from '@/utils/request';

export const findOrderSummary = params => {
  return request('/admin/report/order/findOrderSummaryTotal', params);
}

export const findOrderSummaryList = params => {
  return request('/admin/report/order/findOrderSummaryList', params);
}

export const findProductSummary = params => {
  return request('/admin/report/product/findProductSummaryTotal', params);
}

export const findProductSummaryList = params => {
  return request('/admin/report/product/findProductSummaryList', params);
}

export const findOrderReturnSummary = params => {
  return request('/admin/report/orderReturn/findOrderReturnSummaryTotal', params);
}

export const findOrderReturnSummaryList = params => {
  return request('/admin/report/orderReturn/findOrderReturnSummaryList', params);
}
