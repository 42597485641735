import request from '@/utils/request';

export const findSupplyOrderSummary = params => {
  return request('/admin/report/supply/findSupplyOrderSummaryTotal', params);
}

export const findSupplyOrderSummaryList = params => {
  return request('/admin/report/supply/findSupplyOrderSummaryList', params);
}

export const findSupplyProductSummary = params => {
  return request('/admin/report/supply/findSupplyProductSummaryTotal', params);
}

export const findSupplyProductSummaryList = params => {
  return request('/admin/report/supply/findSupplyProductSummaryList', params);
}




export const findSupplyReturnSummary = params => {
  return request('/admin/report/supply/findSupplyReturnSummaryTotal', params);
}

export const findSupplyReturnSummaryList = params => {
  return request('/admin/report/supply/findSupplyReturnSummaryList', params);
}

export const findSupplyProductReturnSummary = params => {
  return request('/admin/report/supply/findReturnProductSummaryTotal', params);
}

export const findSupplyProductReturnSummaryList = params => {
  return request('/admin/report/supply/findReturnProductSummaryList', params);
}

export const findSupplyDetail = params => {
  return request('/admin/report/supply/findSupplyDetail', params);
}