import React, { Component } from 'react';
import { Result, Button } from 'antd';

export default props => {

  return <Result
    status="404"
    title="404"
    subTitle="抱歉, 您访问的页面不存在"
    // extra={<Button type="primary" onClick={() => {
    //   props.history.goBack();
    // }}>返回</Button>}
  />
}
