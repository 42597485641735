/**
 * 网络请求
 * @argument {object} 请求参数
 * @returns {object} 请求实例
 */
import { message, Modal } from 'antd';
import { routerRedux } from 'dva/router';
import config from '@/config';
import axios from 'axios';
import { getToken } from '@/storage/storage';

const baseURL = config.apiUrl;

const codeMessage = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '请求地址出错',
  406: '请求的格式不可得。',
  408: '请求超时',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
};

export const serializeUrlencoded = param => {
	if (param && typeof param === 'object'){
    return Object.keys(param).map(key => {
      return `${key}=${param[key]}`;
    }).join('&');
  }
  return param;
};

export const serializeFormData = param => {
  const data = new FormData();
  for (let key in param) {
    if (param[key] !== undefined && param[key] !== null) {
      data.append(key, param[key]);
    } else {
      data.append(key, '');
    }
  }
  return data;
};

export const contentType = {
  json: 'application/json;charset=utf-8',
  form: 'multipart/form-data;charset=utf-8',
  urlencoded: 'application/x-www-form-urlencoded;charset=utf-8'
};

let requestLock = false;

axios.interceptors.request.use(
  (config) => {
    // 此处自动序列化数据类型
    // switch(config.headers['Content-Type']) {
    //   case contentType.json: {
    //     config.data = typeof config.data === 'string' ? config.data : JSON.stringify(config.data);
    //   }
    //   case contentType.urlencoded: {
    //     config.data = serializeUrlencoded(config.data);
    //   }
    //   case contentType.form: {
    //     config.data = serializeFormData(config.data);
    //   }
    //   default: {}
    // }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(async (options) => {
  if (options.data){
    // 禁用
    if (options.data.code === 'A9999') {
      if (!requestLock) {
        Modal.warning({
          centered: true,
          title: '易采惠',
          content: options.data.msg,
          okText: '退出易采惠',
          onOk: () => {
            window.app._store.dispatch(routerRedux.replace('/login'));
          }
        });
      }
      requestLock = true;
      return new Promise(() => {

      });
    }
    if (options.data.code === 'I0002' || options.data.code === '9000') {
      message.warning(options.data.msg);
      window.app._store.dispatch(routerRedux.replace('/login'));
      return;
    }
    return options.data;
  } else {
    throw new Error('系统繁忙: 未返回数据');
  }
  }, (error) => {
    console.log(error);
    let ychError = error;
    if (error.response) {
      //存在请求，但是服务器的返回一个状态码
      //他们都在2xx之外
      //console.log(error.response.data);
      //console.log(error.response.status);
      //console.log(error.response.headers);
      const extendMessage = error.response.status === 404 ? `:${error.response.config.url.replace(baseURL, '')}` : '';
      error.message = codeMessage[error.response.status] || '请求错误[' + error.response.status + ']' + extendMessage ;
    } else {
      // 一些错误是在设置请求时触发的
      if (error.message.indexOf('timeout') > -1){
        error.message = '请求超时';
      }
      if (error.message.indexOf('Network Error') > -1) {
        error.message = '服务器繁忙';
      }
    }
    return Promise.reject(ychError);
  }
);

export default (url, data, options = {}) => {
  let config = {};
  if (typeof url === 'object' && data === undefined && options === undefined) {
    config = url;
  } else {
    config.url = url;
    config.data = data;
  }

  if (typeof config.data === 'object' && !(config.data instanceof FormData)) {
    config.data = JSON.stringify(config.data);
  }

  const token = getToken();
  if (!requestLock) {

    return axios({
      baseURL,
      timeout: 20000,
      method: 'POST',
      ...config,
      ...options,
      headers: {
        'Content-Type': typeof config.data === 'string' ? contentType.json : contentType.form,
        'authToken': token,
        // 'auth-token': token,
      },
    });
  }

  return new Promise(() => {

  });
};

export const handleResponse = (res) => {
  if (res.code !== '0000') {
    throw new Error(res.msg);
  }
  return res.response;
};
