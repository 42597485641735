type EnumType = {
  value:string
  name: string
}

export const getEnumList = (data): EnumType[] => {
  return Object.keys(data).map(key => data[key])
}

export const PRODUCT_STATUS = {
  UP: {
    name: '上架',
    value: '11'
  },
  DOWN: {
    name: '下架',
    value: '12'
  },
  DELETE: {
    name: '删除',
    value: '99'
  },
};

export const SALE_ORDER_WAY = {
  CUSTOMER: {
    name: '自主下单',
    value: '12',
  },
  AGENT: {
    name: '代下单',
    value: '11',
  },
};

export const SALE_STATUS  = {
  WAIT_AUDIT: {
    value: '11',
    name: '待审核'
  },
  WAIT_OUT_STOCK: {
    value: '12',
    name: '待出库'
  },
  WAIT_DELIVERY: {
    value: '13',
    name: '待发货确认'
  },
  DONE: {
    value: '14',
    name: '已完成'
  },
  INVALID: {
    value: '99',
    name: '已作废',
  },
}


export const SALE_RETURN_STATUS = {
  WAIT_AUDIT: {
    value: 'WAIT_AUDIT',
    name: '待审核'
  },
  WAIT_IN_STOCK: {
    value: 'WAIT_IN_STOCK',
    name: '待入库'
  },
  DONE: {
    value: 'DONE',
    name: '已完成'
  },
  INVALID: {
    value: 'INVALID',
    name: '已作废',
  },
};

export const PAYMENT_STATUS = {
  WAIT: {
    value: '11',
    name: '未付款',
  },
  PART: {
    value: '12',
    name: '部分付款',
  },
  DONE: {
    value: '13',
    name: '已付款',
  },
}

export const PAYMENT_STATUS2 = {
  WAIT: {
    value: '11',
    name: '未付款',
  },
  DONE: {
    value: '12',
    name: '已付款',
  },
}

export const PAYMENT_STATUS_3 = {
  WAIT: {
    value: 'WAIT_PAYMENT',
    name: '未付款',
  },
  DONE: {
    value: 'DONE_PAYMENT',
    name: '已付款',
  },
}

export const PRINT_STATUS = {
  WAIT: {
    value: '11',
    name: '未打印'
  },
  DONE: {
    value: '12',
    name: '已打印'
  },
}

export const PURCHASE_ORDER_STATUS = {
  WAIT_AUDIT: {
    value: '11',
    name: '待审核',
  },
  WAIT_IN_STOCK: {
    value: '12',
    name: '待入库',
  },
  FINISHED: {
    value: '13',
    name: '已完成',
  },
  INVALID: {
    value: '99',
    name: '已作废',
  },
};

export const PURCHASE_PAY_STATUS = {
  UNPAID: {
    value: '11',
    name: '已付款',
  },
  PAID: {
    value: '12',
    name: '未付款',
  },
};
export const PURCHASE_RETURN_STATUS = {
  WAIT_AUDIT: {
    value: '11',
    name: '待审核'
  },
  WAIT_OUT_STOCK: {
    value: '12',
    name: '待出库'
  },
  DONE: {
    value: '13',
    name: '已完成'
  },
  INVALID: {
    value: '99',
    name: '已作废',
  },
}

// 交易类型
export const BOOK_TYPE = {
  PURCHASE_PAYMENT: {
    value: '11',
    name: '进货付款'
  },
  PURCHASE_RETURN_RECEIVED: {
    value: '12',
    name: '进货退货收款'
  },
  SALE_RECEIVED: {
    value: '13',
    name: '订货收款'
  },
  SALE_RETURN_PAYMENT: {
    value: '14',
    name: '订货退货付款'
  },
  OTHER_PAYMENT: {
    value: '15',
    name: '其他付款'
  },
  Other_OTHER_RECEIVED: {
    value: '16',
    name: '其他收款'
  },
  FEE_OTHER: {
    value: '20',
    name: '其他费用'
  },
  FEE_OIL: {
    value: '21',
    name: '加油'
  },
  FEE_SALARY: {
    value: '22',
    name: '工资'
  },
  FEE_ELECTRICITY: {
    value: '23',
    name: '电费'
  },
  FEE_WATER: {
    value: '24',
    name: '水费'
  },
}

// 优惠券类型
export const COUPON_TYPE = {
  SALE: {
    name: '订单券',
    value: 'SALE',
  },
  PRODUCT: {
    name: '商品券',
    value: 'PRODUCT',
  },
  CATEGORY: {
    name: '品类券',
    value: 'PRODUCT',
  },
};

// 订货单枚举值映射
export const saleEnumMap = (status: string) => {
  return {
    '11': 'WAIT_AUDIT',
    '12': 'WAIT_OUT_STOCK',
    '13': 'WAIT_DELIVERY',
    '14': 'DONE',
    '99': 'INVALID',
  }[status];
}

// 进货单枚举值映射
export const purchaseEnumMap = (status: string) => {
  return {
    '11': 'WAIT_AUDIT',
    '12': 'WAIT_IN_STOCK',
    '13': 'DONE',
    '99': 'INVALID',
  }[status];
}

// 进货退货单枚举值映射
export const purchaseReturnEnumMap = (status: string) => {
  return {
    '11': 'WAIT_AUDIT',
    '12': 'WAIT_OUT_STOCK',
    '13': 'DONE',
    '99': 'INVALID',
  }[status];
}

// 收付款枚举映射
export const paymentEnumMap = (status: string) => {
  return {
    '11': 'WAIT',
    '12': 'PART',
    '13': 'DONE',
  }[status]
}
export const payment2EnumMap = (status: string) => {
  return {
    '11': 'WAIT',
    '12': 'DONE',
  }[status]
}