export function getFileFromImgUrl(imgUrl, callback) {
  const image = new Image();
  image.src = imgUrl + "?v=" + Date.now();
  image.setAttribute('crossOrigin', 'anonymous');
  image.onload = function() {
    const base64 = getBase64FromImage(image);
    const file = getFileFromBase64(base64, Date.now() + '');
    callback(file);
  };
}

export function getBase64FromImage(img) {
  const canvas = document.createElement("canvas");
  canvas.width = img.width;
  canvas.height = img.height;
  const ctx = canvas.getContext('2d');
  ctx.drawImage(img, 0, 0, img.width, img.height);
  const ext = img.src.substring(img.src.lastIndexOf('.') + 1).toLowerCase();
  const dataURL = canvas.toDataURL('image/' + ext);
  return dataURL;
}

export function getFileFromBase64(data, fileName) {
  const dataArr = data.split(',');
  const byteString = atob(dataArr[1]);

  const options = {
    type: 'image/jpeg',
    endings: 'native'
  };
  const u8Arr = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    u8Arr[i] = byteString.charCodeAt(i);
  }
  return new File([u8Arr], fileName + '.jpg', options);
}