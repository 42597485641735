import NotFound from '../page/404';
import ErrorPage from '../page/500';
import Success from '../page/Success';
import NotPermission  from '../page/403';
import { replace } from './util';
import { LibAuthCode } from 'lib/Auth';

export const notFountRouter = {
  name: '页面不存在',
  path: '/404',
  component: NotFound,
};

export default {
  'userInfo.view': {
    name: '个人中心',
    path: '/userInfo',
    asyncComponent: () => import('../page/user-info/UserInfo'),
  },
  'auth.view': {
    code: LibAuthCode.auth.view,
    name: '权限管理',
    path: '/auth',
    asyncComponent: () => import('../page/system/auth/AuthListPage'),
  },
  'role.create': {
    code: LibAuthCode.auth.create,
    name: '新增角色',
    path: '/role',
    asyncComponent: () => import('../page/system/auth/role/CreateRole'),
  },
  'role.update': {
    code: LibAuthCode.auth.update,
    name: '修改角色',
    path: '/role/:id',
    getPath(id) {
      return replace(this.path, {id});
    },
    asyncComponent: () => import('../page/system/auth/role/UpdateRole'),
  },
  // 403 404 500
  '403': {
    name: '没有权限',
    path: '/403',
    component: NotPermission,
  }, 
  '500': {
    name: '页面出错',
    path: '/500',
    component: ErrorPage,
  },
  'success.create': {
    name: '新增成功',
    path: '/create-success',
    component: Success,
  },
};