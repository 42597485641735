const Token_Key: string = 'caSNecIcDKNcGdd';
const Login_Name: string = 'dafrvaFdfvVdfv';
const Password_Key: string = '12rcds5FEDCDvt^';

export const getToken = () => localStorage.getItem(Token_Key);
export const setToken = (token: string) => localStorage.setItem(Token_Key, token);
export const removeToken = () => localStorage.removeItem(Token_Key);

export const getLoginName = () => localStorage.getItem(Login_Name);
export const setLoginName = (string: string) => localStorage.setItem(Login_Name, string);

export const getPassword = () => localStorage.getItem(Password_Key);
export const setPassword = (string: string) => localStorage.setItem(Password_Key, string);