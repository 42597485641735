import React from "react";
import ViewTable from "../table/ViewTable";
import Layout from "../layout/Layout";
import WebApi from "@/web/api";
import Filter from "../filter/Filter";

export default class customerTable extends React.PureComponent<{
  onChange(selectedRowKeys: React.Key[], selectedRows: any[]): void
}> {
  private keyword = ''
  private table = React.createRef<ViewTable>();
  state = {
    visible: false
  }
  render() {
    return <Layout.TabPage>
      <Filter
        fields={[
          {
            type: 'input',
            field: 'keyword',
            name: '搜索',
            placeholder: '名称/手机号'
          }
        ]}
        onChange={(values: any) => {
          this.keyword = values.keyword;
          this.table.current?.reset({keyword: this.keyword});
        }}
      />

      <ViewTable
        flex1
        ref={this.table}
        rowSelection={{
          type: 'radio',
          onChange: this.props.onChange
        }}
        columns={[
          {
            title: '手机号',
            width: 160,
            align: 'center',
            dataIndex: 'mobile',
          },
          {
            title: '客户',
            width: 'auto',
            titleAlign: 'left',
            dataIndex: 'customerName',
          },
        ]}
        fetch={data => {
          return WebApi.customer_paging(data)
        }}
      />
    </Layout.TabPage>
  }
}