import {Modal} from 'antd';
import logo from '@/static/logo.jpg';

const Notification = window.Notification || window.mozNotification || window.webkitNotification;

let isPermission = false;

function creatNotification(params) {
  const notification = new Notification('易采惠消息', {
    // 通知显示正文。非必须，默认为空
    body: '',
    // 通知显示正文的图片地址。非必须，默认为空
    image: '',
    // 通知的分类标记（ID）。非必须，默认为空
    tag: '',
    // 通知相关联的数据，通常用于方法的回调，传参。非必须，默认为空
    data: '',
    // 通知显示延迟的时间。非必须，默认通知实例创建完成就显示
    timestamp: '',
    // 通知主体内容的水平展示顺序，有点类似direction属性。非必须，默认值是auto, 可以是ltr或rtl
    dir: 'auto',
    icon: logo,
    // 新通知出现是否覆盖旧的通知，覆盖（true）则永远只显示一条通知，不覆盖（false）则会多条通知重叠。非必须，默认为true
    renotify: false,
    // 通知是否静音。非必须，默认为false，表示无声
    silent: false,
    // 通知声源文件地址。非必须，默认为空
    // sound: require('@/static/notice.mp3'),
    // 指定通知是否保持活性，知道用户点击或关闭。非必须，默认为false
    // requireInteraction: false
    ...params
  });

  notification.onshow = function () {}
  notification.onclick = function (e) {
    //可以直接通过实例的方式获取data内自定义的数据
    //也可以通过访问回调参数e来获取data的数据
    // window.open(n.data.url, '_blank');
    notification.close();
  }
  notification.onclose = function () {
    console.log('通知已关闭');
  }
  notification.onerror = function (err) {
    console.log('通知出错了');
  }
}

export default function (params){
  if (isPermission) {
    creatNotification(params);
    return;
  }
  if(Notification){
    const permission = Notification.permission;
    if (permission === 'granted') {//允许通知
      isPermission = true;
      creatNotification(params);
    } else if (permission === 'denied') {
      const isWaring = localStorage.getItem('$$_message_notification');
      if (isWaring !== '0') {
        Modal.confirm({
          title: '桌面消息推送已关闭',
          content: '您将错过来自系统的桌面消息提醒，但这并不影响正常接收消息，若想启用此功能，请在浏览器设置中允许通知',
          okText: '知道了',
          cancelText: '不再提示',
          onCancel: () => {
            localStorage.setItem('$$_message_notification_permission', '0');
          }
        });
      }
    } else {
      Notification.requestPermission((result) => {
        if (result === 'granted') {
          isPermission = true;
        }
      });
    }
  }else{
    const isWaring = localStorage.getItem('$$_message_notification_support');
    if (isWaring !== '0') {
      Modal.confirm({
        title: '您的浏览器不支持桌面消息推送',
        content: '若想要使用此功能，请下载安装最新版chrome浏览器',
        okText: '知道了',
        cancelText: '不再提示',
        onCancel: () => {
          localStorage.setItem('$$_message_notification_support', '0');
        }
      });
    }
    console.log("您的浏览器不支持桌面消息");
  }
};
