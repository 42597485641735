import request from '@/utils/request';

export const findSupplier = (params) => {
  return request('/admin/report/target/findAllSupplier', params);
}

export const findSupplierDetail = (params) => {
  return request('/admin/report/target/findSupplierDetail', params);
}

export const findSupplierStock = (params) => {
  return request('/admin/report/target/findSupplierStock', params);
}

export const findSupplierStockDetail = (params) => {
  return request('/admin/report/target/findSupplierStockDetail', params);
}

export const findUserOrder = (params) => {
  return request('/admin/report/target/findAllUserOrder', params);
}

export const findUserOrderDetail = (params) => {
  return request('/admin/report/target/findUserOrderDetail', params);
}

export const findUserReturn = (params) => {
  return request('/admin/report/target/findAllUserReturn', params);
}

export const findUserReturnDetail = (params) => {
  return request('/admin/report/target/findUserReturnDetail', params);
}

export const findAllUserProfit = (params) => {
  return request('/admin/report/target/findAllUserProfit', params);
}