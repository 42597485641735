import { createCatch, deleteCatch, findCatch, updateCatch } from "../catching";
import request from "../request";

export default {

  'notice.update': {
    url: '/agent/notice/update',
    post(data: {
      noticeId: string;
      closeStatus?: '1' | '0';
      status?: '1' | '0',
      title?: string;
      content?: string;
    }) {
      return request(this.url, data).catch(e => updateCatch('通知公告', e));
    }
  },
  'notice.delete': {
    url: '/agent/notice/delete',
    post(data: {
      noticeId: string;
    }) {
      return request(this.url, data).catch(e => deleteCatch('通知公告', e));
    }
  },
}