import WebApi from "@/web/api";
import { TreeSelect } from "antd";
import { TreeSelectProps } from "antd/lib/tree-select";
import React from "react";
import "./CategorySelectTree.less";

interface IProps extends TreeSelectProps<any> {
  addRoot?: boolean;
  bordered?: boolean;
  onFetch?(data: any[]): void;
}
export default React.memo((props: IProps) => {
  const {onFetch, addRoot = false, bordered = false, ...reset} = props;
  const [categories, setCategories] = React.useState([]);

  React.useEffect(() => {
    WebApi.category_list()
    .then(data => {
      let treeData = data.map((i: CategoryType) => {
        return {
          title: i.categoryName,
          value: i.categoryId,
          children: i.children.map(c => {
            return {
              title: c.categoryName,
              value: c.categoryId,
            };
          })
        };
      });
      if (addRoot) {
        data = [{
          title: '全部分类',
          value: '',
          children: treeData
        }]
      }
      setCategories(treeData as any);
      onFetch && onFetch(treeData as any[]);
    });
  }, []);
  return <TreeSelect
    bordered={bordered}
    treeDefaultExpandAll
    className="components-category-tree-select search-tree-select"
    dropdownMatchSelectWidth={200}
    placeholder="选择..."
    showSearch
    {...reset}
    treeData={categories}
  />
})