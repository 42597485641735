import React from 'react';
import { Layout, Menu } from 'antd';
import { Link } from 'dva/router';
const { SubMenu, ItemGroup } = Menu;
import { Sub_Menu, Menu_Item } from '@/menu/type';
const styles = require('./app-sider.less');
// import 'antd/es/layout/style/index'

interface Props {
  menu: any[]
  history: any
  collapsed: boolean
}

export default React.memo((props: Props) => {
  const {menu, history, collapsed} = props;
  return <Layout.Sider
    trigger={null}
    collapsible
    collapsed={collapsed}
    width={160}
    className="app-menu"
  >
    <div className="app-logo">
      <img src={require('@/static/logo.png').default}/>
    </div>
    <Menu
      theme="dark"
      mode="vertical"
      defaultSelectedKeys={history.location.pathname}
      // subMenuCloseDelay={0}
    >
      {
        menu.filter(sub => !!sub).map(sub => {
          if (sub.type === Sub_Menu) {
            return <SubMenu
              popupClassName="app-submenu"
              key={sub.name}
              title={
                <div style={{display: 'flex', alignItems: 'center', height: '100%'}}>
                  <span className="anticon">
                    {sub.icon}
                  </span>
                  <span>{sub.name}</span>
                </div>
              }
            >
              {
                sub.children.map(group => {
                  return <ItemGroup
                    key={group.name}
                    title={group.name}>
                    {
                      group.children.map(item => {
                        return <Menu.Item key={item.name}>
                          {item.icon}
                          <Link to={item.path}>{item.name}</Link>
                        </Menu.Item>;
                      })
                    }
                  </ItemGroup>
                })
              }
            </SubMenu>;
          }
          if (sub.type === Menu_Item) {
            return <Menu.Item key={sub.name}>
              <span className="anticon">
                {sub.icon}
              </span>
              <Link to={sub.path}>{sub.name}</Link>
            </Menu.Item>;
          }
        })
      }
    </Menu>
  </Layout.Sider>
});