import React from 'react';
import { Modal } from 'antd';
import { ModalFuncProps, ModalProps } from 'antd/lib/modal';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ButtonProps } from 'antd/lib/button';
const style = require('./styles.less');

export interface DialogProps extends ModalProps{
  children?: React.ReactNode;
  block?: boolean;
  full?: boolean;
  onOkPro?(setLoading: (loading: boolean) => void): void;
}

interface IState {
  loading: boolean;
}

class Dialog extends React.PureComponent<DialogProps, IState> {
  static confirm = (params: ModalFuncProps) => {
    Modal.confirm({
      title: '温馨提示',
      icon: <ExclamationCircleOutlined />,
      content: '',
      okText: '确认',
      cancelText: '取消',
      ...params
    });
  }

  static error = (params: ModalFuncProps) => {
    Modal.error({
      title: '温馨提示',
      content: '',
      ...params
    });
  }

  static info = (params: ModalFuncProps) => {
    Modal.info({
      title: '温馨提示',
      ...params
    });
  }

  static warning = (params: ModalFuncProps) => {
    Modal.warning({
      title: '温馨提示',
      ...params
    });
  }

  static success = (params: ModalFuncProps) => {
    Modal.success({
      title: '温馨提示',
      ...params
    });
  }

  state: IState = {
    loading: false,
  }
  render() {
    const {
      title = false,
      footer,
      destroyOnClose = true,
      visible,
      width,
      okText = '确定',
      cancelText = '取消',
      getContainer,
      okButtonProps = {
        loading: this.state.loading,
        disabled: this.state.loading,
      },
      onCancel = () => {},
      wrapClassName,
      full,
      children,
    } = this.props;
    if (full === false) {
      return <Modal title={title}
        footer={footer}
        wrapClassName={"component-dialog component-dialog-no-full " + (wrapClassName || '')}
        destroyOnClose={destroyOnClose}
        visible={visible}
        okText={okText}
        maskClosable={false}
        cancelText={cancelText}
        maskTransitionName="fade"
        transitionName="fade"
        okButtonProps={okButtonProps}
        onCancel={this.onCancel}
        onOk={this.onOk}
        width={width}
        >{children}</Modal>
    }
    return <div>
      <Modal
        title={<div>{title}</div>}
        wrapClassName="component-dialog component-dialog-full"
        closable={false}
        width={width || "100%"}
        footer={footer}
        maskClosable={false}
        destroyOnClose={destroyOnClose}
        getContainer={getContainer || document.querySelector('.ant-layout-content') as HTMLElement}
        maskTransitionName="fade"
        transitionName="fade"
        style={{ top: 0, paddingBottom: 0, bottom: 0, position: 'absolute', zIndex: 1000000000 }}
        // zIndex={10000}
        visible={visible}
        onCancel={this.onCancel}
        okButtonProps={okButtonProps}
        okText={okText}
        cancelText={cancelText}
        onOk={this.onOk}
      >
        {children}
      </Modal>
    </div>
  }

  private setLoading = (loading: boolean) => {
    this.setState({
      loading: loading,
    });
  }

  private onOk = (e) => {
    this.props.onOk && this.props.onOk(e);
    this.props.onOkPro && this.props.onOkPro(this.setLoading);
  }

  private onCancel = (e) => {
    this.setState({
      loading: false,
    });
    this.props.onCancel && this.props.onCancel(e);
  }
}

export default Dialog;