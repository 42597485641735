import LibApi from './Api';
import LibAgentUrl from './AgentUrl';
import { LibIAgentSaleOrderCreateDetail } from '../interface/SaleOrderInterface';
import { LibISortValueEnum } from '../interface';
import { LibIProductUnit } from '../interface/ProductInterface';

interface LibIPagingParam {
  pageNum?: number;
  pageSize?: number;
  keyword?: string;
}

export default class LibAgentApi<IConfig> extends LibApi<IConfig> {

  /********** 用户 **********/
  // 登录
  async user_login(
    data: {
      username: string;
      password: string;
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.USER_LOGIN_URL, data, config);
  }
  // 修改密码
  async user_update_password(
    data: {
      old: string;
      new: string;
    },
    config?: IConfig
  ) {
    return this.request('/agent/user/update_password', data, config);
  }

  /********** 首页数据 *********/
  async home_data_detail() {
    return this.request(LibAgentUrl.HOME_DATA_DETAIL_URL, {});
  }

  /********** 订货单 **********/
  // 订货单分页
  async sale_order_paging(
    data: {
      keyword?: string;
      orderStatus?: string;
      withDetail?: boolean;
      paymentStatus?: string;
      dateRange?: [string, string];
      customerId?: string;
    } & LibIPagingParam,
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.SALE_ORDER_PAGE_URL, {
      ...(data || {}),
    },
    config);
  }
  async sale_product_paging(
    data: {
      keyword?: string;
      customerId?: string;
      dateRange?: [string, string];
    } & LibIPagingParam,
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.SALE_PRODUCT_PAGE_URL, {
      ...(data || {}),
    },
    config);
  }
  async sale_order_count(data = {}, config?: IConfig) {
    return this.request(LibAgentUrl.SALE_ORDER_COUNT_URL, data, config);
  }
  // 订货单商品修改选项列表
  async sale_detail_update_option(
    data: {
      saleOrderId: string;
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.SALE_ORDER_DETAIL_UPDATE_OPTION_URL, data, config);
  }
  // 订货单详情
  async sale_order_detail(id: string, config?: IConfig) {
    return this.request(LibAgentUrl.SALE_ORDER_DETAIL_URL, {saleOrderId: id}, config);
  }
  // 订货单打印状态修改
  async sale_order_print_status_update(
    data: {
      saleOrderIdList: string[];
      printStatus: string;
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.SALE_ORDER_PRINT_STATUS_UPDATE_URL, data, config);
  }
  // 订货单打印信息查询
  async sale_order_print_data_list(
    data: {
      saleOrderIdList: string[];
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.SALE_ORDER_PRINT_DATA_LIST_URL, data, config);
  }
  // 订货单收货地址修改
  async sale_order_address_update(
    data: {
      saleOrderId: string;
      consigneeName: string;
      consigneeMobile: string;
      consigneeAddress: string;
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.SALE_ORDER_ADDRESS_UPDATE_URL, data, config);
  }
  // 订货单备注修改
  async sale_order_remark_update(
    data: {
      saleOrderId: string;
      remark: string;
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.SALE_ORDER_REMARK_UPDATE_URL, data, config);
  }
  // 订货单商品修改
  async sale_order_product_update(
    data: {
      saleOrderId: string;
      detailList: LibIAgentSaleOrderCreateDetail[]
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.SALE_ORDER_PRODUCT_UPDATE_URL, data, config);
  }
  // 订货单作废
  async sale_order_invalid(
    data: {
      saleOrderId: string;
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.SALE_ORDER_INVALID_URL, data, config);
  }
  // 订货通过审核
  async sale_order_audit(
    data: {
      saleOrderId: string;
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.SALE_ORDER_AUDIT_URL, data, config);
  }
  // 订货退回审核
  async sale_order_back_audit(
    data: {
      saleOrderId: string;
      reason: string;
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.SALE_ORDER_AUDIT_RETURN_URL, data, config);
  }
  // 订货单查询出库选项信息
  async sale_order_out_stock_option_list(
    data: {
      saleOrderId: string;
    },
    config?: IConfig
  ): Promise<any[]> {
    return this.request(LibAgentUrl.SALE_ORDER_OUT_STOCK_OPTION_LIST_URL, data, config);
  }
  // 订货出库
  async sale_order_out_stock(
    data: {
      saleOrderId: string;
      outStockList: {
        saleDetailId: string;
        items: {
          stockId: string;
          count: number;
        }[]
      }[]
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.SALE_ORDER_OUT_STOCK_URL, data, config);
  }
  // 订货发货
  async sale_order_deliver(
    data: {
      saleOrderId: string;
      company?: string;
      number?: string;
      remark?: string;
      datetime?: string;
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.SALE_ORDER_DELIVER_URL, data, config);
  }
  // 修改发货记录
  async sale_order_deliver_record_update(
    data: {
      saleOrderId: string;
      company?: string;
      number?: string;
      remark?: string;
      datetime?: string;
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.SALE_ORDER_DELIVER_RECORD_UPDATE_URL, data, config);
  }
  // 订货添加付款记录
  async sale_order_payment_create(
    data: {
      saleOrderId: string;
      actualAmount: string;
      shouldAmount: string;
      datetime: string;
      remark?: string;
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.SALE_ORDER_PAYMENT_URL, data, config);
  }
  // 订货单删除
  async sale_order_delete(
    data: {
      saleOrderId: string;
    },
    config?: IConfig) {
    return this.request(LibAgentUrl.SALE_ORDER_DELETE_URL, data, config);
  }
  // 代下单
  async sale_order_create(
    data: {
      customerId: string;
      consigneeAddress: string;
      consigneeMobile: string;
      consigneeName: string;
      remark?: string;
      detailList: LibIAgentSaleOrderCreateDetail[]
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.SALE_ORDER_CREATE_URL, data, config);
  }
  // 查询商品最近一次的订货价格
  async sale_product_latest_price(data: {
    customerId: string;
    skuId: string;
  }, config?: IConfig) {
    return this.request(LibAgentUrl.SALE_PRODUCT_LATEST_PRICE, data, config);
  }
  // 查询代下单客户
  async sale_customer_List(data?: {}, config?: IConfig) {
    return this.request(LibAgentUrl.SALE_CUSTOMER_URL, data, config);
  }



  /********* 订货退货单 *******/
  async sale_return_order_paging(
    data: {
      keyword?: string;
      orderStatus?: string;
      paymentStatus?: string;
      customerId?: string;
      dateRange?: [string, string];
    } & LibIPagingParam,
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.SALE_RETURN_ORDER_PAGING, data, config);
  }
  async sale_return_product_paging(
    data: {
      keyword?: string;
      categoryId?: string;
      dateRange?: [string, string];
      customerId?: string;
    } & LibIPagingParam,
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.SALE_RETURN_PRODUCT_PAGING, data, config);
  }
  async sale_return_order_detail(
    data: {
      saleReturnOrderId: string;
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.SALE_RETURN_ORDER_DETAIL, data, config);
  }
  async sale_return_order_audit(
    data: {
      saleReturnOrderId: string;
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.SALE_RETURN_ORDER_AUDIT, data, config);
  }
  async sale_return_order_cancel(
    data: {
      saleReturnOrderId: string;
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.SALE_RETURN_ORDER_CANCEL, data, config);
  }
  async sale_return_order_delete(
    data: {
      saleReturnOrderId: string;
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.SALE_RETURN_ORDER_DELETE, data, config);
  }
  async sale_return_order_in_stock(
    data: {
      saleReturnOrderId: string;
      detailList: {
        saleReturnOrderDetailId: string;
        repositoryId: string;
      }[]
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.SALE_RETURN_ORDER_IN_STOCK, data, config);
  }
  async sale_return_order_pay(
    data: {
      saleReturnOrderId: string;
      actualAmount: string;
      datetime: string;
      remark?: string;
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.SALE_RETURN_ORDER_PAY, data, config);
  }
  async sale_return_order_create(
    data: {
      customerId: string;
      remark?: string;
      detailList: {
        saleOrderId: string;
        saleOrderDetailId: string;
        productId: string;
        productName: string;
        skuId: string;
        skuName: string;
        price: string | number;
        count: number;
        remark?: string;
      }
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.SALE_RETURN_ORDER_CREATE, data, config);
  }



  /********* 进货单 *********/
  async purchase_order_paging(
    data: {
      keyword?: string;
      orderStatus?: string;
      withDetail?: boolean;
      paymentStatus?: string;
      supplierId?: string;
      dateRange?: [string, string];
    } & LibIPagingParam,
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.PURCHASE_ORDER_PAGING_URL, data, config);
  }
  async purchase_order_audit(
    data: {
      purchaseOrderId: string;
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.PURCHASE_ORDER_AUDIT_URL, data, config);
  }
  async purchase_order_cancel(
    data: {
      purchaseOrderId: string;
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.PURCHASE_ORDER_CANCEL_URL, data, config);
  }
  async purchase_order_delete(
    data: {
      purchaseOrderId: string;
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.PURCHASE_ORDER_DELETE_URL, data, config);
  }
  async purchase_order_create(
    data: {
      supplierId: string;
      remark?: string;
      detailList: {
        productId: string;
        productName: string;
        skuId: string;
        skuName: string;
        price: number;
        count: number;
      }[],
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.PURCHASE_ORDER_CREATE_URL, data, config);
  }
  async purchase_order_detail(
    data: {
      purchaseOrderId: string;
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.PURCHASE_ORDER_DETAIL_URL, data, config);
  }
  async purchase_order_in_stock(
    data: {
      purchaseOrderId: string;
      detailList: {
        purchaseOrderDetailId: string;
        repositoryId: string;
      }[]
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.PURCHASE_ORDER_IN_STOCK_URL, data, config);
  }
  async purchase_order_pay(
    data: {
      purchaseOrderId: string;
      datetime: string;
      actualAmount: string;
      remark?: string;
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.PURCHASE_ORDER_PAYMENT_RECORD_CREATE_URL, data, config);
  }

  /********* 进货退货单 ********/
  async purchase_return_order_paging(data: {
    keyword?: string;
    orderStatus?: string;
    withDetail?: boolean;
    paymentStatus?: string;
    supplierId?: string;
    dateRange?: [string, string];
  } & LibIPagingParam,
    config?: IConfig) {
    return this.request(LibAgentUrl.PURCHASE_RETURN_ORDER_PAGING_URL, data, config);
  }
  async purchase_return_order_details(
    data: {
      purchaseReturnOrderId: string;
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.PURCHASE_RETURN_ORDER_DETAILS_URL, data, config);
  }
  async purchase_return_order_audit(
    data: {
      purchaseReturnOrderId: string;
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.PURCHASE_RETURN_ORDER_AUDIT_URL, data, config);
  }
  async purchase_return_order_cancel(
    data: {
      purchaseReturnOrderId: string;
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.PURCHASE_RETURN_ORDER_CANCEL_URL, data, config);
  }
  async purchase_return_order_delete(
    data: {
      purchaseReturnOrderId: string;
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.PURCHASE_RETURN_ORDER_DELETE_URL, data, config);
  }
  async purchase_return_order_create(
    data: {
      supplierId: string;
      remark?: string;
      detailList: {
        stockDetailId: string;
        skuName: string;
        skuId: string;
        productId: string;
        productName: string;
        returnCount: string;
        returnPrice: string;
        repositoryId: string;
        repositoryName: string;
        price: string;
        remark?: string;
      }[];
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.PURCHASE_RETURN_ORDER_CREATE_URL, data, config);
  }
  async purchase_return_order_out_stock(
    data: {
      purchaseReturnOrderId: string;
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.PURCHASE_RETURN_ORDER_OUT_STOCK_URL, data, config);
  }
  async purchase_return_order_payment_record_create(
    data: {
      purchaseReturnOrderId: string;
      datetime:string;
      actualAmount: number | string;
      remark?: string;
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.PURCHASE_RETURN_ORDER_PAYMENT_RECORD_CREATE_URL, data, config);
  }

  /********* 商品**********/
  // 商品分类查询
  async category_list(
    data?: {
      withImage?: boolean;
      isTree?: boolean;
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.CATEGORY_LIST_URL, data, config);
  }
  async category_create(
    data: {
      categoryName: string;
      parentId: string;
      imageId?: string;
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.CATEGORY_CREATE_URL, data, config);
  }
  async category_update(
    data: {
      categoryId: string;
      categoryName: string;
      parentId: string;
      imageId?: string;
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.CATEGORY_UPDATE_URL, data, config);
  }
  async category_sort(
    data: {
      categoryIdList: string[];
      parentId: string;
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.CATEGORY_SORT_URL, data, config);
  }
  async category_delete(
    data?: {
      categoryId: string;
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.CATEGORY_DELETE_URL, data, config);
  }
  // 商品分页v2
  async product_paging(
    data: {
      keyword?: string;
      status?: string;
      categoryId?: string;
      attributes?: ('sku' | 'unit' | 'image' | 'activity')[]
    } & LibIPagingParam,
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.PRODUCT_PAGING_URL, data, config);
  }
  // 商品分类
  async category_tree(config?: IConfig) {
    return this.request(LibAgentUrl.PRODUCT_CATEGORY_TREE_URL, {}, config);
  }
  // 商品详情
  async product_detail(
    data?: {
      productId: string;
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.PRODUCT_DETAIL_URL, data, config);
  }
  // 新增商品
  async product_create(
    data: {
      productName: string;
      categoryId: string;
      status: string;
      searchWordList?: string[];
      productDesc: string;
      sortValue?: string;
      barcode?: string;
      unitList: Partial<LibIProductUnit>[];
      skuList: {skuId: string; skuName: string; price: number | string}[];
      imageLogoId: string;
      imageSlideIdList?: string[];
      imageDetailIdList?: string[];
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.PRODUCT_CREATE_URL, data, config);
  }

  // 修改商品
  async product_update(
    data: {
      productId: string;
      productName?: string;
      categoryId?: string;
      status?: string;
      searchWordList?: string[];
      productDesc?: string;
      sortValue?: string;
      barcode?: string;
      minBuyCount?: number;
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.PRODUCT_UPDATE_URL, data, config);
  }
  // 删除商品
  async product_delete(data: {productId: string}) {
    return this.request(LibAgentUrl.PRODUCT_DELETE, data);
  }
  // 修改规格
  async product_sku_update(
    data?: {
      skuId: string;
      skuName?: string;
      price?: string;
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.PRODUCT_SKU_UPDATE_URL, data, config);
  }
  // 启用或禁用规格
  async product_sku_disable(
    data?: {
      skuId: string;
      disabled: boolean;
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.PRODUCT_SKU_DISABLE_URL, data, config);
  }
  // 新增规格
  async product_sku_create(
    data: {
      productId: string;
      skuName: string;
      price: string;
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.PRODUCT_SKU_CREATE_URL, data, config);
  }
  // 删除规格
  async product_sku_delete(
    data?: {
      skuId: string;
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.PRODUCT_SKU_DELETE_URL, data, config);
  }
  // 新增单位
  async product_unit_create(
    data: {
      productId: string;
      unitName?: string;
      ratio: number;
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.PRODUCT_UNIT_CREATE_URL, data, config);
  }
  // 修改单位
  async product_unit_update(
    data: {
      unitId: string;
      unitName?: string;
      ratio?: number;
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.PRODUCT_UNIT_UPDATE_URL, data, config);
  }
  // 启用/禁用单位
  async product_unit_disable(
    data: {
      unitId: string;
      disabled: boolean;
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.PRODUCT_UNIT_DISABLE_URL, data, config);
  }
  // 删除单位
  async product_unit_delete(
    data: {
      unitId: string;
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.PRODUCT_UNIT_DELETE_URL, data, config);
  }
  // 批量修改状态
  async product_batch_update_status(data: {
    productIdList: string[];
    status: string;
  }, config?: IConfig) {
    return this.request(LibAgentUrl.PRODUCT_BACH_UPDATE_STATUS_URL, data, config);
  }
  // 批量修改商品分类
  async product_batch_update_category(data: {
    productIdList: string[];
    categoryId: string;
  }, config?: IConfig) {
    return this.request(LibAgentUrl.PRODUCT_BACH_UPDATE_CATEGORY_URL, data, config);
  }


  /********** 客户 **********/
  // 客户分页
  async customer_paging(
    data: LibIPagingParam,
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.CUSTOMER_PAGE_URL, data, config);
  }
  // 客户详情
  async customer_detail(
    data: {
      customerId: string;
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.CUSTOMER_DETAIL_URL, data, config);
  }
  // 客户新增
  async customer_create(
    data: {
      employeeId: string;
      customerName: string;
      mobile: string;
      password: string;
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.CUSTOMER_CREATE_URL, data, config);
  }
  // 客户信息修改
  async customer_update(
    data: {
      customerId: string;
      customerName?: string;
      status?: '0' | '1';
      password?: string;
      mobile?: string;
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.CUSTOMER_UPDATE_URL, data, config);
  }
  // 客户删除
  async customer_delete(
    data: {
      customerId: string;
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.CUSTOMER_DELETE_URL, data, config);
  }
  // 客户收货地址列表
  async customer_address_list(
    data: {
      customerId: string;
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.CUSTOMER_ADDRESS_LIST_URL, data, config);
  }
  // 客户收货地址删除
  async customer_address_delete(
    data: {
      addressId: string;
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.CUSTOMER_ADDRESS_DELETE_URL, data, config);
  }
  // 客户收货地址修改
  async customer_address_update(
    data: {
      addressId: string;
      name?: string;
      mobile?: string;
      provinceCode?: string;
      provinceName?: string;
      cityCode?: string;
      cityName?: string;
      areaCode?: string;
      areaName?: string;
      street?: string;
      isDefault?: '0' | '1';
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.CUSTOMER_ADDRESS_UPDATE_URL, data, config);
  }
  // 客户收货地址新增
  async customer_address_create(
    data: {
      customerId: string;
      name: string;
      mobile: string;
      provinceCode: string;
      provinceName: string;
      cityCode: string;
      cityName: string;
      areaCode: string;
      areaName: string;
      street: string;
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.CUSTOMER_ADDRESS_CREATE_URL, data, config);
  }



  // 供应商分页
  async supplier_paging(
    data?: {
      keyword?: string;
      pageNum?: number;
      pageSize?: number;
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.SUPPLIER_PAGING_URL, data, config);
  }
  // 供应商列表
  async supplier_list(
    data?: {
      keyword?: string;
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.SUPPLIER_LIST_URL, data, config);
  }
  // 供应商修改
  async supplier_update(
    data: {
      supplierId: string;
      supplierName?: string;
      linkman?: string;
      mobile?: string;
      address?: string;
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.SUPPLIER_UPDATE_URL, data, config);
  }
  // 供应商新增
  async supplier_create(
    data: {
      supplierName: string;
      linkman: string;
      mobile: string;
      address: string;
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.SUPPLIER_CREATE_URL, data, config);
  }
  // 供应商删除
  async supplier_delete(
    data: {
      supplierId: string;
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.SUPPLIER_DELETE_URL, data, config);
  }



  // 库存汇总分页
  async stock_summary_paging(
    data?: {
      productName?: string;
      skuId?: string;
      productId?: string;
      pageNum?: number;
      pageSize?: number;
      order?: {
        preCount?: LibISortValueEnum | '';
        usableCount?: LibISortValueEnum | '';
        stockCount?: LibISortValueEnum | '';
      };
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.STOCK_SUMMARY_PAGING_URL, data, config);
  }
  // 库存明细分页
  async stock_detail_paging(
    data?: {
      productName?: string;
      skuId?: string;
      productId?: string;
      repositoryId?: string;
      supplierId?: string;
      pageNum?: number;
      pageSize?: number;
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.STOCK_DETAIL_PAGING_URL, data, config);
  }
  // 删除库存明细
  async stock_detail_delete(data: {
    stockId: string;
  },
  config?: IConfig) {
    return this.request(LibAgentUrl.STOCK_DETAIL_DELETE_URL, data, config);
  }
  // 出入库记录分页
  async stock_record_paging(
    data: {
      productName?: string;
      repositoryId?: string;
      productId?: string;
      pageNum?: number;
      pageSize?: number;
    },
    config?: IConfig
  ) {
    return this.request(LibAgentUrl.STOCK_RECORD_PAGING_URL, data, config);
  }

  // 账单分页查询
  async book_paging(data?: {
    bookTypeList?: string[];
    bookType?: string;
    targetName?: string;
    targetId?: string;
    dateRange?: string[];
  }, config?: IConfig) {
    return this.request(LibAgentUrl.BOOK_PAGING_URL, data, config);
  }
  // 账单新增
  async book_create(data: {
    bookType: string;
    targetName: string;
    targetId?: string;
    datetime: string;
    actualAmount: string;
    shouldAmount: string;
    remark?: string;
    relevanceId?: string;
  }, config?: IConfig) {
    return this.request(LibAgentUrl.BOOK_CREATE_URL, data, config);
  }

  // 系统日志查询
  async action_log_paging(data: {
    targetName?: string;
    eventType?: string;
  } & LibIPagingParam, config?: IConfig) {
    return this.request(LibAgentUrl.ACTION_LOG_PAGING_URL, data, config);
  }

  // 员工列表
  async employee_list(data: {departmentId?: string, withRole?: boolean}, config?: IConfig) {
    return this.request(LibAgentUrl.EMPLOYEE_LIST_URL, data, config);
  }
  async employee_create(data: {fullName: string, mobile: string, departmentId: string}, config?: IConfig) {
    return this.request(LibAgentUrl.EMPLOYEE_CREATE_URL, data, config);
  }
  async employee_update(data: {employeeId: string, fullName?: string, mobile?: string, departmentId?: string, status?: string, password?: string}, config?: IConfig) {
    return this.request(LibAgentUrl.EMPLOYEE_UPDATE_URL, data, config);
  }
  async employee_delete(data: {employeeId: string}, config?: IConfig) {
    return this.request(LibAgentUrl.EMPLOYEE_DELETE_URL, data, config);
  }
  async employee_reset_password(data: {employeeId: string, password: string}, config?: IConfig) {
    return this.request(LibAgentUrl.EMPLOYEE_RESET_PW_URL, data, config);
  }
  // 部门列表
  async department_list() {
    return this.request(LibAgentUrl.DEPARTMENT_LIST_URL, {});
  }
  async department_create(data: {departmentName: string;}) {
    return this.request(LibAgentUrl.DEPARTMENT_CREATE_URL, data);
  }
  async department_update(data: {departmentName: string; departmentId: string}) {
    return this.request(LibAgentUrl.DEPARTMENT_UPDATE_URL, data);
  }
  async department_delete(data: {departmentId: string}) {
    return this.request(LibAgentUrl.DEPARTMENT_DELETE_URL, data);
  }

  // 仓库
  async repository_list(data?: {withProduct?: boolean}) {
    return this.request(LibAgentUrl.REPOSITORY_LIST_URL, data);
  }
  async repository_create(data: { repositoryName: string, repositoryAddress: string, repositoryDesc?: string}, config?: IConfig) {
    return this.request(LibAgentUrl.REPOSITORY_CREATE_URL, data, config);
  }
  async repository_update(data: { repositoryId: string, repositoryName: string, address: string, desc?: string}, config?: IConfig) {
    return this.request(LibAgentUrl.REPOSITORY_UPDATE_URL, data, config);
  }
  async repository_delete(data: { repositoryId: string }, config?: IConfig) {
    return this.request(LibAgentUrl.REPOSITORY_DELETE_URL, data, config);
  }


  // 权限
  async auth_role_list(data: {
    withEmployee?: boolean;
  }, config?: IConfig) {
    return this.request(LibAgentUrl.AUTH_ROLE_LIST_URL, data, config);
  }
  async auth_role_detail(data: {
    roleId?: string;
  }, config?: IConfig) {
    return this.request(LibAgentUrl.AUTH_ROLE_DETAIL_URL, data, config);
  }
  async auth_role_create(data: {
    roleName?: string;
    employeeIdList?: string[];
    functionList?: number[] | string[];
  }, config?: IConfig) {
    return this.request(LibAgentUrl.AUTH_ROLE_CREATE_URL, data, config);
  }
  async auth_role_update(data: {
    roleId?: string;
    roleName?: string;
    employeeIdList?: string[];
    functionList?: number[] | string[];
  }, config?: IConfig) {
    return this.request(LibAgentUrl.AUTH_ROLE_UPDATE_URL, data, config);
  }
  async auth_role_delete(data: {
    roleId?: string;
  }, config?: IConfig) {
    return this.request(LibAgentUrl.AUTH_ROLE_DELETE_URL, data, config);
  }

  // 公告
  async notice_paging(data: {
    keyword?: string;
    closable?: boolean;
  }, config?: IConfig) {
    return this.request(LibAgentUrl.NOTICE_PAGING_URL, data, config);
  }
  async notice_create(data: {
    title: string;
    content: string;
    enable: boolean;
    closable: boolean;
  }, config?: IConfig) {
    return this.request(LibAgentUrl.NOTICE_CREATE_URL, data, config);
  }
  async notice_update(data: {
    noticeId: string;
    title: string;
    content: string;
    enable: boolean;
    closable: boolean;
  }, config?: IConfig) {
    return this.request(LibAgentUrl.NOTICE_UPDATE_URL, data, config);
  }
  async notice_delete(data: {
    noticeId: string;
  }, config?: IConfig) {
    return this.request(LibAgentUrl.NOTICE_DELETE_URL, data, config);
  }


  // 广告
  async advert_detail(data: {
    advertId: string;
  }, config?: IConfig) {
    return this.request(LibAgentUrl.ADVERT_DETAIL_URL, data, config);
  }
  async advert_list(data?: {
    advertName?: string;
    advertType?: string;
    linkType?: string;
  }, config?: IConfig) {
    return this.request(LibAgentUrl.ADVERT_LIST_URL, data, config);
  }
  async advert_create(data: {
    advertName: string;
    advertType: string;
    linkType: string;
    imageId: string;
    status?: string;
    objectIdList: string;
  }, config?: IConfig) {
    return this.request(LibAgentUrl.ADVERT_CREATE_URL, data, config);
  }
  async advert_update(data: {
    advertId: string;
    advertName?: string;
    linkType?: string;
    imageId?: string;
    status?: string;
    objectIdList?: string;
  }, config?: IConfig) {
    return this.request(LibAgentUrl.ADVERT_UPDATE_URL, data, config);
  }
  async advert_delete(data: {
    advertId: string;
  }, config?: IConfig) {
    return this.request(LibAgentUrl.ADVERT_DELETE_URL, data, config);
  }

  // 报表
  async report_sale_order(data: {
    dateRange: [string, string],
  }, config?: IConfig) {
    return this.request(LibAgentUrl.REPORT_SALE_ORDER_URL, data, config);
  }
  async report_customer_sale(data: {
    dateRange: [string, string],
  }, config?: IConfig) {
    return this.request(LibAgentUrl.REPORT_CUSTOMER_SALE_URL, data, config);
  }
  async report_product_sale(data: {
    dateRange: [string, string],
  }, config?: IConfig) {
    return this.request(LibAgentUrl.REPORT_PRODUCT_SALE__URL, data, config);
  }
  async report_purchase_order(data: {
    dateRange: [string, string],
  }, config?: IConfig) {
    return this.request(LibAgentUrl.REPORT_PURCHASE_ORDER_URL, data, config);
  }
  async report_product_purchase(data: {
    dateRange: [string, string],
  }, config?: IConfig) {
    return this.request(LibAgentUrl.REPORT_PRODUCT_PURCHASE_URL, data, config);
  }
  async report_supplier_purchase(data: {
    dateRange: [string, string],
  }, config?: IConfig) {
    return this.request(LibAgentUrl.REPORT_SUPPLIER_PURCHASE_URL, data, config);
  }
  async report_manage(data: {
    dateRange: [string, string],
  }, config?: IConfig) {
    return this.request(LibAgentUrl.REPORT_MANAGE_SUMMARY_URL, data, config);
  }
  async report_manage_fee(data: {
    dateRange: [string, string],
  }, config?: IConfig) {
    return this.request(LibAgentUrl.REPORT_MANAGE_FEE_URL, data, config);
  }
  async report_employee_sale_ranking(data: {
    dateRange: [string, string],
  }, config?: IConfig) {
    return this.request(LibAgentUrl.REPORT_EMPLOYEE_SALE_RANKING_URL, data, config);
  }


  ///////////// 推送消息 /////////
  async message_unread_count() {
    return this.request(LibAgentUrl.MESSAGE_UNREAD_COUNT_URL);
  }
  async message_paging(data: {msgType: string} & LibIPagingParam) {
    return this.request(LibAgentUrl.MESSAGE_PAGING_URL, data);
  }
  async message_read_update(data?: {msgIdList?: string[]}) {
    return this.request(LibAgentUrl.MESSAGE_READ_UPDATE_URL, data);
  }
  async message_delete(data: {msgIdList: string[]}) {
    return this.request(LibAgentUrl.MESSAGE_DELETE_URL, data);
  }

  // 上传
  async upload_image(data: FormData) {
    return this.request(LibAgentUrl.UPLOAD_IMAGE, data);
  }

  // 图片
  async image_update(data: {
    data: {
      imageId: string;
      sortValue: number;
    }[]
  }) {
    return this.request(LibAgentUrl.IMAGE_UPDATE, data);
  }
  async image_delete(data: {
    imageId: string;
  }) {
    return this.request(LibAgentUrl.IMAGE_DELETE, data);
  }
}