import request from '@/utils/request';

export const findProductSaleSort = params => {
  return request('/admin/report/saleSort/findProductSaleSort', params);
}

export const findUserSaleSort = params => {
  return request('/admin/report/saleSort/findUserSaleSort', params);
}

export const findEmpSaleSort = params => {
  return request('/admin/report/saleSort/findEmpSaleSort', params);
}