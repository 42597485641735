import React, { PureComponent } from 'react';
import { Checkbox } from 'antd';
import Dialog from '@/components/dialog/Dialog';
import ViewTable from '@/components/table/ViewTable';
import columns from './columns';
import Layout from '../layout/Layout';
import SearchForm from '../search-form/SearchForm';
import WebApi from '@/web/api';
import Filter from '../filter/Filter';
import LibEnum from 'lib/enum';

interface IState {
  visible: boolean;
  selectedRowKeys: string[];
  selectedRows: any[];
}

interface IProductDialogProps {
  rowSelectType?: 'checkbox' | 'radio';
  getRowDisabled?: ((row: any) => boolean);
  attributes?: ('sku' | 'skuWithStock' | 'unit' | 'activity')[];
  onOk?(data: ProductType[], params?: any): void;
}

export default class ProductDialog extends PureComponent<IProductDialogProps, IState> {

  private table = React.createRef<ViewTable>()

  private reqData = {
    keyword: '',
    categoryId: '',
    status: '11',
  };
  
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      selectedRowKeys: [],
      selectedRows: [],
    };
  }

  public open = () => {
    this.setState({
      visible: true,
    });
  }
  public close = () => {
    this.setState({
      visible: false,
      selectedRowKeys: [],
    });
  }

  private fields = [
    {
      type: 'category',
    },
    {
      type: 'input',
      name: '搜索',
      field: 'keyword',
      placeholder: '商品名称/编号/关键词',
    },
    {
      type: 'checkbox',
      name: '只显示上架',
      field: 'status',
      defaultValue: true,
    }
  ]

  render() {

    return <Dialog
      visible={this.state.visible}
      okButtonProps={{
        disabled: this.state.selectedRowKeys.length === 0
      }}
      onOk={this.clickOk}
      block
      destroyOnClose
      onCancel={this.close}
      title="选择商品"
    >
      <Layout flexColumn height overHidden>
        <Filter
          fields={this.fields as any}
          onChange={this.onFilterChange}
        />
        <ViewTable
          flex1
          ref={this.table}
          fetch={this.fetch}
          rowSelection={{
            type: this.props.rowSelectType || 'checkbox',
            columnWidth: 60,
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
              this.setState({
                selectedRowKeys: selectedRowKeys as string[],
                selectedRows,
              })
            },
            getCheckboxProps: (record: any) => {
              const disabled = this.props.getRowDisabled ? this.props.getRowDisabled(record) : false;
              return {
                disabled,
              };
            },
          }}
          columns={columns as any[]}
        />
      </Layout>
    </Dialog>
  }

  private onFilterChange = values => {
    this.reqData = values as any;
    if (this.reqData.status) {
      this.reqData.status = LibEnum.PRODUCT_STATUS.UP.value;
    } else {
      this.reqData.status = '';
    }
    this.table.current?.reset();
  }

  private fetch = (data) => {
    const attributes = this.props.attributes || ['image', 'activity', 'skuWithStock', 'unit'];
    return WebApi.product_paging({...data, attributes: [...attributes, 'image'], ...this.reqData});
  }

  private clickOk = () => {
    this.setState({
      selectedRowKeys: [],
      selectedRows: [],
      visible: false
    });
    this.props.onOk && this.props.onOk(this.state.selectedRows);
  }

  renderProductSearchForm = () => {
    return  <SearchForm.Item label="只显示上架">
      <Checkbox defaultChecked onChange={e => {
        this.reqData.status = e.target.checked ? '11' : '';
      }}></Checkbox>
    </SearchForm.Item>
  }
}