import BigNumber from "bignumber.js";
import React from "react";

export default (props: {
  children: React.ReactText;
}) => {
  const amount = isNaN(Number(props.children)) || typeof props.children !== 'string' ? '0.00' : new BigNumber(props.children).toFixed(2);
  const array = amount.split('.');
  const left = array[0];
  const right = array[1];
  return <span style={{color: 'red'}}>
    <span style={{fontSize: 20}}>{left}</span>
    <span>.{right}</span>
  </span>
} 