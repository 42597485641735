export const setHash = (object) => {
  if (Object.prototype.toString.call(object) !== '[object Object]') {
    return '';
  }
  const strings = [];
  const keys = Object.keys(object);
  keys.forEach(key => {
    strings.push(`${key}=${object[key]}`);
  });
  const hash = strings.join('&');
  window.location.hash = hash;
};

export const getHashParams = () => {
  const query = window.location.hash.substring(1);
  const items = query.split('&');
  const result = {};
  items.forEach(item => {
    const array = item.split('=');
    const key = array[0];
    const value = array[1];
    result[key] = value || '';
  });
  return result;
}