/**
 * 网络请求
 * @argument {object} 请求参数
 * @returns {object} 请求实例
 */
import { message, Modal } from 'antd';
import config from '@/config';
import axios from 'axios';
import { getToken, removeToken } from '@/storage/storage';
import { Dialog } from '@/components';
import { hideLoading } from '@/components/message/message';

const codeMessage = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误。',
  401: '登录后才能操作。',
  403: '访问受限，缺少对应的权限。',
  404: '请求地址出错。',
  406: '请求的格式不可得。',
  408: '请求超时。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
};

let requestLock = false;

const axiosInstance = axios.create();

export interface HttpResponse {
  code: string
  msg: string
  data: any
}

export interface RequestError extends Error {
  type?: 'request',
  data?: HttpResponse
}

axiosInstance.interceptors.response.use((options) => {
    if (options.data){
      // 禁用
      if (options.data.code === 'A9999') {
        if (!requestLock) {
          Modal.warning({
            centered: true,
            title: '易采惠',
            content: options.data.msg,
            okText: '退出易采惠',
            onOk: () => {
              window.location.replace('/login');
            }
          });
        }
        requestLock = true;
        return new Promise(() => {

        });
      }
      if (options.data.code === 'I0002' || options.data.code === '9000') {
        removeToken();
        message.warning(options.data.msg);
        window.location.replace('/login');
        return;
      }
      if (options.data.code === '0000') {
        return options.data.response || options.data.data;
      }
      const error: any = new Error(options.data.msg || '操作失败');
      error.type = 'request';
      error.code = options.data.code;
      error.msg = options.data.msg;
      error.data = options.data;
      throw error;
    } else {
      throw new Error('系统繁忙: 未返回数据');
    }
  }, (error) => {
    hideLoading();
    if (error.response) {
      const extendMessage = error.response.status === 404 ? '404' : '';
      error.message = codeMessage[error.response.status] || '请求出错[' + error.response.status + ']' + extendMessage ;
    } else {
      // 一些错误是在设置请求时触发的
      if (error.message.indexOf('timeout') > -1){
        error.message = '请求超时';
      }
      if (error.message.indexOf('Network Error') > -1) {
        error.message = '请检查网络连接';
      }
      Dialog.error({
        title: error.message,
      });
      return new Promise(() => {});
    }
    return Promise.reject(error);
  }
);

export const request = (url: string, data, options = {}) => {

  const baseURL = url.indexOf('/agent') > -1 || url.indexOf('/ych') > -1 || url.indexOf('/web') > -1 ? config.apiV2BaseUrl : config.apiUrl;
  const token = getToken();
  if (!requestLock) {
    return axiosInstance({
      baseURL,
      url,
      timeout: 20000,
      method: "POST",
      data,
      ...options,
      headers: {
        authToken: token,
      },
    });
  }
  return new Promise(() => {});
};

export default request;