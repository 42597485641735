import apis from "@/api/api";
import WebApi from "@/web/api";
import { LoadingOutlined } from "@ant-design/icons";
import { message, Spin } from "antd";
import classNames from "classnames";
import React from "react";
import {v4} from 'uuid';
const style = require('./upload.less');

export default class Upload extends React.PureComponent<{
  width: number;
  height: number;
  type: string;
  src?: string;
  onChange(data: {
    src: string;
    imageId: string;
  }): void;
  tip?: React.ReactNode;
}, {
  src: string;
  imageId: string;
  errMsg: string;
  uploading: boolean;
}> {
  private id = v4();

  constructor(props) {
    super(props);
    this.state = {
      src: props.src,
      imageId: '',
      errMsg: '',
      uploading: false,
    };
  }

  render() {
    const {width, height, tip = `建议图片尺寸大小为${width}px * ${height}px`} = this.props;
    const {errMsg} = this.state;
    return <div className="component-upload">
      <label htmlFor={this.id} className={classNames('component-upload-box', {
        err: !!errMsg
      })} style={{width, height}}>
        {
          this.renderContent()
        }
      </label>
      <input type="file" id={this.id} accept=".png,.jpg,.jpeg" onChange={this.onInputChange} hidden/>
      <p className="component-upload-tip">{tip}</p>
      <p className="component-upload-err-msg">{errMsg}</p>
    </div>
  }

  private renderContent = () => {
    const {src, uploading} = this.state;
    if (uploading) {
      return <div className="component-upload-loading">
        <Spin indicator={<LoadingOutlined/>} delay={500}/>
      </div>
    } 
    if (src) return <img className="component-upload-image" src={src}/>;

    return <div className="component-upload-btn">
      <i className="iconfont icon-shangchuan"/>
      <span>点击上传</span>
    </div>
  }

  // 上传图片
  private onInputChange = async (e) => {
    const files = e.target.files;
    if(files && files[0]) {
      const file = files[0];
      if(file.size > 1024 * 1024 * 2) {
        this.setState({
          errMsg: '图片大小不能超过2M！',
        });
        return false;
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async (e) => {
        const src: string = e.target?.result as string;
        if (!src) return;
        this.setState({
          src,
          uploading: true,
          errMsg: '',
        });
        const data = new FormData();
        data.append('type', this.props.type);
        data.append('image', file);
        WebApi.upload_image(data).then(async res => {
          // 删除之前的
          if (this.state.imageId) {
            await WebApi.image_delete({imageId: this.state.imageId});
          }
          this.setState({
            imageId: res.imageId,
            src,
            uploading: false,
          });
          this.props.onChange && this.props.onChange({
            src,
            imageId: res.imageId
          });
        }).catch(e => {
          message.error('上传出错：' + e.message);
        });
      }
    }
  }
}