import { Select } from "antd";
import React from "react";
import { AgentApi } from "src/api/AgentApi";
import { ProductDialog } from "./ProductDialog";
const style = require('./ProductAdvanced.less');
import autoBind from 'auto-bind'

interface IProps {
  multiple?: boolean;
  value?: string[];
  onChange?(selectedList: string[]): void;
}

interface IState {
  productList: any[];
  loading: boolean;
  dialogVisible: boolean;
  selectedList: string[];
}

export class ProductAdvanced extends React.PureComponent<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      productList: [],
      loading: false,
      dialogVisible: false,
      selectedList: [],
    };
    autoBind(this);
  }

  componentDidMount(): void {
    AgentApi.product.getProductList({}).then((data) => {
      this.setState({
        productList: data,
        loading: false,
        selectedList: this.props.value || [],
      });
    }).catch((e) => {
      showError(e.message);
    })
  }
  render(): React.ReactNode {
    const {multiple} = this.props;
    const {productList, loading, dialogVisible, selectedList} = this.state;
    const value = selectedList[0];
    return <div className="product-advanced">
      <Select
        className="product-advanced-select"
        showSearch
        mode={multiple ? 'multiple' : undefined}
        loading={loading}
        showArrow
        value={multiple ? selectedList : value}
        placeholder="选择..."
        suffixIcon={<div className="product-advanced-select-btn" onClick={this.onClickOpenDialog}><i className="iconfont icon-more"/></div>}
        onChange={multiple ? this.onSelectList : this.onSelect}
      >
        {
          productList.map(item => {
            return <Select.Option value={item.id} key={item.id}>{item.productName}</Select.Option>
          })
        }
      </Select>

      {
        dialogVisible && <ProductDialog
          multiple={!!multiple}
          onClose={this.onClickCloseDialog}
          onOk={this.onClickOkDialog}
        />
      }
    </div>
  }

  private onSelect(value) {
    this.setState({
      selectedList: [value]
    });
    this.props.onChange && this.props.onChange([value]);
  }

  private onSelectList(value) {
    this.setState({
      selectedList: value
    });
    this.props.onChange && this.props.onChange(value);
  }

  private onClickOpenDialog() {
    this.setState({
      dialogVisible: true,
    });
  }

  private onClickCloseDialog() {
    this.setState({
      dialogVisible: false,
    });
  }

  private onClickOkDialog(productList) {
    const selectedList = productList.map(i => i.productId);
    this.setState({
      dialogVisible: false,
      selectedList: selectedList
    });
    this.props.onChange && this.props.onChange(selectedList);
  }
}