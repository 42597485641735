import React from 'react';
import setUserMenu from './menu';

import {routerMap} from '@/router/agent_router';

import {Sub_Menu, Menu_Item} from './type';

const filterComponent = (routerItem) => {
  const item: any = {};
  item.path = routerItem.path;
  item.code = routerItem.code;
  item.name = routerItem.name;
  return item;
};

const style = {
  fontSize: 14,
  verticalAlign: 'middle'
};

const menus = [
  {
    name: '主页',
    icon: <i className="iconfont icon-shouye" style={style}/>,
    type: Menu_Item,
    ...filterComponent(routerMap.home),
  },
  {
    name: '订单',
    icon: <i className="iconfont icon-dingdan" style={style}/>,
    type: Sub_Menu,
    children: [
      {
        name: '订货管理',
        children: [
          // 订货单
          filterComponent(routerMap.sale_order_list),
          // 订货退货单
          filterComponent(routerMap.sale_return_order_list),
        ],
      },
      {
        name: '进货管理',
        children: [
          // 进货单
          filterComponent(routerMap.purchase_order_list),
          // 进货退货单
          filterComponent(routerMap.purchase_return_order_list),
        ],
      },
    ],
  },
  {
    name: '商品库存',
    icon: <i className="iconfont icon-shangpin" style={style}/>,
    type: Sub_Menu,
    children: [
      {
        name: '商品管理',
        children: [
          // 商品分类
          filterComponent(routerMap.product_category),
          // 商品列表
          filterComponent(routerMap.product_list),
        ],
      },
      {
        name: '库存管理',
        children: [
          // 库存列表
          filterComponent(routerMap.stockSummary),
          filterComponent(routerMap.stockDetail),
          // 出入库明细
          filterComponent(routerMap.stockRecord),
        ],
      },
    ],
  },
  {
    name: '营销推广',
    icon: <i className="iconfont icon-yingxiao" style={style}/>,
    type: Sub_Menu,
    children: [
      {
        name: '促销活动',
        children: [
          // 单品打折
          filterComponent(routerMap['activity.discount.view']),
          // 单品特价
          filterComponent(routerMap['activity.special.view']),
          // 单品满赠
          filterComponent(routerMap['activity.gift.view']),
        ],
      },
      {
        name: '广告设置',
        children: [
          filterComponent(routerMap['advert.view']),
        ],
      },
      {
        name: '优惠券',
        children: [
          // 满减券
          filterComponent(routerMap['coupon.full-minus.view']),
        ],
      }
    ],
  },
  {
    name: '资金管理',
    type: Sub_Menu,
    icon: <i className="iconfont icon-caiwu" style={style}/>,
    children: [
      {
        name: '账单',
        children: [
          filterComponent(routerMap.bookSale),
          filterComponent(routerMap.bookSaleReturn),
          filterComponent(routerMap.bookPurchase),
          filterComponent(routerMap.bookPurchaseReturn),
          filterComponent(routerMap['fee.view']),
          // 账簿
          filterComponent(routerMap['book.view']),
        ],
      },
    ],
  },
  {
    name: '报表中心',
    type: Sub_Menu,
    icon: <i className="iconfont icon-baobiao" style={style}/>,
    children: [
      {
        name: '订货报表',
        children: [
          filterComponent(routerMap.reportSaleOrder),
          filterComponent(routerMap.reportCustomerSale),
          filterComponent(routerMap.reportProductSaleProfit),
        ],
      },
      {
        name: '进货报表',
        children: [
          filterComponent(routerMap.reportPurchaseOrder),
          filterComponent(routerMap.reportSupplierPurchase),
          filterComponent(routerMap.reportProductPurchase),
        ],
      },
      {
        name: '经营报表',
        children: [
          filterComponent(routerMap.reportManage),
          filterComponent(routerMap.reportManageFee),
          filterComponent(routerMap.reportEmployeeSaleRanking),
        ],
      },
    ],
  },
  // {
  //   name: '报表中心',
  //   type: Sub_Menu,
  //   icon: <i className="iconfont icon-baobiao" style={style}/>,
  //   code: routerMap['report.product.sale.view'].code,
  //   children: [
  //     {
  //       name: '销售报表',
  //       children: [
  //         {
  //           name: '销售订单',
  //           path: routerMap['report.saleOrder.view'].path
  //         },
  //         {
  //           name: '商品销售',
  //           path: routerMap['report.saleProduct.view'].path
  //         },
  //         {
  //           name: '退货订单',
  //           path: routerMap['report.saleOrderReturn.view'].path
  //         },
  //         {
  //           name: '商品退货',
  //           path: routerMap['report.saleProduct.view'].path
  //         },
  //       ],
  //     },
  //     {
  //       name: '进货报表',
  //       children: [
  //         {
  //           name: '进货订单',
  //           path: routerMap['report.purchaseOrder.view'].path
  //         },
  //         {
  //           name: '进货商品',
  //           path: routerMap['report.purchaseProduct.view'].path
  //         },
  //         {
  //           name: '进货退货订单',
  //           path: routerMap['report.purchaseOrderReturn.view'].path
  //         },
  //         {
  //           name: '进货退货商品',
  //           path: routerMap['report.purchaseProductReturn.view'].path
  //         },
  //         {
  //           name: '进货明细',
  //           path: routerMap['report.purchaseDetail.view'].path
  //         },
  //       ],
  //     },
  //     {
  //       name: '往来单位',
  //       children: [
  //         {
  //           name: '供应商进销',
  //           path: routerMap['report.supplier.view'].path
  //         },
  //         {
  //           name: '供应商库存',
  //           path: routerMap['report.supplier.stock.view'].path
  //         },
  //         {
  //           name: '客户订货',
  //           path: routerMap['report.customer.saleOrder.view'].path
  //         },
  //         {
  //           name: '客户退货',
  //           path: routerMap['report.customer.saleOrderReturn.view'].path
  //         },
  //         {
  //           name: '客户利润',
  //           path: routerMap['report.customer.userProfit.view'].path
  //         },
  //       ],
  //     },
  //     {
  //       name: '财务报表',
  //       children: [
  //         {
  //           name: '经营报表',
  //           path: routerMap['report.finance.manage.view'].path
  //         },
  //       ],
  //     },
  //     {
  //       name: '库存报表',
  //       children: [
  //         {
  //           name: '出入库',
  //           path: routerMap['report.stock.record.view'].path
  //         },
  //         {
  //           name: '库存状态',
  //           path: routerMap['report.stock.status.view'].path
  //         }
  //       ],
  //     },
  //     {
  //       name: '销售排行',
  //       children: [
  //         {
  //           name: '商品销售排行',
  //           path: routerMap['report.product.sale.view'].path
  //         },
  //         {
  //           name: '客户订货排行',
  //           path: routerMap['report.customer.sale.view'].path
  //         },
  //         {
  //           name: '员工销售排行',
  //           path: routerMap['report.employee.sale.view'].path
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    name: '往来单位',
    type: Sub_Menu,
    icon: <i className="iconfont icon-kehu" style={style}/>,
    children: [
      {
        name: '往来单位',
        children: [
          // 客户
          filterComponent(routerMap.customer_list),
          // 供应商
          filterComponent(routerMap['supplier.view']),
        ],
      }
    ]
  },
  {
    name: '系统设置',
    path: '/company',
    type:  Sub_Menu,
    icon: <i className="iconfont icon-systemSet" style={style}/>,
    children: [
      {
        name: '基础设置',
        children: [
          // 权限管理
          filterComponent(routerMap['auth.view']),
          // 操作日志
          filterComponent(routerMap.action_log_list),
        ],
      },
      {
        name:  '基础数据',
        children: [
          // 部门与员工
          filterComponent(routerMap['company.view']),
          // 仓库信息
          filterComponent(routerMap['repository.view']),
          // {
          //   name: '部门',
          //   ...filterComponent(routerMap['company.depart.view'])
          // },
          // {
          //   name: '员工',
          //   ...filterComponent(routerMap['company.employee.view'])
          // },
        ],
      },
    ],
  },
  {
    name: '通知公告',
    icon: <i className="iconfont icon-tongzhi" style={style}/>,
    ...filterComponent(routerMap['notice.view']),
    type: Menu_Item,
  },
];

export const setUserAgentMenu = (functionList: any[]) => {
  return setUserMenu(functionList, menus);
}

export default menus;