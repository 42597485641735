 const LibEnum = {
  SALE_ENUM: {
    ORDER_WAY: {
      AGENT: {
        value: '11',
        name: '代下单'
      },
      SELF: {
        value: '12',
        name: '自主下单'
      }
    }
  },
  PAY_STATUS: {
    UN_PAY: {
      name: '未付款',
      value: '11',
    },
    PAYED: {
      name: '已付款',
      value: '12',
    }
  },
  PAY_STATUS2: {
    UN_PAY: {
      name: '未付款',
      value: '11',
    },
    PART: {
      name: '部分付款',
      value: '12',
    },
    DONE: {
      name: '已付款',
      value: '13',
    }
  },
  // 商品状态
  PRODUCT_STATUS: {
    UP: {
      name: '上架',
      value: '11',
      enumValue: 'UP',
    },
    DOWN: {
      name: '下架',
      value: '12',
      enumValue: 'DOWN',
    },
    DELETE: {
      name: '删除',
      value: '99',
    },
  },
  // 活动状态
  ACTIVITY_STATUS: {
    WAITING: {
      name: '未开始',
      value: 'WAITING'
    },
    ONGOING: {
      name: '进行中',
      value: 'ONGOING'
    },
    DONE: {
      name: '已结束',
      value: 'DONE'
    },
  },
  // 活动类型
  ACTIVITY_TYPE: {
    DISCOUNT: {
      name: '单品打折',
      value: 'DISCOUNT',
    },
    SPECIAL: {
      name: '单品特价',
      value: 'SPECIAL',
    },
    GIFT: {
      name: '单品满赠',
      value: 'GIFT',
    },
    TRIAL: {
      name: '单品试用',
      value: 'TRIAL',
    },
  },
  // 活动赠送条件
  ACTIVITY_GIFT_CONDITION: {
    COUNT: {
      name: '按购买数量',
      value: 'COUNT'
    },
    AMOUNT: {
      name: '按购买金额',
      value: 'AMOUNT'
    },
  },
  // 活动赠送方式
  ACTIVITY_GIFT_METHOD: {
    CIRCULATE: {
      name: '循环赠送',
      value: 'CIRCULATE'
    },
    LADDER: {
      name: '阶梯赠送',
      value: 'LADDER'
    },
  },
  // 活动赠送规则
  ACTIVITY_GIFT_RULE: {
    SELF: {
      name: '赠送原品',
      value: 'SELF'
    },
    OTHER: {
      name: '指定赠品',
      value: 'OTHER'
    },
  },
  // 活动赠送计算方式
  ACTIVITY_GIFT_ALGORITHM: {
    ONCE: {
      name: '单次购买',
      value: 'ONCE'
    },
    TOTAL: {
      name: '累计购买',
      value: 'TOTAL'
    },
  },
  BOOK_TYPE: {
    PURCHASE: {
      value: '11',
      name: '进货付款',
    },
    PURCHASE_RETURN: {
      value: '12',
      name: '进货退货收款',
    },
    SALE: {
      value: '13',
      name: '订货收款',
    },
    SALE_RETURN: {
      value: '14',
      name: '订货退货付款',
    },
    OTHER_PAYMENT: {
      value: '15',
      name: '其他付款',
    },
    OTHER_RECEIVED: {
      value: '16',
      name: '其他收款',
    },
    FEE_OTHER: {
      value: '20',
      name: '其他费用',
    },
    FEE_OIL: {
      value: '21',
      name: '加油',
    },
    FEE_SALARY: {
      value: '22',
      name: '工资',
    },
    FEE_ELECTRICITY: {
      value: '23',
      name: '电费',
    },
    FEE_WATER: {
      value: '24',
      name: '水费',
    },
  },
  // 交易类型
  FINANCE_TRADE_TYPE: {
    PURCHASE: {
      value: '11',
      name: '进货付款',
    },
    PURCHASE_RETURN: {
      value: '12',
      name: '进货退货收款',
    },
    SALE: {
      value: '13',
      name: '订货收款',
    },
    SALE_RETURN: {
      value: '14',
      name: '订货退货付款',
    },
    OTHER_PAYMENT: {
      value: '15',
      name: '其他付款',
    },
    OTHER_RECEIVED: {
      value: '16',
      name: '其他收款',
    },
    FEE_OTHER: {
      value: '20',
      name: '其他费用',
    },
    FEE_OIL: {
      value: '21',
      name: '加油',
    },
    FEE_SALARY: {
      value: '22',
      name: '工资',
    },
    FEE_ELECTRICITY: {
      value: '23',
      name: '电费',
    },
    FEE_WATER: {
      value: '24',
      name: '水费',
    },
  },
  // 图片类型
  IMAGE_TYPE: {
    CATEGORY: {
      value: '400',
      name: '分类图片'
    },
    PRODUCT_LOGO: {
      name: '商品主图',
      value: '401',
    },
    PRODUCT_SLIDER: {
      name: '商品轮播图',
      value: '402',
    },
    PRODUCT_DETAIL: {
      name: '商品详情图',
      value: '403',
    },
    PRODUCT_SKU: {
      name: '商品规格图',
      value: '404'
    },
    ADVERT_CAROUSEL: {
      name: '轮播广告',
      value: '500'
    },
    ADVERT_ICON_MENU: {
      name: '图标广告',
      value: '501'
    },
    ADVERT_CARD: {
      name: '卡片广告',
      value: '502'
    },
  },
  // 下单方式
  SALE_ORDER_WAY: {
    AGENT: {
      name: '代下单',
      value: '11',
    },
    CUSTOMER: {
      name: '自主下单',
      value: '12'
    },
  },
  // 订货单状态
  SALE_ORDER_STATUS: {
    WAIT_AUDIT: {
      name: '待审核',
      value: '11',
    },
    WAIT_OUT_STOCK: {
      name: '待出库',
      value: '12',
    },
    WAIT_DELIVER: {
      name: '待发货',
      value: '13',
    },
    FINISHED: {
      name: '已完成',
      value: '14',
    },
    INVALID: {
      name: '已作废',
      value: '99',
    },
  },
  // 订货退货单状态
  SALE_ORDER_RETURN_STATUS: {
    WAIT_AUDIT: {
      name: '待订单审核',
      value: 'WAIT_AUDIT',
    },
    WAIT_IN_STOCK: {
      name: '待入库',
      value: 'WAIT_IN_STOCK',
    },
    DONE: {
      name: '已完成',
      value: 'DONE',
    },
    INVALID: {
      name: '已作废',
      value: 'INVALID',
    },
  },
  // 进货单状态
  PURCHASE_ORDER_STATUS: {
    WAIT_AUDIT: {
      value: '11',
      name: '待审核',
    },
    WAIT_IN_STOCK: {
      value: '12',
      name: '待入库',
    },
    FINISHED: {
      value: '13',
      name: '已完成',
    },
    INVALID: {
      value: '99',
      name: '已作废',
    },
  },
  // 进货退货单状态
  PURCHASE_RETURN_ORDER_STATUS: {
    WAIT_AUDIT: {
      value: '11',
      name: '待审核',
    },
    WAIT_OUT_STOCK: {
      value: '12',
      name: '待出库',
    },
    FINISHED: {
      value: '13',
      name: '已完成',
    },
    INVALID: {
      value: '99',
      name: '已作废',
    },
  },
  // 付款状态
  PAYMENT_STATUS: {
    WAIT_PAYMENT: {
      name: '待付款',
      value: '11',
    },
    // PART_PAYMENT: {
    //   name: '部分付款',
    //   value: '12',
    // },
    FINISHED: {
      name: '已付款',
      value: '13',
    },
  },
  // 付款状态2
  PAYMENT_STATUS_2: {
    WAIT_PAYMENT: {
      name: '待付款',
      value: '11',
    },
    DONE_PAYMENT: {
      name: '已付款',
      value: '12',
    },
  },
  // 付款状态3
  PAYMENT_STATUS_3: {
    WAIT_PAYMENT: {
      name: '待付款',
      value: 'WAIT_PAYMENT',
    },
    DONE_PAYMENT: {
      name: '已付款',
      value: 'DONE_PAYMENT',
    },
  },
  // 收款状态
  RECEIVED_STATUS: {
    WAIT_RECEIVED: {
      name: '待收款',
      value: '11',
    },
    PART_RECEIVED: {
      name: '部分收款',
      value: '12',
    },
    FINISHED: {
      name: '已收款',
      value: '13',
    },
  },
  // 付款/收款状态2
  RECEIVED_STATUS_2: {
    WAIT_RECEIVED: {
      name: '待收款',
      value: '11',
    },
    DONE_RECEIVED: {
      name: '已收款',
      value: '12',
    },
  },
  // 打印状态
  PRINT_STATUS: {
    WAIT_PRINT: {
      name: '未打印',
      value: '11',
    },
    PRINTED: {
      name: '已打印',
      value: '12',
    },
  },
  // 日志类型
  LOG_EVENT_TYPE: {
    LOGIN: {
      name: '登录',
      value: '11',
    },
    FIND: {
      name: '查看',
      value: '12',
    },
    CREATE: {
      name: '新增',
      value: '13',
    },
    UPDATE: {
      name: '修改',
      value: '14',
    },
    DELETE: {
      name: '删除',
      value: '15',
    },
  },
  // 广告类型
  ADVERT_TYPE: {
    CAROUSEL: {
      name: '首页轮播',
      value: 'CAROUSEL'
    },
    ICON_MENU: {
      name: '首页图标',
      value: 'ICON_MENU'
    },
    CARD: {
      name: '首页推荐',
      value: 'CARD'
    },
  },
  // 广告链接类型
  ADVERT_LINK_TYPE: {
    PRODUCT: {
      name: '商品',
      value: 'PRODUCT'
    },
    PRODUCTS: {
      name: '商品列表',
      value: 'PRODUCTS'
    },
    CATEGORY: {
      name: '商品分类',
      value: 'CATEGORY'
    },
    ACTIVITY: {
      name: '促销活动',
      value: 'ACTIVITY'
    },
  },
  // 出入库类型
  STOCK_ABSTRACT_TYPE: {
    PRODUCT_IN: {
      name: '进货入库',
      value: '1',
    },
    SALE_OUT: {
      name: '订货单出库',
      value: '2',
    },
    STOCK_MINUS: {
      name: '库存删减',
      value: '3',
    },
    PURCHASE_IN: {
      name: '进货入库',
      value: '4',
    },
    IN: {
      name: '库存修正入库',
      value: '5',
    },
    OUT: {
      name: '库存修正出库',
      value: '6',
    },
    SALE_RETURN_IN: {
      name: '订单退货入库',
      value: '7',
    },
    PURCHASE_RETURN_OUT: {
      name: '进货退货出库',
      value: '8',
    },
  },
  // 出库/入库
  STOCK_OUT_IN_TYPE: {
    IN: {
      name: '入库',
      value: '1',
    },
    OUT: {
      name: '出库',
      value: '2',
    },
  },
  // 员工类型
  EMPLOYEE_TYPE: {
    SYSTEM_PRESET: {
      name: '系统预置',
      value: '1',
    },
    CUSTOM: {
      name: '自定义',
      value: '2',
    },
  },
  // 员工状态
  EMPLOYEE_STATUS: {
    ENABLED: {
      name: '正常',
      value: '1',
    },
    DISABLED: {
      name: '非正常',
      value: '0',
    },
  },
  // 角色类型
  ROLE_TYPE: {
    UNEDITABLE: {
      name: '不可编辑',
      value: '0',
    },
    EDITABLE: {
      name: '可编辑',
      value: '1',
    },
  },
  // 代理商状态
  AGENT_STATUS: {
    ENABLED: {
      name: '正常',
      value: '1',
    },
    DISABLED: {
      name: '非正常',
      value: '0',
    },
  },
  // 消息类型
  MESSAGE_TYPE: {
    REGISTER: {
      name: '客户注册',
      value: 'REGISTER',
    },
    SALE: {
      name: '订货',
      value: 'SALE',
    },
  },
  // 优惠券类型
  COUPON_TYPE: {
    SALE: {
      name: '订单券',
      value: 'SALE',
    },
    PRODUCT: {
      name: '商品券',
      value: 'PRODUCT',
    },
    CATEGORY: {
      name: '品类券',
      value: 'CATEGORY',
    },
  },
  // 优惠券发放状态
  COUPON_GIVE_STATUS: {
    WAIT: {
      name: '待发放',
      value: 'WAIT',
    },
    GOING: {
      name: '发放中',
      value: 'GOING'
    },
    DONE: {
      name: '已结束',
      value: 'DONE'
    },
  },
  // 公司类型
  COMPANY_TYPE: {
    AGENT: {
      name: '代理商',
      value: 'AGENT'
    },
    YCH: {
      name: '平台',
      value: 'YCH'
    },
  },
  getValueByEnumValue: (value: string) => {
    for (let key in LibEnum) {
      for (let i in LibEnum[key]) {
        if (LibEnum[key][i].enumValue === value) {
          return LibEnum[key][i].value
        }
      }
    }
  },
  getNameByValue: function(value: string) {
    for (let key in LibEnum) {
      for (let i in LibEnum[key]) {
        if (LibEnum[key][i].value === value) {
          return LibEnum[key][i].name;
        }
      }
    }
    return '';
  }
};

export default LibEnum;